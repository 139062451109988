<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span>{{ 'label_files_upload' | translate }}</span>
  <div class="buttons-container">
    <span style="margin-left: 0.5rem"></span>
    <button mat-flat-button color="primary" (click)="close()" [disabled]="isUploading">
      <span>{{ 'label_close' | translate }}</span>
    </button>
  </div>
</div>
<div mat-dialog-content>
  <div class="progress-message-title">
    <mat-progress-bar [mode]="isUploading ? 'buffer' : 'determinate'" color="primary" [value]="progressPercentage"></mat-progress-bar>
  </div>
  <div class="progress-messages" *ngIf="toUploadFilesList && toUploadFilesList.length > 0">
    <div class="entity-table">
      <div class="table-wrapper">
        <div #messagesContainer class="table-container">
          <table class="table table-stripes table-truncate-text" mat-table [dataSource]="toUploadFilesList">
            <ng-container matColumnDef="folder">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'entity_file_folder' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="row.folder" *matCellDef="let row">{{ row.folder }}</td>
            </ng-container>
            <ng-container matColumnDef="filename">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'entity_file_name' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="row.filename" *matCellDef="let row">{{ row.filename }}</td>
            </ng-container>
            <ng-container matColumnDef="percentage">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'entity_file_upload_percentage' | translate }}
              </th>
              <td mat-cell showIfTruncated *matCellDef="let row">{{ row.uploadStatus.progressCount }}%</td>
            </ng-container>
            <ng-container matColumnDef="progress">
              <th mat-header-cell *matHeaderCellDef></th>
              <td
                mat-cell
                *matCellDef="let row"
                showIfTruncated
                [matTooltip]="row.uploadStatus.errorMessage"
                [ngClass]="row.uploadStatus.isError ? 'isError' : 'isSuccess'"
              >
                <mat-progress-bar
                  *ngIf="!row.uploadStatus.isSuccess && !row.uploadStatus.isError"
                  mode="buffer"
                  color="primary"
                  [value]="row.uploadStatus.progressCount"
                ></mat-progress-bar>
                <ng-container *ngIf="row.uploadStatus.isSuccess">
                  {{ 'entity_file_upload_success' | translate }}
                </ng-container>
                <ng-container *ngIf="row.uploadStatus.isError"> {{ row.uploadStatus.errorMessage }}</ng-container>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columns"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="progress-error" *ngIf="progressErrorMessage">
    <span class="error" [innerHTML]="progressErrorMessage | translate"></span>
  </div>
  <div class="progress-error-description" *ngIf="progressErrorMessage">
    <pre class="error" [innerHTML]="progressErrorMessageDescription | translate"></pre>
  </div>
  <div class="progress-message-title" *ngIf="!progressErrorMessage">
    <span>{{ progressTitleMessageKey | translate }}</span>
    <mat-progress-bar
      [mode]="progressErrorMessage ? 'determinate' : 'buffer'"
      color="primary"
      [value]="progressPercentage"
    ></mat-progress-bar>
  </div>
  <div class="progress-messages" *ngIf="progressMsgDatasource && progressMsgDatasource.data.length > 0">
    <div class="entity-table">
      <div class="table-wrapper">
        <div #messagesContainer class="table-container">
          <table class="table table-stripes table-truncate-text" mat-table [dataSource]="progressMsgDatasource">
            <ng-container matColumnDef="progressTimestamp">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'progressstep_progresstimestamp' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="row.progressTimestamp" *matCellDef="let row">
                {{ getTimestamp(row.progressTimestamp) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="progressText">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'progressstep_progressmessage' | translate }}
              </th>
              <td mat-cell showIfTruncated [matTooltip]="row.progressText" *matCellDef="let row">{{ row.progressText }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="progressDisplayedColumnsTable; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: progressDisplayedColumnsTable"></tr>
          </table>
        </div>
      </div>
    </div>
  </div> -->
</div>
