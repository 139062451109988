import { HelpChatbotData } from 'src/app/models/help';

export interface State {
  openChatbot: boolean;
  source: string;
  messages: HelpChatbotData[];
}

export const initialState: State = {
  openChatbot: false,
  source: null,
  messages: [],
};
