/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { DemoAuthComponent } from './auth/demo-auth/demo-auth.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordByadminDialogComponent } from './auth/reset-password-byadmin-dialog/reset-password-byadmin-dialog.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { HomepageComponent } from './homepage/homepage.component';
import { CustomEntityModalComponent } from './modal/custom-entity-modal/custom-entity-modal.component';
import { EntityModalComponent } from './modal/entity-modal/entity-modal.component';
import { FilesMultiselectionModalComponent } from './modal/files-multiselection-modal/files-multiselection-modal.component';
import { FilesMultiselectionTabComponent } from './modal/files-multiselection-modal/files-multiselection-tab/files-multiselection-tab.component';
import { FilesMultiselectionTableComponent } from './modal/files-multiselection-modal/files-multiselection-table/files-multiselection-table.component';
import { FilesMultiselectionTreeComponent } from './modal/files-multiselection-modal/files-multiselection-tree/files-multiselection-tree.component';
import { OptionsModalComponent } from './modal/options-modal/options-modal.component';
import { PropertyColumnModalComponent } from './modal/property-column-modal/property-column-modal.component';
import { RemoveRecordsModalComponent } from './modal/remove-records-modal/remove-records-modal.component';
import { ServiceUsageModalComponent } from './modal/service-usage-modal/service-usage-modal.component';
import { FeeditemEntityModalComponent } from './modal/specific-entitity-modals/feeditem-entity-modal/feeditem-entity-modal.component';
import { PreloadImageComponent } from './preload-image/preload-image.component';
import { ResponsiveBreakpointDirective } from './responsive-breakpoints/responsive-breakpoint.directive';
import { ResponsiveBreakpointsComponent } from './responsive-breakpoints/responsive-breakpoints.component';
import { ResponsiveBreakpointsService } from './responsive-breakpoints/responsive-breakpoints.service';
// Side menu stuff
import { SideMenuContentComponent } from './side-menu/side-menu-content/side-menu-content.component';
// Required modules
import { AuthLicenseRenewalDialogComponent } from './auth/auth-license-renewal-dialog/auth-license-renewal-dialog.component';
import { DirectLoginOauthComponent } from './auth/direct-login-oauth/direct-login-oauth.component';
import { LoginOauthComponent } from './auth/login-oauth-okta/login-oauth-okta.component';
import { CustomConfigurableModalComponent } from './modal/custom-configurable-modal/custom-configurable-modal.component';
import { EntitySaveProgressDialogComponent } from './modal/entity-save-progress-dialog/entity-save-progress-dialog.component';
import { NewsModalComponent } from './modal/news-modal/news-modal.component';
import { SpreadsheetGenerationDialogComponent } from './modal/spreadsheet-generation-dialog/spreadsheet-generation-dialog.component';
import { ViewasuserModalDialogComponent } from './modal/viewasuser-modal-dialog/viewasuser-modal-dialog.component';
import { SideMenuService } from './side-menu/side-menu.service';
import { TaricsSendEntitlementsComponent } from './tarics-send-entitlements/tarics-send-entitlements.component';
import { TopNavbarContentComponent } from './top-navbar/content/top-navbar-content.component';
import { GlobalSearchBarComponent } from './top-navbar/global-search-bar/global-search-bar.component';
import { FilesUploadProgressDialogComponent } from './modal/files-upload-progress-dialog/files-upload-progress-dialog.component';
import { FinesseChatbotComponent } from './finesse-chatbot/finesse-chatbot.component';

@NgModule({
  declarations: [
    LoginComponent,
    PreloadImageComponent,
    // Side menu stuff
    SideMenuContentComponent,
    // Top navbar stuff
    TopNavbarContentComponent,
    GlobalSearchBarComponent,
    // Responsive stuff
    ResponsiveBreakpointDirective,
    ResponsiveBreakpointsComponent,
    EntityModalComponent,
    CustomEntityModalComponent,
    OptionsModalComponent,
    ResetPasswordComponent,
    ServiceUsageModalComponent,
    RemoveRecordsModalComponent,
    DemoAuthComponent,
    HomepageComponent,
    TaricsSendEntitlementsComponent,
    PropertyColumnModalComponent,
    FeeditemEntityModalComponent,
    FilesMultiselectionModalComponent,
    FilesMultiselectionTabComponent,
    FilesMultiselectionTreeComponent,
    FilesMultiselectionTableComponent,
    ResetPasswordByadminDialogComponent,
    SpreadsheetGenerationDialogComponent,
    CustomConfigurableModalComponent,
    EntitySaveProgressDialogComponent,
    AuthLicenseRenewalDialogComponent,
    ViewasuserModalDialogComponent,
    LoginOauthComponent,
    NewsModalComponent,
    DirectLoginOauthComponent,
    FilesUploadProgressDialogComponent,
    FinesseChatbotComponent,
  ],
  imports: [SharedModule],
  providers: [
    SideMenuService,
    // Responsive stuff
    ResponsiveBreakpointsService,
  ],
  exports: [
    LoginComponent,
    PreloadImageComponent,
    SideMenuContentComponent,
    TopNavbarContentComponent,
    // Responsive stuff
    ResponsiveBreakpointsComponent,
    DemoAuthComponent,
  ],
})
export class CoreModule {}
