import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { BaseResponse } from '../models/base-response';
import { HelpChatbotData, HelpData } from '../models/help';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class HelpDataService {
  getHelpData(filter?: string): Observable<BaseResponse<HelpData[]>> {
    const m = this.getHelpData.name;

    const path = environment.getEndpoint(ApiPath.Help.HELP);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'filter', filter);
    let params = new HttpParams().set('filter', filter.toString());
    const retVal = this.http.get<BaseResponse<HelpData[]>>(path, { params });
    return retVal;
  }

  getHelpChatbotData(source: string, question?: string): Observable<BaseResponse<HelpChatbotData>> {
    const m = this.getHelpData.name;

    const path = environment.getEndpoint(ApiPath.Help.HELP_CHATBOT);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'question', question);
    let params = new HttpParams();
    if (source) {
      params = params.append('source', source);
    }
    if (question) {
      params = params.append('question', question);
    }
    const retVal = this.http.get<BaseResponse<HelpChatbotData>>(path, { params });
    return retVal;
  }

  getHelpCommonQuestionResponse(source: string, questionId: number): Observable<BaseResponse<HelpChatbotData>> {
    const m = this.getHelpData.name;

    const path = environment.getEndpoint(ApiPath.Help.HELP_CHATBOT_RESPONSE(questionId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = new HttpParams();
    if (source) {
      params = params.append('source', source);
    }

    const retVal = this.http.get<BaseResponse<HelpChatbotData>>(path, { params });
    return retVal;
  }

  commitQuestionResponse(source: string, responseId: number): Observable<BaseResponse<HelpChatbotData>> {
    const m = this.getHelpData.name;

    const path = environment.getEndpoint(ApiPath.Help.HELP_CHATBOT_RESPONSE_COMMIT(responseId));
    LogService.info(this, m, LogService.POST + path, null);
    let params = new HttpParams();
    if (source) {
      params = params.append('source', source);
    }

    const retVal = this.http.post<BaseResponse<HelpChatbotData>>(path, { params });
    return retVal;
  }

  denyQuestionResponse(source: string, responseId: number): Observable<BaseResponse<HelpChatbotData>> {
    const m = this.getHelpData.name;

    const path = environment.getEndpoint(ApiPath.Help.HELP_CHATBOT_RESPONSE_DENY(responseId));
    LogService.info(this, m, LogService.POST + path, null);
    let params = new HttpParams();
    if (source) {
      params = params.append('source', source);
    }

    const retVal = this.http.post<BaseResponse<HelpChatbotData>>(path, { params });
    return retVal;
  }

  constructor(private http: HttpClient) {}
}
