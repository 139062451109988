import { KeyValue } from '@angular/common';
import { Entity, EntityRecord } from './entity';

export class User extends Entity {
  userId: number;
  userName: string;
  userFirstname: string;
  userPno: string;
  userLogin: string;
  userEmail: string;
  userPhone: string;
  userMobile: string;
  userType: string;
  userTypeId: number;
  userGroupType: string;
  userGroupTypeEnabled: boolean;
  userGroupTypeId: number;
  userRoles: EntityRecord[];
  userDelegates: EntityRecord[];
  userStarts: Date;
  userEnds: Date;
  userEnabled: boolean;
  userAliases: string;
  userInfo1: string;
  userInfo1Enabled: boolean;
  userInfo2: string;
  userInfo2Enabled: boolean;
  userLocked: boolean;
  userPasswordvaliduntil: string;
  userRemarks: string;
  userGroupTypeOptions: KeyValue<string, string>[];
  userTypeOptions: KeyValue<string, string>[];
  userRoleRights: boolean;
}

export class UserResponsibility {
  userresponsibilityEntity: string;
  userresponsibilityEntityId: number;
  userresponsibilityObject: string;
  userresponsibilityObjectId: number;
  userresponsibilityType: string;
  userresponsibilityTypeId: number;
  userresponsibilityUser: string;
  userresponsibilityUserId: number;
}

export class UserParticularRequest {
  userEmail: string;
  userEnabled: boolean;
  userEnds: string;
  userFirstname: string;
  userGroupTypeId: number;
  userAliases: string;
  userInfo1: string;
  userInfo2: string;
  userLocked: boolean;
  userLogin: string;
  userLogin2: string;
  userMobile: string;
  userName: string;
  userPasswordvaliduntil: string;
  userPhone: string;
  userPno: string;
  userRemarks: string;
  userRoles: EntityRecord[];
  userDelegates: EntityRecord[];
  userStarts: string;
  userTypeId: number;
}

export const HAS_ALLOCATIONS_YES = 1;

export class UserParams {
  public static FILTER_ROLE_IDS = 'filterRoleIds';
  public static FILTER_COSTCENTRE_IDS = 'filterCostcentreIds';
  public static FILTER_ORGANISATION_IDS = 'filterOrganisationIds';
  public static FILTER_LOCATION_IDS = 'filterLocationIds';
  public static FILTER_HASALLOCATION_IDS = 'filterHasallocationIds';
  public static FILTER_TYPES = 'filterTypeIds';
  public static FILTER_GROUP_TYPES = 'filterGrouptypeIds';
  public static FILTER_ASSET_IDS = 'filterAssetIds';
  public static FILTER_PRODUCTFAMILY_IDS = 'filterProductfamilyIds';
  public static FILTER_SERVICE_IDS = 'filterServiceIds';
  public static FILTER_SHARED_IDS = 'filterSharedIds';
  public static FILTER_BILLINGACCOUNT_IDS = 'filterBillingaccountIds';
}
