export class DefaultColsTable {
  public static ADMIN_TEXTS = ['text_language', 'text_name', 'text_system', 'text_custom', 'text_modified', 'text_modifiedby'];
  public static ADMINUSER_COLUMNS = ['userLogin', 'userName', 'userFirstname', 'userPno', 'userEmail', 'userRoles'];
  public static ACCOUNT_COLUMNS = ['account_no', 'account_name', 'account_type', 'account_code', 'account_remarks'];
  public static ADDRESS_COLUMNS = [
    'addressSalutation',
    'addressLastname',
    'addressFirstname',
    'addressBusinesspartner',
    'addressBusiness',
    'addressAddress',
    'addressZip',
    'addressCity',
    'addressCountry',
  ];
  public static BILLING_ITEM_COLUMNS = ['billingitemService', 'billingitemBiller', 'billingitemBillingaccount', 'billingitemTooltip'];
  public static BUDGET_COLUMNS = ['budget_no', 'budget_name', 'budget_abb', 'budget_remarks'];
  public static BUDGETBROWSER_COLUMNS = ['name', 'budget', 'forecast', 'variance'];
  public static CONTRACT_COLUMS = ['contract_topic', 'actions', 'contract_type', 'contract_number', 'contract_status', 'info'];
  public static CONTRACT_UPCOMING_RENEWALS_COLUMS = [
    'contract_name',
    'actions',
    'contract_issuer',
    'contract_type',
    'contract_number',
    'contract_start_date',
    'contract_cancellation_date',
    'contract_notice_period',
    'contract_renewal_date',
    'contract_end_date',
    'contract_status',
    'info',
    // 'contract_beneficiarylegalentities',
    // 'contract_organisationrestrictions',
    // 'contract_locationrestrictions',
    // 'contract_ends',
    // 'contract_currency',
  ];
  public static CURRENCY_COLUMS = ['currency_iso', 'currency_unit', 'currency_symbol', 'currency_description', 'currency_is_reporting'];
  public static COSTBROWSER_COLUMNS = [
    'name',
    'total',
    'month0',
    'month1',
    'month2',
    'month3',
    'month4',
    'month5',
    'month6',
    'month7',
    'month8',
    'month9',
    'month10',
    'month11',
  ];
  public static LANGUAGE_COLUMNS = ['language_name', 'language_code'];
  public static LICENSE_COLUMS = ['license_product_family', 'license_name', 'license_platform', 'license_site'];
  public static MESSAGE_COLUMS = ['message_level', 'message_user', 'message_content', 'message_sent'];
  public static REQUEST_COLUMS = [
    'info',
    'request_effectivedate',
    'actions',
    'request_source',
    'request_action',
    'request_receiver',
    'request_reference',
    'request_requestor',
    'request_assigned_user',
    'request_no',
    'request_state',
    'request_requested',
    'request_sync',
    //'request_type',
    //'request_id',
    //'request_no',
    //'request_subject',
    //'request_error',
    //'request_state',
    //'request_actiondate',
    //'request_actionstate',
    //'request_actionremarks',
  ];
  public static SERVICE_COLUMS = ['serviceName', 'serviceRemarks', 'serviceAbb', 'serviceType', 'serviceProductfamily', 'serviceIssuer'];
  public static TERMSANDREGULATIONS_COLUMS = [
    'terms_name',
    'terms_invoiceperiod',
    'terms_noticedate',
    'terms_billperiod',
    'terms_starts',
    'terms_ends',
  ];
  public static USER_COLUMS = [
    'userName',
    'actions',
    'userStarts',
    'userEnds',
    'userCurrency',
    'userCostPeriod',
    'userUnitCosts',
    'userFixedCosts',
    'userAdditionalCosts',
  ];
  public static CONSUMER_COLUMS = [
    'consumerName',
    'actions',
    'consumerAllocation',
    'consumerDesk',
    'consumerStarts',
    'consumerEnds',
    'consumerCurrency',
    'consumerCostPeriod',
    'consumerUnitCosts',
    'consumerFixedCosts',
  ];

  public static VENDOR_COLUMNS = [
    'vendorName',
    'actions',
    'vendorAddress',
    'vendorParent',
    'vendorAccount',
    'vendorBudget',
    'vendorRemarks',
  ];
  public static BILLINGACCOUNT_COLUMNS = [
    'billingaccountBillingvendor',
    'actions',
    'billingaccountAccount',
    'billingaccountType',
    'billingaccountProductfamilies',
    'billingaccountRemarks',
    'billingaccountStarts',
    'billingaccountEnds',
    'billingaccountTenant',
    'billingaccountTenantLedger',
  ];
  public static FEED_ITEM_COLUMN = [
    'feeditemName',
    'feeditemCode',
    'feeditemServiceProviderCode',
    'feeditemProvider',
    'feeditemPlatform',
    'feeditemSite',
  ];
  public static SYSTEMMONITOR_COLUMNS = [
    'systemmonitor_pid',
    'systemmonitor_username',
    'systemmonitor_application_name',
    'systemmonitor_client_addr',
    'systemmonitor_backend_start',
    'systemmonitor_state',
    'systemmonitor_wait_event',
    'systemmonitor_blocking_pids',
    'systemmonitor_query',
    'systemmonitor_state_change',
    'systemmonitor_query_start',
    'systemmonitor_backend_type',
    'systemmonitor_active_since',
    'systemmonitor_color',
  ];
  public static INDEX_COLUMNS = [
    'index_name',
    'actions',
    'index_group',
    'index_issuer',
    'index_is_licensed',
    //'index_license_issue',
    //'index_custom_index',
    'index_kind',
    'index_packages',
    'index_nof_licensed',
  ];
  public static INDEX_PACKAGE_CONTENT_COLUMNS = [
    'indexpackagecontent_index',
    'actions',
    'indexpackagecontent_group',
    'indexpackagecontent_is_licensed',
    //'indexpackagecontent_license_issue',
    //'indexpackagecontent_custom_index',
    'indexpackagecontent_index_kind',
    'indexpackagecontent_nof_licensed',
  ];
  public static PRODUCT_COLUMS = ['lid', 'logicalDetailAddress', 'logicalPlatform', 'logicalSite'];
  public static INVOICE_COLUMS = [
    'invoice_invoiceno',
    'invoice_reference',
    'invoice_account',
    'invoice_vendor',
    'invoice_status',
    'invoice_date',
    'invoice_period',
    //'invoice_perioddates',
    'invoice_starts',
    'invoice_ends',
    'invoice_subtotal',
    'invoice_total',
    //'invoice_remarks',
    'info',
    'invoiceEmailCount',
  ];
  public static INVOICE_CREDITNOTE_COLUMS = [
    'invoicecreditnote_invoiceno',
    'invoicecreditnote_reference',
    'invoicecreditnote_account',
    'invoicecreditnote_vendor',
    'invoicecreditnote_status',
    'invoicecreditnote_date',
    'invoicecreditnote_period',
    //'invoicecreditnote_perioddates',
    'invoicecreditnote_starts',
    'invoicecreditnote_ends',
    'invoicecreditnote_subtotal',
    'invoicecreditnote_total',
    //'invoicecreditnote_remarks',
    'info',
    //'invoicecreditnoteEmailCount',
  ];
  public static INVOICESTATUS_COLUMS = ['invoicestatus_name', 'invoicestatus_accrue', 'invoicestatus_paid', 'invoicestatus_readonly'];
  public static LOG_COLUMS = [
    'log_entity',
    'log_entity_id',
    'log_field',
    'log_kind',
    'log_fromvalue',
    'log_tovalue',
    'log_modified',
    'log_transaction',
    'log_modifiedby',
  ];

  public static PRICE_COLUMS = [
    'price_vendor',
    'price_service',
    'price_starts',
    'price_currency',
    'price_basic',
    'price_access',
    'price_other',
    'row_action_1',
  ];

  public static PRICE_NODES_COLUMS = [
    'price_vendor_service_name',
    'actions',
    'price_starts',
    'price_currency',
    'price_basic',
    'price_access',
    'price_other',
    // 'row_action_1',
  ];

  public static JOB_COLUMS = [
    'job_name',
    'actions',
    'job_status',
    'job_progress',
    'job_started',
    'job_ended',
    'job_message',
    'job_assigned_user',
    'job_ondemand',
  ];

  public static JOB_SCHEDULE_COLUMS = [
    'schedule_name',
    'actions',
    'schedule_status',
    'schedule_progress',
    'schedule_started',
    'schedule_ended',
    'schedule_message',
    'schedule_assigned_user',
    'schedule_active',
    //'schedule_ondemand',
  ];

  public static TASK_COLUMNS = [
    'task_summary',
    'actions',
    'task_topic',
    'task_priority',
    'task_status',
    'task_duedate',
    'task_creator',
    'task_assignedteam',
    'task_assignee',
    // 'actionButtons',
  ];

  public static RECURRING_TASK_COLUMNS = [
    'task_summary',
    'actions',
    'task_topic',
    'task_priority',
    'task_status',
    'task_recurring_dateperiod',
    'task_duedate',
    'task_creator',
    'task_assignedteam',
    'task_assignee',
    // 'actionButtons',
  ];

  public static TASK_SCHEDULE_COLUMS = [
    'schedule_name',
    'schedule_status',
    'schedule_progress',
    'schedule_started',
    'schedule_ended',
    'schedule_message',
    'schedule_assigned_user',
    'schedule_active',
  ];

  public static TAXES_COLUMS = ['tax_name', 'actions', 'tax_abbreviation'];

  public static TENANTS_COLUMNS = [
    'tenant_name',
    'actions',
    'tenant_currency',
    'tenant_accounting_tenant',
    'tenant_by_start_month',
    'tenant_chargeback_interface',
    'tenant_finance_interface',
    'tenant_vat',
  ];

  public static ORGANISATIONDESK_COLUMS = ['desk_organisation', 'desk_costcentre', 'desk_location', 'desk_starts', 'desk_ends'];

  public static COSTCENTRE_COLUMS = [
    'costcentre_no',
    'costcentre_abb',
    'costcentre_name',
    'costcentre_level',
    'costcentre_type',
    'costcentre_starts',
    'costcentre_parent',
    'costcentre_remarks',
  ];
  public static ENTITY_COLUMS = ['entity_name', 'entity_description', 'info'];
  public static LOCATION_COLUMS = ['location_no', 'location_abb', 'location_name', 'location_level', 'location_parent', 'location_remarks'];
  public static ORGANISATION_COLUMS = [
    'organisation_name',
    'organisation_abb',
    'organisation_no',
    'organisation_level',
    'organisation_starts',
    'organisation_ends',
    'organisation_parent',
  ];
  public static PACKAGE_COLUMNS = [
    'package_billingitem',
    'actions',
    'package_starts',
    'package_usecount',
    'package_current',
    'package_rule',
  ];
  public static PLATFORM_COLUMNS = ['platform_name', 'platform_type', 'platform_status', 'platform_remarks'];
  public static PRIORITY_COLUMNS = ['priority_name', 'priority_starts', 'priority_ends'];
  public static PRODUCT_COLUMNS = ['product_name', 'product_vendor', 'product_platform', 'product_site'];
  public static PRODUCTFAMILY_COLUMNS = [
    'productfamily_name',
    'productfamily_provider',
    'productfamily_permissioningsystem',
    'productfamily_site',
  ];
  public static REPORT_COLUMS = ['report_name', 'report_kind', 'report_custom'];
  public static REPORTSTYLE_COLUMS = ['reportstyle_report', 'reportstyle_style'];
  public static COSTREPORT_COLUMS = [
    'report_name',
    'actions',
    'report_autodistribution',
    'report_owner',
    'report_format',
    'report_created',
    'report_lastexecuted',
    'report_template',
  ];
  public static SAVEDREPORT_COLUMS = [
    'report_name',
    'report_template',
    'report_type',
    'report_kind',
    'report_autoreport',
    'report_flyreport',
    'report_custom',
    'report_owner',
  ];
  public static SAVEDREPORT_COLUMS_FIELDS = [
    'reportfield_hide',
    'reportfield_name',
    'reportfield_filtertype',
    'reportfield_filtervalue',
    'reportfield_sort',
    'reportfield_sortorder',
  ];
  public static ROLE_COLUMS = ['roleName', 'rolePriority', 'roleDefaultrole', 'roleSystemrole', 'roleOrderrole'];
  public static SURVEY_COLUMNS = [
    'survey_name',
    'survey_remarks',
    'survey_starts',
    'survey_ends',
    'survey_issuer',
    'survey_lastchanged',
    'survey_test',
  ];
  public static TYPE_COLUMNS = ['type_kind', 'type_name'];
  public static TYPE_COLUMNS_MIN = ['type_name'];
  public static BB_ACCOUNTS_COLUMNS = [
    'bbAccountsIgnore',
    'bbAccountsValid',
    'bbAccountsIsnew',
    'bbAccountsBbaccount',
    'bbAccountsLocation',
    'bbAccountsContractyears',
    'bbAccountsName',
    'bbAccountsCity',
    'bbAccountsCountry',
    'bbAccountsCurrency',
    'bbAccountsTax',
    'bbAccountsExchangecu',
    'bbAccountsExchangeaccount',
    'bbAccountsExchangebudget',
    'bbAccountsAdmincu',
    'bbAccountsAdminaccount',
    'bbAccountsAdminbudget',
    'bbAccountsLicensecu',
    'bbAccountsLicenseaccount',
    'bbAccountsLicensebudget',
    'bbAccountsHardwarecu',
    'bbAccountsHardwareaccount',
    'bbAccountsHardwarebudget',
  ];
  public static BB_CURRENCY_COLUMNS = [
    'bbCurrencyValid',
    'bbCurrencyNew',
    'bbCurrencyCurrency',
    'bbCurrencyIso',
    'bbCurrencyIsoname',
    'bbCurrencySign',
  ];
  public static BB_ADMINFEES_COLUMNS = ['bbAdminfeeValid', 'bbAdminfeeIsnew', 'bbAdminfeeAdm', 'bbAdminfeeAdmname', 'bbAdminfeeAbb'];
  public static BB_HARDWARECOSTS_COLUMNS = [
    'bbHardwarecostValid',
    'bbHardwarecostIsnew',
    'bbHardwarecostTerminaltype',
    'bbHardwarecostSubcost',
    'bbHardwarecostStarts',
    'bbHardwarecostEnds',
  ];
  public static BB_THIRDPARTIES_COLUMNS = [
    'bbThirdpartyValid',
    'bbThirdpartyIsnew',
    'bbThirdpartyBbaccount',
    'bbThirdpartyExchange',
    'bbThirdpartyExchangename',
    'bbThirdpartyStarts',
    'bbThirdpartyEnds',
    'bbThirdpartyDefaultaccount',
    'bbThirdpartyAccount',
    'bbThirdpartyDefaultbudget',
    'bbThirdpartyBudget',
    'bbThirdpartyDefaultcalculationunit',
    'bbThirdpartyCalculationunit',
  ];

  static Interfaces = class {
    public static IIF_SERVICE_MAPPINGS_COLUMNS = [
      'iifservicemappingIgnore',
      'iifservicemappingValid',
      'iifservicemappingIsnew',
      'iifservicemappingInvoiceName',
      'iifservicemappingInvoiceCode',
      'iifservicemappingPermissionName',
      'iifservicemappingPermissionCode',
      'iifservicemappingPermissioningSystem',
      'iifservicemappingSite',
      'iifservicemappingCost',
      'iifservicemappingBillingAccount',
      'iifservicemappingOldBillingAccount',
      'iifservicemappingService',
      'iifservicemappingFixedCostService',
    ];
    public static IIF_TEMPLATE_CONFIG_COLUMNS = [
      'iiftemplateDescription',
      'iiftemplateModule',
      'iiftemplateVendor',
      'iiftemplateCurrency',
      'iiftemplatePlatform',
      'iiftemplateSite',
    ];
    public static IIF_MODULE_CONFIG_COLUMNS = [
      'iifmoduleName',
      'iifmoduleDescription',
      'iifmoduleKind',
      'iifmoduleType',
      'iifmoduleStatus',
      'iifmoduleFixed',
    ];
    public static IIF_MESSAGES_COLUMNS = [
      'iifmessageFileindex',
      'iifmessageRowindex',
      'iifmessageColumn1',
      'iifmessageColumn2',
      'iifmessageColumn3',
      'iifmessageColumn4',
      'iifmessageColumn5',
      'iifmessageType',
      'iifmessageText',
    ];
    public static IIF_USERS_COLUMNS = [
      'iifuserSelected',
      'iifuserStatus',
      'iifuserAsset',
      'iifuserProduct',
      'iifuserProvider',
      'iifuserPlatform',
      'iifuserAccount',
      'iifuserRemarks',
      'iifuserDeskNew',
      'iifuserDeskOld',
      'iifuserFeedstartsNew',
      'iifuserFeedendsNew',
      'iifuserFeedstartsOld',
      'iifuserFeedendsOld',
      'iifuserStartsNew',
      'iifuserEndsNew',
      'iifuserStartsOld',
      'iifuserEndsOld',
    ];
    public static IIF_PRODUCTS_COLUMNS = [
      'iifproductSelected',
      'iifproductStatus',
      'iifproductAsset',
      'iifproductProduct',
      'iifproductProvider',
      'iifproductPlatform',
      'iifproductAccount',
      'iifproductRemarks',
      'iifproductStarts',
      'iifproductEnds',
    ];
    public static IIF_SERVICES_COLUMNS = [
      'iifserviceSelected',
      'iifserviceStatus',
      'iifserviceNew',
      'iifserviceServiceAliasName',
      'iifserviceServiceAliasAbb',
      'iifserviceProvider',
      'iifservicePlatform',
      'iifserviceBillingAccount',
      'iifserviceVendor',
    ];
    public static IIF_COSTS_COLUMNS = [
      'iifcostSelected',
      'iifcostStatus',
      'iifcostServiceName',
      'iifcostServiceAbb',
      'iifcostProvider',
      'iifcostPlatform',
      'iifcostBillingAccount',
      'iifcostVendor',
      'iifcostIsoNew',
      'iifcostAccessCostNew',
      'iifcostBasicCostNew',
      'iifcostOtherCostNew',
      'iifcostDiscountNew',
      'iifcostSchemeNew',
      'iifcostIsoOld',
      'iifcostAccessCostOld',
      'iifcostBasicCostOld',
      'iifcostOtherCostOld',
      'iifcostDiscountOld',
      'iifcostSchemeOld',
      'iifcostStarts',
      'iifcostEnds',
    ];
    public static IIF_PERMISSIONS_COLUMNS = [
      'iifpermissionSelected',
      'iifpermissionStatus',
      'iifpermissionAsset',
      'iifpermissionProduct',
      'iifpermissionServiceAliasName',
      'iifpermissionServiceAliasAbb',
      'iifpermissionProvider',
      'iifpermissionPlatform',
      'iifpermissionBillingAccount',
      // 'iifpermissionVendor',
      'iifpermissionFeedstartsNew',
      'iifpermissionFeedendsNew',
      'iifpermissionFeedstartsOld',
      'iifpermissionFeedendsOld',
      'iifpermissionStartsNew',
      'iifpermissionEndsNew',
      'iifpermissionStartsOld',
      'iifpermissionEndsOld',
      'iifpermissionNoofaccessNew',
      'iifpermissionNoofaccessOld',
      'iifpermissionFocofnoofaccessNew',
      'iifpermissionFocofnoofaccessOld',
    ];
    public static BB_RELOCATIONS_COLUMNS = [
      'iifrelocationCustNum',
      'iifrelocationDate',
      'iifrelocationTime',
      'iifrelocationStart',
      'iifrelocationStop',
      'iifrelocationSid',
      'iifrelocationSidInstNum',
      'iifrelocationOrderNum',
      'iifrelocationLine',
      'iifrelocationType',
      'iifrelocationCode',
      'iifrelocationDescription',
      'iifrelocationPoNumber',
      'iifrelocationSpecial',
      'iifrelocationFromCustNum',
      'iifrelocationFromCompletionDate',
      'iifrelocationToCustNum',
      'iifrelocationToCompletionDate',
    ];
    public static BB_SUBSCRIPTIONS_COLUMNS = [
      'iifsubscription90Day',
      'iifsubscriptionCustNum',
      'iifsubscriptionSid',
      'iifsubscriptionSidInstNum',
      'iifsubscriptionContractDate',
      'iifsubscriptionRenewalDate',
      'iifsubscriptionLastUser',
      'iifsubscriptionSidType',
      'iifsubscriptionSidDescription',
      'iifsubscriptionTermType',
      'iifsubscriptionDescription',
      'iifsubscriptionSn',
      'iifsubscriptionWs',
      'iifsubscriptionSpecial',
      'iifsubscriptionPrice',
      'iifsubscriptionDualInstNum',
      'iifsubscriptionDualCustNum',
      'iifsubscriptionPoNumber',
    ];
    public static BB_INVOICES_COLUMNS = [
      'iifinvoiceCustNum',
      'iifinvoiceInvoiceNumber',
      'iifinvoiceInvoiceLine',
      'iifinvoiceSid',
      'iifinvoiceSidInstNum',
      'iifinvoiceContractDate',
      'iifinvoiceSidType',
      'iifinvoiceSidDescription',
      'iifinvoiceGptt',
      'iifinvoiceGpttDescription',
      'iifinvoiceSerialNumber',
      'iifinvoiceSuperClass',
      'iifinvoiceSuperDescription',
      'iifinvoiceUnitPrice',
      'iifinvoiceBillFromDate',
      'iifinvoiceBillToDate',
      'iifinvoiceExtendedPrice',
    ];
  };
  public static ENTITY_FILES_COLUMN = [
    'filenodeName',
    'filenodeType',
    'filenodeSize',
    'filenodeUploaded',
    'filenodeUploadedby',
    'details',
    'actionButtons',
  ];
  public static ENTITY_FILES_WITH_SEND_COLUMN = [
    'filenodeName',
    'filenodeSend',
    'filenodeType',
    'filenodeSize',
    'filenodeUploaded',
    'filenodeUploadedby',
    'details',
    'actionButtons',
  ];

  public static ENTITY_DASHBOARD_COLUMNS = ['widgetRadioButton', 'widgetEntityName', 'widgetEntityDescription'];

  public static TENANTS = ['tenant_description', 'tenant_name', 'tenant_muser', 'tenant_mdate'];
  public static LOGINS = [
    'login_email',
    'login_fullname',
    'login_starts',
    'login_ends',
    'login_last_action',
    'login_locked',
    'login_disabled',
    'login_passwordvaliduntil',
    'login_admin',
  ];

  public static NOTIFICATIONS = ['info', 'notificationSource', 'notificationType', 'actions', 'notificationDate', 'notificationMessage'];
  public static ASSET_NODES_COLUMNS = [
    'assetLabel',
    'actions',
    'assetQuantity',
    'assetBillingStarts',
    'assetNoticeDate',
    'assetRolloverDate',
    'assetExpiryDate',
    // 'assetUser',
    'assetWaitingroomDate',
    'assetCurrency',
    //'assetCostPeriod',
    'assetUnitCosts',
    'assetFixedCosts',
    //'assetAdditionalCosts',
  ];
  public static ASSET_PER_USER_NODES_COLUMNS = [
    'assetName',
    // 'actions',
    'assetNoticeDate',
    'assetRolloverDate',
    'assetExpiryDate',
    'assetAsset',
  ];
  public static CATALOG_NODES_COLUMNS = [
    'catalogName',
    'actions',
    'catalogCode',
    'catalogLicenses',
    'catalogCurrency',
    'catalogCostPeriod',
    'catalogUnitCosts',
    'catalogFixedCosts',
    'catalogCostPerUser',
  ];
}
