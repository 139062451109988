<div mat-dialog-content>
  <div class="chat-container">
    <div class="top">
      <button class="close-button" mat-icon-button [disableRipple]="true" mat-dialog-close (click)="close()">
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
      </button>
      <div class="chatbot-icon">
        <mat-icon fontSet="fas" fontIcon="fa-comments"></mat-icon>
      </div>
      <div class="chatbot-title">
        <!-- <span class="title">{{ 'label_finesse' | translate }}</span>
        <span class="subtitle">{{ 'label_online' | translate }}</span> -->
        <span class="title">{{ 'Finesse' | translate }}</span>
        <span class="subtitle">{{ 'Online' | translate }}</span>
      </div>
    </div>
    <div #messagesContainer class="conversation">
      <ng-container *ngIf="messages">
        <ng-container *ngFor="let message of messages; let index = index">
          <div class="message" [ngClass]="!message.isUser ? 'finesse' : 'user'">
            <div class="message-header" [matTooltip]="getDatetime(message.timestamp)">
              <div class="finesse-user" *ngIf="!message.isUser">&nbsp;</div>
              <span>{{ message.responder }} </span>
            </div>
            <span class="message-info" *ngIf="message.info">{{ message.info | translate }}</span>
            <span class="message-text" *ngIf="message.response" [innerHtml]="message.response"></span>
            <span class="message-info" *ngIf="message.message">{{ message.message | translate }}</span>
            <div class="message-common-questions" *ngIf="message.commonQuestions">
              <div class="question" *ngFor="let question of message.commonQuestions" (click)="askCommonQuestion(question)">
                {{ question.value }}
              </div>
            </div>
            <div class="message-help" *ngIf="message.help">
              <span class="help">{{ message.help | translate }}</span>
              <button
                class="help-button"
                [ngClass]="message.feedbackSent && message.feedbackValue ? 'clicked' : 'disabled'"
                mat-icon-button
                [disableRipple]="true"
                (click)="commit(message)"
                [disabled]="message.feedbackSent"
              >
                <mat-icon fontSet="fas" fontIcon="fa-thumbs-up" color="primary"></mat-icon>
              </button>
              <button
                class="help-button"
                [ngClass]="message.feedbackSent && !message.feedbackValue ? 'clicked' : 'disabled'"
                mat-icon-button
                [disableRipple]="true"
                (click)="deny(message)"
                [disabled]="message.feedbackSent"
              >
                <mat-icon fontSet="fas" fontIcon="fa-thumbs-down"></mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <mat-toolbar class="table-footer-toolbar" *ngIf="isLoading">
        <mat-toolbar-row *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="50"></mat-spinner>
        </mat-toolbar-row>
        <!-- <mat-toolbar-row *ngIf="messages.length === 0 && !isLoading" class="no-data-message">
          <span>{{ 'chatbot_error_nodata' | translate }}</span>
        </mat-toolbar-row> -->
      </mat-toolbar>
    </div>
    <div class="typing">
      <input
        type="text"
        matInput
        [(ngModel)]="inputMessage"
        cdkTextareaAutosize
        placeholder="{{ 'chatbot_message_placeholder' | translate }}"
        (keydown.enter)="$event.stopPropagation(); sendMessage()"
      />
      <button
        class="send"
        mat-icon-button
        (click)="$event.stopPropagation(); sendMessage()"
        matSuffix
        [disabled]="inputMessage == null || inputMessage == ''"
      >
        <mat-icon fontSet="far" fontIcon="fa-paper-plane"></mat-icon>
      </button>
    </div>
  </div>
</div>
