import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AllocationWizardsStoreModule } from './allocation-wizard-store';
import { AuthStoreModule } from './auth-store/auth-store.module';
import { ConfigurationSearchStoreModule } from './configuration-tiles-search-store';
import { CoreUiInterfaceStoreModule } from './core-ui-interface.store';
import { ErrorHandlingStoreModule } from './error-handling-store';
import { FinesseChatbotInterfaceStoreModule } from './finesse-chatbot-interface.store';
import { GlobalDateFilterStoreModule } from './global-date-filter-store';
import { GlobalSearchStoreModule } from './global-search-store/global-search-store.module';
import { ImageFullscreenViewerStoreModule } from './image-fullscreen-viewer-store';
import { ModuleItemStoreModule } from './module-item-store/module-item-store.module';
import { NotificationsStoreModule } from './notifications-store';
import { PreferencesStoreModule } from './preferences-store/preferences-store.module';
import { RequestsStoreModule } from './requests-store/requests-store.module';
import { RestrictedAuthStoreModule } from './restricted-auth-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthStoreModule,
    RestrictedAuthStoreModule,
    ModuleItemStoreModule,
    RequestsStoreModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    AllocationWizardsStoreModule,
    PreferencesStoreModule,
    GlobalSearchStoreModule,
    GlobalDateFilterStoreModule,
    ErrorHandlingStoreModule,
    NotificationsStoreModule,
    ConfigurationSearchStoreModule,
    ImageFullscreenViewerStoreModule,
    CoreUiInterfaceStoreModule,
    FinesseChatbotInterfaceStoreModule,
  ],
})
export class RootStoreModule {}
