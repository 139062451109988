<!-- <div class="file-tab-content-container">
  <div class="left-panel">
    <app-file-tree [tabType]="tabType" (nodeClick)="treeNodeClick($event)"></app-file-tree>
  </div>
  <mat-divider [vertical]="true"></mat-divider>
  <div class="right-panel">
    <app-file-table
      [tabType]="tabType"
      [treeEvent]="eventsSubject.asObservable()"
      (displayFileDetails)="displayFileDetails.emit($event)"
    ></app-file-table>
  </div>
</div> -->
<div class="file-tab-content-container">
  <as-split direction="horizontal">
    <as-split-area size="40" minSize="20">
      <app-file-tree
        [tabType]="tabType"
        (nodeClick)="treeNodeClick($event)"
        [rootNodeLabel]="rootNodeLabel"
        [isModal]="isModal"
      ></app-file-tree>
    </as-split-area>
    <as-split-area size="60" minSize="40">
      <app-file-table
        [tabType]="tabType"
        [treeEvent]="eventsSubject.asObservable()"
        [isModal]="isModal"
        (fileSelected)="fileSelected.emit($event)"
      ></app-file-table>
    </as-split-area>
  </as-split>
</div>

<div
  *ngIf="!externalSidenavOpened && fileTable && !fileTable.isModal"
  #flyingButtons
  cdkDragBoundary=".main-content-container"
  (cdkDragEnded)="flyingButtonsDragEnd($event)"
  (cdkDragStarted)="flyingBtnDragging = true"
  [style.pointer-events]="flyingBtnDragging ? 'none' : null"
  class="flying-button-overlay-button"
  [ngClass]="[fileTable?.isDeleteEnabled ? 'triple-buttons' : 'double-buttons', isChatbotEnabled ? 'with-chatbot' : '']"
  cdkDrag
>
  <ng-container>
    <button
      mat-icon-button
      (click)="fileTable.addFileClick()"
      [disabled]="fileTable.isUploadDisabled()"
      matTooltip="{{ 'label_add' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="fileTable?.isDeleteEnabled">
    <button
      mat-icon-button
      (click)="fileTable.removeMultipleRecords()"
      matTooltip="{{ 'label_remove' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-trash-alt"></mat-icon>
    </button>
  </ng-container>
  <button
    mat-icon-button
    [matMenuTriggerFor]="contextViewMenu"
    matTooltip="{{ 'label_other_options' | translate }}"
    [matTooltipClass]="'fo-mat-tooltip'"
  >
    <mat-icon fontSet="fas" fontIcon="fa-ellipsis-v"></mat-icon>
  </button>
  <button
    *ngIf="isChatbotEnabled"
    class="chatbot-button"
    mat-icon-button
    (click)="openChatbot()"
    matTooltip="{{ 'label_finesse_chatbot' | translate }}"
    [matTooltipClass]="'fo-mat-tooltip'"
  >
    <mat-icon fontSet="fas" fontIcon="fa-comments"></mat-icon>
  </button>
</div>
<mat-menu class="flying-button-context-menu" #contextViewMenu="matMenu" xPosition="before" yPosition="above">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="fileTable.addLink()" [disabled]="fileTable.isUploadDisabled()">
      <mat-icon fontSet="fas" fontIcon="fa-link"></mat-icon>
      <span> {{ 'files_add_link' | translate }}</span>
    </button>
    <ng-container *ngIf="fileTree && fileTree.showDirectoryButtons()">
      <button mat-menu-item (click)="fileTree.addFileFolderClick()" [disabled]="!fileTree.selectedTreeNode">
        <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
        <span> {{ 'files_upload_directory_button' | translate }}</span>
      </button>
      <button mat-menu-item (click)="fileTree.createDirectory()" [disabled]="!fileTree.selectedTreeNode">
        <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
        <span> {{ 'files_add_directory_button' | translate }}</span>
      </button>
      <button
        mat-menu-item
        (click)="fileTree.modifyDirectory()"
        [disabled]="!fileTree.selectedTreeNode || fileTree.selectedTreeNode.entityId === -1"
      >
        <mat-icon fontSet="fas" fontIcon="fa-edit"></mat-icon>
        <span> {{ 'files_edit_directory_button' | translate }}</span>
      </button>
      <button
        mat-menu-item
        (click)="fileTree.treeView.moveDirectory()"
        [disabled]="!fileTree.selectedTreeNode || fileTree.selectedTreeNode.entityId === -1"
      >
        <mat-icon fontSet="fas" fontIcon="fa-arrows-alt"></mat-icon>
        <span> {{ 'files_move_directory_button' | translate }}</span>
      </button>
      <button *ngIf="fileTable?.selection.selected.length > 0" mat-menu-item (click)="fileTable.moveFilesIntoDirectory()">
        <mat-icon fontSet="fas" fontIcon="fa-arrows-alt"></mat-icon>
        <span> {{ 'files_move_files_button' | translate }}</span>
      </button>
      <button
        mat-menu-item
        (click)="fileTree.deleteDirectory()"
        [disabled]="!fileTree.selectedTreeNode || fileTree.selectedTreeNode.entityId === -1"
      >
        <mat-icon fontSet="fas" fontIcon="fa-times-circle"></mat-icon>
        <span> {{ 'files_cancel_directory_button' | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
