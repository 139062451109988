<div class="generic-entity-form-container">
  <mat-expansion-panel
    class="detail-expansion-panel"
    (opened)="entityDetailsIsOpen = true"
    (closed)="entityDetailsIsOpen = false"
    [hideToggle]="true"
    [expanded]="entityDetailsIsOpen || isEditing"
    [disabled]="!showPanelTitleToggle || isEditing"
  >
    <mat-expansion-panel-header *ngIf="showPanelTitleToggle">
      <mat-panel-title (click)="showPanelTitleToggle ? expandPanel($event) : null; $event.stopPropagation()">
        <span *ngIf="showPanelTitleToggle && titleKey">{{ titleKey | translate }}</span>
      </mat-panel-title>
      <mat-panel-description (click)="showPanelTitleToggle ? expandPanel($event) : null; $event.stopPropagation()">
        <button (click)="showPanelTitleToggle ? expandPanel($event) : null" mat-icon-button [disabled]="isEditing">
          <mat-icon *ngIf="!entityDetailsIsOpen">expand_more</mat-icon>
          <mat-icon *ngIf="entityDetailsIsOpen">expand_less</mat-icon>
        </button>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="detail-expansion-panel-body">
      <ng-container *ngIf="!isLoading && genericEntityFormFields">
        <app-generic-form
          [entityId]="selectedEntity.entityId"
          [entityKind]="selectedEntity.entityKind"
          [saveSubj]="saveSubj.asObservable()"
          [mailpreviewSubj]="mailpreviewSubj.asObservable()"
          [formFields]="genericEntityFormFields"
          [fixedContainerSize]="false"
          [isViewMode]="!isEditing"
          [isDirtyEnabled]="true"
          [showForeignDetailsButton]="showForeignDetailsButton"
          [foreignFilterEntityKind]="foreignFilterEntityKind"
          [foreignFilterEntityId]="foreignFilterEntityId"
          (changeValueEvEm)="formValueChanged()"
          (originalValueEvEm)="setOriginalValue()"
          (autoUpdateEvEm)="onAutoUpdateEvent($event)"
        ></app-generic-form>
      </ng-container>
      <div *ngIf="isLoading" class="spinner-container">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </div>
  </mat-expansion-panel>
</div>
<div
  *ngIf="leftButtonIsEnabled()"
  id="generic-entity-form-overlay-button"
  #flyingButtons
  [cdkDragBoundary]="isFormDialog ? '.flying-button-custom-container' : '.main-content-container'"
  (cdkDragEnded)="flyingButtonsDragEnd($event)"
  (cdkDragStarted)="flyingBtnDragging = true"
  [style.pointer-events]="flyingBtnDragging ? 'none' : null"
  class="flying-button-overlay-button"
  cdkDrag
  [ngClass]="[getFlyingButtonsClass(), isChatbotEnabled ? 'with-chatbot' : '']"
>
  <ng-container *ngIf="!isEditing">
    <ng-container *ngIf="mailTemplatePreviewEnabled">
      <button
        mat-icon-button
        (click)="mailpreviewSubj.next()"
        matTooltip="{{ 'label_mail_preview' | translate }}"
        [matTooltipClass]="'fo-mat-tooltip'"
      >
        <mat-icon fontSet="fas" fontIcon="fa-binoculars"></mat-icon>
      </button>
    </ng-container>
    <button
      mat-icon-button
      (click)="isLeftButtonCustom ? leftButtonCustomButtonEvent.emit() : goEditMode()"
      matTooltip="{{ leftButtonKey | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" [fontIcon]="leftButtonCustomIcon ? leftButtonCustomIcon : 'fa-edit'"></mat-icon>
    </button>
    <mat-divider [vertical]="true" *ngIf="rightButtonIsEnabled() && isRightButtonString"></mat-divider>
    <button *ngIf="rightButtonIsEnabled() && isRightButtonString" mat-button (click)="rightButtonCustomButtonEvent.emit()">
      {{ rightButtonKey | translate }}
    </button>
    <button
      *ngIf="rightButtonIsEnabled() && !isRightButtonString"
      mat-icon-button
      (click)="rightButtonCustomButtonEvent.emit()"
      matTooltip="{{ rightButtonKey | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" [fontIcon]="rightButtonCustomIcon"></mat-icon>
    </button>
    <button
      *ngIf="customButtonIsEnabled() || secondCustomButtonIsEnabled()"
      mat-icon-button
      [matMenuTriggerFor]="contextViewMenu"
      matTooltip="{{ 'label_other_options' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-ellipsis-v"></mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="isEditing">
    <button
      mat-icon-button
      (click)="onSave($event)"
      [disabled]="saveIsDisabled"
      matTooltip="{{ 'label_save' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-save"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="onCancel($event)"
      [matMenuTriggerFor]="contextEditMenu"
      #confirmCancelMenu="matMenuTrigger"
      matTooltip="{{ 'label_cancel' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-times-circle"></mat-icon>
    </button>
  </ng-container>
  <button
    *ngIf="isChatbotEnabled"
    class="chatbot-button"
    mat-icon-button
    (click)="openChatbot()"
    matTooltip="{{ 'label_finesse_chatbot' | translate }}"
    [matTooltipClass]="'fo-mat-tooltip'"
  >
    <mat-icon fontSet="fas" fontIcon="fa-comments"></mat-icon>
  </button>
</div>
<mat-menu class="flying-button-context-menu" #contextViewMenu="matMenu" xPosition="before" yPosition="above">
  <ng-template matMenuContent let-item="item">
    <ng-container *ngIf="secondCustomButtonIsEnabled()">
      <button mat-menu-item (click)="secondCustomButtonEvent.emit()">
        <mat-icon fontSet="fas" [fontIcon]="secondCustomButtonIcon" *ngIf="secondCustomButtonIcon"></mat-icon>
        <span> {{ secondCustomButtonKey | translate }}</span>
      </button>
    </ng-container>
    <ng-container *ngIf="customButtonIsEnabled()">
      <button mat-menu-item (click)="customButtonEvent.emit()">
        <mat-icon fontSet="fas" [fontIcon]="customButtonIcon" *ngIf="customButtonIcon"></mat-icon>
        <span> {{ customButtonKey | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
<mat-menu
  class="flying-button-context-menu"
  #contextEditMenu="matMenu"
  xPosition="before"
  yPosition="above"
  (closed)="enableConfirmCancel = false"
>
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="onConfirmCancel()">
      <mat-icon fontSet="fas" fontIcon="fa-times-circle"></mat-icon>
      <span> {{ 'label_confirm_cancel' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
