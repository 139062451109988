import { KeyValue } from '@angular/common';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BannerSettings,
  FlyingButtonSettings,
  HelpSettings,
  LanguageRegion,
  NewsSettings,
  PublicPageSettings,
  QuoteSettings,
  TopNavbarSettings,
} from 'src/app/models/preference-settings';
import * as fromPreferences from './reducer';
import { State } from './state';

export const selectPreferencesState = createFeatureSelector<State>(fromPreferences.preferencesFeatureKey);

export const selectPreferencesError = createSelector(selectPreferencesState, (state: State): boolean => state.error);

export const selectPreferencesPaginatonsOptions = createSelector(
  selectPreferencesState,
  (state: State): number[] => state.paginatorOptions
);

export const selectPreferencesFilterRulesOptions = createSelector(
  selectPreferencesState,
  (state: State): KeyValue<string, string>[] => state.filterRules
);

export const selectPreferencesLanguageRegion = createSelector(
  selectPreferencesState,
  (state: State): LanguageRegion => state.languageRegion
);

export const selectPreferencesTopNavBarSettings = createSelector(
  selectPreferencesState,
  (state: State): TopNavbarSettings => state.navbarSettings
);

export const selectPreferencesBannerSettings = createSelector(
  selectPreferencesState,
  (state: State): BannerSettings => state.bannerSettings
);

export const selectPreferencesNewsSettings = createSelector(selectPreferencesState, (state: State): NewsSettings => state.newsSettings);

export const selectPreferencesPublicPageSettings = createSelector(
  selectPreferencesState,
  (state: State): PublicPageSettings => state.publicPageSettings
);

export const selectLicenseRenewal = createSelector(selectPreferencesState, (state: State): string => state.settingLicenseRenewal);
export const selectTenantPrefixBrowserTab = createSelector(
  selectPreferencesState,
  (state: State): boolean => state.settingTenantPrefixBrowserTab
);

export const selectPreferencesInitialPageLink = createSelector(selectPreferencesState, (state: State): string => state.initialPageLink);
export const selectPreferencesLoginImage = createSelector(selectPreferencesState, (state: State): string => state.loginImage);
export const selectPreferencesQuote = createSelector(selectPreferencesState, (state: State): QuoteSettings => state.quoteSettings);

export const selectPreferencesDemoHtml = createSelector(selectPreferencesState, (state: State): string => state.demoHtml);
export const selectFlyingButtonCoordinates = createSelector(
  selectPreferencesState,
  (state: State): FlyingButtonSettings => state.flyingButtonCoordinates
);
export const selectEnabledPaths = createSelector(selectPreferencesState, (state: State): string[] => state.enabledPaths);
export const selectShowEntityid = createSelector(selectPreferencesState, (state: State): boolean => state.settingShowEntityid);
export const selectChatbotEnabled = createSelector(selectPreferencesState, (state: State): boolean => state.isChatbotEnabled);
export const selectPreferencesHelp = createSelector(selectPreferencesState, (state: State): HelpSettings => state.helpSettings);
