import { Action, createReducer, on } from '@ngrx/store';
import * as FoModuleActions from './actions';
import { State, initialState } from './state';

export const finesseChatbotInterfaceFeatureKey = 'finesseChatbotInterface';

const finesseChatbotInterfaceReducer = createReducer(
  initialState,

  on(FoModuleActions.openFinesseChatbotInterface, (state) => ({
    ...state,
    openChatbot: true,
  })),
  on(FoModuleActions.closeFinesseChatbotInterface, (state) => ({
    ...state,
    openChatbot: false,
  })),
  on(FoModuleActions.changeFinesseSource, (state, { source }) => ({
    ...state,
    source,
  })),
  on(FoModuleActions.storeFinesseMessages, (state, { messages }) => ({
    ...state,
    messages,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return finesseChatbotInterfaceReducer(state, action);
}
