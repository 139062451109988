import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Entities } from 'src/app/configs/entities';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { BaseResponse } from '../models/base-response';
import { FileGrid, FileGridNode, FilePreview } from '../models/file-grid-node';
import { PaginatedRequest } from '../models/paginated-request';
import { FileTabType } from '../shared/files/file-tab/file-tab-type';
import { CreateDirectoryRequest } from '../shared/files/requests/create-directory.request';
import { ModifyDirectoryRequest } from '../shared/files/requests/modify-directory.request';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  getFiles(request: PaginatedRequest): Observable<BaseResponse<FileGrid[]>> {
    const m = this.getFilesList.name;

    const path = environment.getEndpoint(ApiPath.Files.FILES);
    LogService.info(this, m, LogService.GET + path, null);
    LogService.info(this, m, LogService.REQUEST, request);
    const params = request ? PaginatedRequest.getBaseRequestParams(request) : null;
    const retVal = this.http.get<BaseResponse<FileGrid[]>>(path, {
      params,
    });

    return retVal;
  }

  getFilesList(apiPath: string, request?: PaginatedRequest): Observable<BaseResponse<FileGridNode[]>> {
    const m = this.getFilesList.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'apiPath', apiPath);
    LogService.info(this, m, LogService.REQUEST, request);
    const params = request ? PaginatedRequest.getBaseRequestParams(request) : null;
    const retVal = this.http.get<BaseResponse<FileGridNode[]>>(path, {
      params,
    });

    return retVal;
  }

  uploadFileToServer(apiPath: string, file: File) {
    const m = this.uploadFileToServer.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, 'apiPath: ' + apiPath, null);
    LogService.info(this, m, 'file:', file);
    const formData = new FormData();
    formData.set('file', file, file.name);
    return this.http.post(path, formData, {
      observe: 'events',
      reportProgress: true,
      responseType: 'json',
    });
  }

  uploadMultipleFilesToServer(apiPath: string, files: File[], fileIds?: number[], filterKind?: string, filterIds?: number[]) {
    const m = this.uploadMultipleFilesToServer.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, 'apiPath: ' + apiPath, null);
    LogService.info(this, m, 'files:', files);
    const formData = new FormData();
    if (files) {
      files.forEach((file) => {
        const filename = file.webkitRelativePath ? file.webkitRelativePath : file.name;
        formData.append('files', file, filename);
      });
    }
    formData.append('fileIds', fileIds ? fileIds.join(',') : '');
    let params = new HttpParams();
    if (filterKind != null && filterIds != null) {
      params = params.append(filterKind, filterIds.join(','));
    }

    return this.http.post(path, formData, {
      observe: 'events',
      reportProgress: true,
      responseType: 'json',
      params,
    });
  }

  deleteFileFromServer(apiPath: string, fileId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.deleteFileFromServer.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, 'apiPath', apiPath);
    const params = new HttpParams().set('fileId', fileId);
    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path, {
      params,
    });

    return retVal;
  }

  downloadFile(fileKind: string, fileId: number): Observable<Blob> {
    const m = this.downloadFile.name;

    let path = null;
    if (fileKind == Entities.FILE) path = environment.getEndpoint(ApiPath.Files.ENTITY_FILE_DOWNLOAD(fileId));
    else if (fileKind == Entities.FILE_COMPANY)
      path = environment.getEndpoint(ApiPath.Files.FILES_DOWNLOAD(FileTabType.company.key, fileId));
    else if (fileKind == Entities.FILE_GENERIC)
      path = environment.getEndpoint(ApiPath.Files.FILES_DOWNLOAD(FileTabType.generic.key, fileId));
    else if (fileKind == Entities.FILE_USER) path = environment.getEndpoint(ApiPath.Files.FILES_DOWNLOAD(FileTabType.user.key, fileId));
    LogService.info(this, m, LogService.GET + path, null);
    return this.http.get(path, { responseType: 'blob' });
  }

  createDirectory(tabTypeKey: string, nodeId: number, request: CreateDirectoryRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.createDirectory.name;

    const path = environment.getEndpoint(ApiPath.Files.FILES_DIRECTORY(tabTypeKey, nodeId));
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  modifyDirectory(tabTypeKey: string, nodeId: number, request: ModifyDirectoryRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.modifyDirectory.name;

    const path = environment.getEndpoint(ApiPath.Files.FILES_DIRECTORY(tabTypeKey, nodeId));
    LogService.info(this, m, LogService.PUT + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  removeDirectory(tabTypeKey: string, nodeId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.modifyDirectory.name;

    const path = environment.getEndpoint(ApiPath.Files.FILES_DIRECTORY(tabTypeKey, nodeId));
    LogService.info(this, m, LogService.DELETE + path, null);

    const retVal = this.http.delete<BaseResponse<ActionResponse>>(path);

    return retVal;
  }

  moveDirectory(tabTypeKey: string, nodeId: number, destinationNodeId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.modifyDirectory.name;

    const path = environment.getEndpoint(ApiPath.Files.FILES_DIRECTORY_MOVE(tabTypeKey, nodeId));
    LogService.info(this, m, LogService.POST + path, null);

    const params = new HttpParams().set('destinationNodeId', destinationNodeId);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  moveFiles(
    tabTypeKey: string,
    nodeId: number,
    destinationNodeId: number,
    filenodeIds: number[]
  ): Observable<BaseResponse<ActionResponse>> {
    const m = this.moveFiles.name;

    const path = environment.getEndpoint(ApiPath.Files.FILES_MOVE(tabTypeKey, nodeId));
    LogService.info(this, m, LogService.POST + path, null);

    const params = new HttpParams().set('destinationNodeId', destinationNodeId).set('filenodeIds', filenodeIds.join(','));
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  addLink(apiPath: string): Observable<BaseResponse<ActionResponse>> {
    const m = this.createDirectory.name;

    const path = environment.getEndpoint(apiPath);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, 'apiPath', apiPath);
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  addFileLinkToEntity(entityKind: string, entityId: number, fileId: number): Observable<BaseResponse<ActionResponse>> {
    const m = this.createDirectory.name;

    const path = environment.getEndpoint(ApiPath.Files.ENTITY_FILES_LINK(entityKind, entityId, fileId));
    LogService.info(this, m, LogService.POST + path, null);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null);

    return retVal;
  }

  getFilePreviewImage(fileId: number): Observable<BaseResponse<FilePreview>> {
    const m = this.getFilePreviewImage.name;

    const path = environment.getEndpoint(ApiPath.Files.FILES_PREVIEW(fileId));
    LogService.info(this, m, LogService.GET + path, null);
    const retVal = this.http.get<BaseResponse<FilePreview>>(path);
    return retVal;
  }

  constructor(private http: HttpClient) {}
}
