import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { AssetSubscription } from '../models/asset-subscription';
import { BaseResponse } from '../models/base-response';
import { BillingItem } from '../models/billingitem';
import { PaginatedRequest } from '../models/paginated-request';
import { CatalogPriceIncreaseRequest } from '../models/price';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class BillingItemDataService {
  getBillingItems(
    request: PaginatedRequest,
    filterExcludeServiceIds?: number[],
    filterExcludeDeliveryitemIds?: number[]
  ): Observable<BaseResponse<BillingItem[]>> {
    const m = this.getBillingItems.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS);
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);

    if (filterExcludeServiceIds != null && filterExcludeServiceIds.length > 0) {
      params = params.append('filterExcludeServiceIds', filterExcludeServiceIds.join(','));
    }
    if (filterExcludeDeliveryitemIds != null && filterExcludeDeliveryitemIds.length > 0) {
      params = params.append('filterExcludeDeliveryitemIds', filterExcludeDeliveryitemIds.join(','));
    }

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<BillingItem[]>>(path, {
      params,
    });

    return retVal;
  }

  getBillingItemsSpreadsheet(request: PaginatedRequest) {
    const m = this.getBillingItemsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getBillingitemAssets(request: PaginatedRequest): Observable<BaseResponse<AssetSubscription[]>> {
    const m = this.getBillingItems.name;

    const path = environment.getEndpoint(ApiPath.Assets.ASSETS_SUBSCRIPTIONS);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<AssetSubscription[]>>(path, {
      params: PaginatedRequest.getBaseRequestParams(request),
    });

    return retVal;
  }

  copyPricePeriod(billingitemId: number, starts: string, ends: string, updateAll: boolean): Observable<BaseResponse<ActionResponse>> {
    const m = this.copyPricePeriod.name;

    const path = environment.getEndpoint(ApiPath.Costs.COSTS_PERIOD_COPY);
    LogService.info(this, m, LogService.POST + path, null);

    LogService.info(this, m, LogService.REQUEST, null);
    let params = new HttpParams()
      .set('billingitemId', billingitemId.toString())
      .set('starts', starts)
      .set('all', updateAll ? 'true' : 'false');
    if (ends) {
      params = params.append('ends', ends);
    }
    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, null, { params });

    return retVal;
  }

  getBillingitemAllocations(request: PaginatedRequest, billingitemId: number): Observable<BaseResponse<AssetSubscription[]>> {
    const m = this.getBillingitemAllocations.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_SUBSCRIPTIONS(billingitemId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<AssetSubscription[]>>(path, {
      params,
    });

    return retVal;
  }

  getBillingitemAllocationsSpreadsheet(request: PaginatedRequest, billingitemId: number) {
    const m = this.getBillingitemAllocationsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_SUBSCRIPTIONS_SPREADSHEET(billingitemId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: PaginatedRequest.getBaseRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  postBillingtemPriceIncrease(billingitemId: number, request: CatalogPriceIncreaseRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.postBillingtemPriceIncrease.name;

    const path = environment.getEndpoint(ApiPath.BillingItems.BILLING_ITEMS_PRICE_INCREASE(billingitemId));
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  constructor(private http: HttpClient) {}
}
