<div id="generic-history-email-table" class="entity-table">
  <div class="table-wrapper">
    <div class="table-search-paginate hidden-filters">
      <div class="table-search-paginate-left-wrapper">
        <mat-form-field appearance="outline" class="reset-outline-hint filter-field search"
          ><button *ngIf="filter" mat-icon-button matSuffix class="small-icon" (click)="clearSearchFilter(); $event.stopPropagation()">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-autocomplete
            (opened)="matAutocompletPanelOpened()"
            (closed)="matAutocompletPanelClosed()"
            #autoLastStringOption="matAutocomplete"
            (optionSelected)="applyFilterTable($event.option.value)"
          >
            <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
              {{ 'label_recent_searches' | translate }}
            </mat-option>
            <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
              {{ opt }}
            </mat-option>
          </mat-autocomplete>
          <input
            #searchInputField
            #searchInputAutocompleteTrigger="matAutocompleteTrigger"
            (input)="searchInputAutocompleteTrigger.closePanel()"
            matInput
            [(ngModel)]="filter"
            [matAutocomplete]="autoLastStringOption"
            (debounceFilterInput)="applyFilterTable($event)"
            [debounceTime]="600"
            maxlength="1500"
            placeholder="{{ 'organisation_table_filter_placeholder' | translate }}"
          /> </mat-form-field
        ><ng-container *ngIf="showFilters">
          <button mat-button color="warn" (click)="clearFilters()" *ngIf="isFiltersSetted()">
            {{ 'label_reset_filters' | translate }}
          </button>
        </ng-container>
      </div>
      <div class="table-search-paginate-right"></div>
    </div>
    <div class="table-filters hidden"></div>
    <div class="table-container">
      <table
        class="table table-stripes table-truncate-text"
        mat-table
        [dataSource]="dataSourceTable"
        matSort
        #sortTable="matSort"
        [matSortActive]="gbGetInitialOrderBy()"
        [matSortDirection]="gbGetInitialSort()"
        matSortDisableClear
      >
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="row.emailhistoryError">
              <mat-icon fontSet="fas" fontIcon="fa-exclamation-circle" class="error"></mat-icon>
            </ng-container>
            <ng-container *ngIf="!row.emailhistoryError && !row.emailhistoryRead && row.emailhistorySent">
              <mat-icon fontSet="fas" fontIcon="fa-envelope" class="sent"></mat-icon>
            </ng-container>
            <ng-container *ngIf="!row.emailhistoryError && row.emailhistoryRead && row.emailhistorySent">
              <mat-icon fontSet="fas" fontIcon="fa-envelope-open" class="read"></mat-icon>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="emailhistorySent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'emailhistory_sent_read_error' | translate }}</th>
          <td mat-cell *matCellDef="let row" [matTooltip]="getTooltip(row)" showIfTruncated>
            <ng-container *ngIf="row.emailhistoryError">
              {{ row.emailhistoryError }}
            </ng-container>
            <ng-container *ngIf="!row.emailhistoryError && !row.emailhistoryRead && row.emailhistorySent">
              {{ getDatetime(row.emailhistorySent) }}
            </ng-container>
            <ng-container *ngIf="!row.emailhistoryError && row.emailhistoryRead && row.emailhistorySent">
              {{ getDatetime(row.emailhistorySent) }} / {{ getDatetime(row.emailhistoryRead) }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="emailhistorySubject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'emailhistory_subject' | translate }}</th>
          <td mat-cell *matCellDef="let row" [matTooltip]="row.emailhistorySubject" showIfTruncated>
            {{ row.emailhistorySubject }}
          </td>
        </ng-container>

        <ng-container matColumnDef="emailhistoryAttachments">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="icon-header">
            <mat-icon fontSet="fas" fontIcon="fa-paperclip" style="justify-content: flex-start"></mat-icon>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.emailhistoryAttachmentCount }}
          </td>
        </ng-container>

        <ng-container matColumnDef="emailhistoryTos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'emailhistory_tos' | translate }}</th>
          <td mat-cell *matCellDef="let row" [matTooltip]="row.emailhistoryTos" showIfTruncated>
            {{ row.emailhistoryTos }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actionButtons">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="icon-header"></th>
          <td mat-cell *matCellDef="let row" class="icon-cell">
            <mat-icon
              (click)="viewEmail(row)"
              fontSet="fas"
              fontIcon="fa-file-alt"
              matTooltip="{{ 'label_view_email' | translate }}"
              [matTooltipClass]="'fo-mat-tooltip'"
            ></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
      </table>
      <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
        <mat-toolbar-row *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="50"></mat-spinner>
        </mat-toolbar-row>
        <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
          <span>{{ 'email_history_table_error_nodata' | translate }}</span>
        </mat-toolbar-row>
      </mat-toolbar>
      <mat-toolbar class="table-footer-toolbar">
        <mat-toolbar-row>
          <mat-paginator
            [disabled]="isLoading"
            #paginatorTable
            [pageSizeOptions]="pageSizeList"
            [length]="pageTotalElements"
            class="reset-paginator"
          ></mat-paginator>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </div>
</div>
<div
  id="generic-audit-log-overlay-button"
  #flyingButtons
  cdkDragBoundary=".main-content-container"
  (cdkDragEnded)="flyingButtonsDragEnd($event)"
  (cdkDragStarted)="flyingBtnDragging = true"
  [style.pointer-events]="flyingBtnDragging ? 'none' : null"
  class="flying-button-overlay-button"
  cdkDrag
  [ngClass]="[isChatbotEnabled ? 'with-chatbot' : '']"
>
  <button
    mat-icon-button
    (click)="loadSpreadsheetFile()"
    matTooltip="{{ 'label_spreadsheet' | translate }}"
    [matTooltipClass]="'fo-mat-tooltip'"
  >
    <mat-icon fontSet="fas" fontIcon="fa-file-excel"></mat-icon>
  </button>
  <button
    *ngIf="isChatbotEnabled"
    class="chatbot-button"
    mat-icon-button
    (click)="openChatbot()"
    matTooltip="{{ 'label_finesse_chatbot' | translate }}"
    [matTooltipClass]="'fo-mat-tooltip'"
  >
    <mat-icon fontSet="fas" fontIcon="fa-comments"></mat-icon>
  </button>
</div>
