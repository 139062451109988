import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ApiPath } from 'src/app/configs/api-paths';
import {
  FilesUploadProgressDialogComponent,
  FilesUploadProgressDialogData,
} from 'src/app/core/modal/files-upload-progress-dialog/files-upload-progress-dialog.component';
import { BaseResponse } from 'src/app/models/base-response';
import { ExtendedFileModel } from 'src/app/models/core/extended-file';
import { Entity } from 'src/app/models/entity';
import { InterfaceWizardResponse } from 'src/app/models/wizard-import-response';
import { FilesService } from 'src/app/services/files.data.services';
import { LogService } from 'src/app/services/log-service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { BaseComponent } from 'src/app/shared/base-components/base-component';
import { MessageBox, MessageBoxButton, MessageBoxStyle, MessageBoxType } from 'src/app/shared/message-box';
import { FileTabType } from '../file-tab/file-tab-type';
import { CreateDirectoryRequest } from '../requests/create-directory.request';
import { ModifyDirectoryRequest } from '../requests/modify-directory.request';
import { FileTreeViewComponent } from './file-tree-view/file-tree-view.component';

export interface TreeEntity {
  entityId: number;
  entityName: string;
  entityKind: string;
}

export interface Folder {
  folderName: string;
  folderId;
}

@Component({
  selector: 'app-file-tree',
  templateUrl: './file-tree.component.html',
  styleUrls: ['./file-tree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileTreeComponent extends BaseComponent implements OnInit {
  @ViewChild('filesTreeView') treeView: FileTreeViewComponent;
  @ViewChild('fileFolderInput') fileFolderInput: ElementRef;
  @Input() tabType: FileTabType;
  @Input() isModal = false;
  @Input() rootNodeLabel = 'label_root';
  @Output() nodeClick = new EventEmitter<TreeEntity>();

  selectedTreeNode: TreeEntity;

  rootNode: boolean;
  autoselectEmptyNode: boolean;
  // Upload
  isUploading = false;
  progressPercentage = 0;
  private fileUploadSubscription: any;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private messageNotifierService: MessageNotifierService,
    private filesService: FilesService
  ) {
    super();
  }

  ngOnInit() {
    if (this.tabType === null || this.tabType === undefined) {
      throw new TypeError('The input ‘tabType’ is required');
    }
    this.rootNode = this.tabType.rootNode;
    this.autoselectEmptyNode = this.tabType.autoselectEmptyNode;
  }

  addFileFolderClick() {
    this.fileFolderInput.nativeElement.click();
  }

  onNodeClicked({ entityId, entityName, entityKind }) {
    const m = this.onNodeClicked.name;
    LogService.info(this, m, 'On Node Clicked', { entityId, entityName, entityKind });
    this.selectedTreeNode = { entityId, entityName, entityKind };
    this.nodeClick.emit({ entityId, entityName, entityKind });
  }

  showDirectoryButtons(): boolean {
    return !this.isModal && this.tabType !== FileTabType.generic;
  }

  createDirectory() {
    MessageBox.show(
      this.dialog,
      this.translate.instant('label_directory'),
      this.translate.instant('label_add_directory'),
      '',
      MessageBoxType.Input,
      MessageBoxButton.OkCancel,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      console.log(result);
      if (result.result === 'ok') {
        this.createDirRequest(result.inputText);
      }
    });
  }

  modifyDirectory() {
    MessageBox.show(
      this.dialog,
      this.translate.instant('label_directory'),
      this.translate.instant('label_modify_directory'),
      this.selectedTreeNode.entityName,
      MessageBoxType.Input,
      MessageBoxButton.OkCancel,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'ok') {
        this.modifyDirRequest(result.inputText);
      }
    });
  }

  deleteDirectory() {
    MessageBox.show(
      this.dialog,
      this.translate.instant('label_directory') + ': ' + this.selectedTreeNode.entityName,
      this.translate.instant('label_confirm'),
      this.translate.instant('label_delete_directory'),
      MessageBoxType.Comfirm,
      MessageBoxButton.YesNo,
      false,
      MessageBoxStyle.Full,
      '600px'
    ).subscribe((result) => {
      if (result.result === 'yes') {
        this.removeDirRequest();
      }
    });
  }

  getUploadApiEntitiesPath(): string {
    switch (this.tabType) {
      case FileTabType.generic:
        return ApiPath.Files.ENTITY_FILES_GRID(this.selectedTreeNode.entityKind, this.selectedTreeNode.entityId);
      case FileTabType.company:
      case FileTabType.user:
        return ApiPath.Files.FILES_UPLOAD(this.tabType.key, this.selectedTreeNode.entityId);
    }
  }

  async uploadFilesAndFolders(fileList: FileList) {
    console.log(fileList);
    if (this.selectedTreeNode) {
      this.subscribe(
        this.dialog
          .open<FilesUploadProgressDialogComponent, FilesUploadProgressDialogData, ExtendedFileModel[]>(
            FilesUploadProgressDialogComponent,
            {
              autoFocus: false,
              width: '80vw',
              height: '60vh',
              panelClass: 'files-upload-progress-dialog',
              hasBackdrop: true,
              disableClose: true,
              data: {
                fileList: fileList,
                showFolder: true,
                apipath: this.getUploadApiEntitiesPath(),
              } as FilesUploadProgressDialogData,
            }
          )
          .afterClosed(),
        (uploadedFiles) => {
          if (uploadedFiles.length > 0) {
            const response = uploadedFiles[0].uploadStatus.uploadResponse as BaseResponse<InterfaceWizardResponse>;
            const entities = uploadedFiles.map((f) => f.uploadStatus.fileEntity);
            if (this.selectedTreeNode.entityId === -1) {
              this.treeView.fetchData(true);
            } else {
              this.treeView.addChildToSelectedNode(entities?.length > 0 ? entities[0] : null);
            }
            this.onNodeClicked({ ...new Entity(), entityKind: response.entityKind, entityId: response.data.id });
          }
        }
      );
    }
    // const createdFolders: string[] = [];
    // if (files.length > 0) {
    //   const { data } = await firstValueFrom(
    //     this.filesService.createDirectory(this.tabType.key, this.selectedTreeNode.entityId, {
    //       name: files[0].webkitRelativePath.split('/')[0],
    //     })
    //   );
    //   if (data.state) {
    //     const mainFolder = data.entities[0];
    //     for (var i = 0; i < files.length; ++i) {
    //       const folders: string[] = files[i].webkitRelativePath.split('/');
    //       folders.shift();
    //       if (folders.length > 1) {
    //         folders.pop();
    //         //create folder
    //         const f = createdFolders.find((f) => f === folders.join('/'));
    //         if (f) {
    //           console.log('upload file', files[i]);
    //         } else {
    //           console.log('create folder', folders);
    //           createdFolders.push(folders.join('/'));
    //           console.log('upload file', files[i]);
    //         }
    //       } else {
    //         //upload file
    //         console.log('upload file', files[i]);
    //       }
    //       // console.log(files[i].webkitRelativePath.split('/'));
    //     }
    //     if (this.selectedTreeNode.entityId === -1) {
    //       this.treeView.fetchData();
    //     } else {
    //       this.treeView.addChildToSelectedNode(mainFolder);
    //     }
    //   }
    // }
  }

  private createDirRequest(name: string) {
    const request: CreateDirectoryRequest = { name };
    this.subscribe(this.filesService.createDirectory(this.tabType.key, this.selectedTreeNode.entityId, request), (response) => {
      if (response.data) {
        if (response.data) {
          if (response.data.state) {
            this.messageNotifierService.showSuccessMessage(_('toastr_directory_success_created'));
            // RELOAD DATA INTO CHILD COMPONENT
            if (this.selectedTreeNode.entityId === -1) {
              this.treeView.fetchData();
            } else {
              this.treeView.addChildToSelectedNode(response.data.entities?.length > 0 ? response.data.entities[0] : null);
            }
          } else if (response.data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          } else {
            this.messageNotifierService.showWarningMessage('toastr_no_data');
          }
        }
      }
    });
  }

  private modifyDirRequest(name: string) {
    const request: ModifyDirectoryRequest = { id: this.selectedTreeNode.entityId, name };
    this.subscribe(this.filesService.modifyDirectory(this.tabType.key, this.selectedTreeNode.entityId, request), (response) => {
      if (response.data) {
        if (response.data) {
          if (response.data.state) {
            this.messageNotifierService.showSuccessMessage(_('toastr_directory_success_modified'));
            // RELOAD DATA INTO CHILD COMPONENT
            this.treeView.updateEntityNameSelectedNode(name);
          } else if (response.data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          } else {
            this.messageNotifierService.showWarningMessage('toastr_no_data');
          }
        }
      }
    });
  }

  private removeDirRequest() {
    this.subscribe(this.filesService.removeDirectory(this.tabType.key, this.selectedTreeNode.entityId), (response) => {
      if (response.data) {
        if (response.data) {
          if (response.data.state) {
            this.messageNotifierService.showSuccessMessage(_('toastr_directory_success_removed'));
            this.treeView.removeSelectedNode();
            this.treeView.removeChildToSelectedNode();
            this.treeView.onClick(this.treeView.selectedNode);
            this.selectedTreeNode = null;
          } else if (response.data.error) {
            // Fail
            this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
          } else {
            this.messageNotifierService.showWarningMessage('toastr_no_data');
          }
        }
      }
    });
  }
}
