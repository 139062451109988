import { KeyValue } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { PublicPageSettings } from 'src/app/models/preference-settings';
import { AuthStoreAction, AuthStoreSelectors, RootStoreState } from 'src/app/root-store';
import { PreferencesSelectors } from 'src/app/root-store/preferences-store';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BaseComponent } from 'src/app/shared/base-components/base-component';
import { environment } from 'src/environments/environment';
import { AuthAttempt } from '../auth-attempt';
import { AuthError } from '../auth-result';
import { ForgotPasswordAttempt } from '../forgot-pwd-attempt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent extends BaseComponent implements OnInit {
  publicPageSettings: PublicPageSettings;

  authAttempt: AuthAttempt;
  loginForm: UntypedFormGroup;
  isSubmitted = false;
  isAuthenticated = false;
  authError: AuthError = null;

  isAdminTentantCogs = false;
  isAdminTenantAuth = false;
  isMultiTenant = false;
  isTwoFactorMethod = false;
  isOneTimePinTwoFactorMethod = false;
  tenants: KeyValue<string, string>[];
  loginMultitenantForm: UntypedFormGroup;
  loginOneTimePinForm: UntypedFormGroup;
  headerImage: string;
  logintryId: number;

  isForgotForm = false;
  forgotAttempt: ForgotPasswordAttempt;
  forgotPasswordForm: UntypedFormGroup;
  isForgotSubmitted = false;
  isForgotMailSent = false;
  forgotError: AuthError = null;
  returnUrl: string;
  isLoading = false;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private store: Store<RootStoreState.State>,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.isMultiTenant = false;
    this.isTwoFactorMethod = false;
    this.isOneTimePinTwoFactorMethod = false;
    this.isForgotForm = false;
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.loginMultitenantForm = this.formBuilder.group({
      tenant: ['', Validators.required],
    });
    this.loginOneTimePinForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
    this.subscribe(
      combineLatest([
        this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticated)),
        this.store.pipe(select(AuthStoreSelectors.selectIsAuthenticatedTenantAdmin)),
      ]),
      ([isAuth, isAuthTenantAdmin]) => {
        this.isLoading = false;
        this.isAuthenticated = isAuth || isAuthTenantAdmin;
        // if (this.isAuthenticated) {
        //   setTimeout(() => {
        //     return this.dialogRef.close();
        //   }, 3000);
        // }
      }
    );
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectTenantList)), (tenants) => {
      this.isLoading = false;
      this.isMultiTenant = this.isSubmitted && tenants && tenants.length > 1;
      if (this.isMultiTenant) {
        this.tenants = tenants;
        this.loginMultitenantForm.setValue({ tenant: this.tenants[0].key });
      }
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectTwoFactorMethod)), (twoFactorMethod) => {
      this.isLoading = false;
      this.isTwoFactorMethod = twoFactorMethod != null;
      if (this.isTwoFactorMethod) {
        this.isOneTimePinTwoFactorMethod = twoFactorMethod === 'one-time-pin';
      }
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectLoginTryId)), (logintryId) => {
      this.logintryId = logintryId;
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectPwdForgotten)), (isForgotten) => {
      this.isForgotMailSent = isForgotten;
      this.isLoading = false;
      // if (this.isForgotMailSent) {
      //   setTimeout(() => {
      //     this.store.dispatch(AuthStoreAction.forgotPwdSuccessDone());
      //     return this.dialogRef.close();
      //   }, 3000);
      // }
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectAuthError)), (error) => {
      this.authError = error;
      this.isLoading = false;
    });
    this.subscribe(this.store.pipe(select(AuthStoreSelectors.selectForgotPwdError)), (error) => {
      this.forgotError = error;
      this.isLoading = false;
    });
    this.subscribe(this.authService.getAdminTentantCogsFlag(), ({ data }) => (this.isAdminTentantCogs = data));
    this.subscribe(
      this.store.pipe(select(PreferencesSelectors.selectPreferencesPublicPageSettings)),
      (publicSettings) => (this.publicPageSettings = publicSettings)
    );
    this.subscribe(
      this.store.pipe(select(PreferencesSelectors.selectPreferencesLoginImage)),
      (headerImage) => (this.headerImage = headerImage)
    );
  }

  // Call this method in the image source, it will sanitize it.
  transform(base64Image: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Image);
  }

  login(): void {
    this.isLoading = true;
    this.authError = null;
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.authAttempt = new AuthAttempt(
      this.loginForm.value.email,
      this.loginForm.value.password,
      null,
      null,
      this.logintryId,
      this.returnUrl
    );
    if (this.isAdminTenantAuth) {
      this.store.dispatch(AuthStoreAction.authTenantAdmin({ auth: this.authAttempt }));
    } else {
      this.store.dispatch(AuthStoreAction.authenticate({ auth: this.authAttempt }));
    }
  }

  selectTenant(): void {
    this.isLoading = true;
    this.authError = null;
    this.isSubmitted = true;
    if (this.loginMultitenantForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.authAttempt = new AuthAttempt(
      this.loginForm.value.email,
      this.loginForm.value.password,
      this.loginMultitenantForm.value.tenant,
      this.loginOneTimePinForm.value.otp !== '' ? this.loginOneTimePinForm.value.otp : null,
      this.logintryId,
      this.returnUrl
    );
    if (!this.isAdminTenantAuth) {
      this.store.dispatch(AuthStoreAction.authenticate({ auth: this.authAttempt }));
    }
  }

  sendOTP(): void {
    this.isLoading = true;
    this.authError = null;
    this.isSubmitted = true;
    if (this.loginOneTimePinForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.authAttempt = new AuthAttempt(
      this.loginForm.value.email,
      this.loginForm.value.password,
      this.loginMultitenantForm.value.tenant !== '' ? this.loginMultitenantForm.value.tenant : null,
      this.loginOneTimePinForm.value.otp,
      this.logintryId,
      this.returnUrl
    );
    if (!this.isAdminTenantAuth) {
      this.store.dispatch(AuthStoreAction.authenticate({ auth: this.authAttempt }));
    }
  }

  resendOTP(): void {
    this.authError = null;
    this.isSubmitted = true;
    this.authAttempt = new AuthAttempt(
      this.loginForm.value.email,
      this.loginForm.value.password,
      this.loginMultitenantForm.value.tenant !== '' ? this.loginMultitenantForm.value.tenant : null,
      null,
      this.logintryId,
      this.returnUrl
    );
    if (!this.isAdminTenantAuth) {
      this.store.dispatch(AuthStoreAction.authenticate({ auth: this.authAttempt }));
    }
  }

  forgotPassword(): void {
    this.forgotError = null;
    this.isForgotSubmitted = true;
    if (this.forgotPasswordForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.forgotAttempt = new ForgotPasswordAttempt(this.forgotPasswordForm.value.email);
    this.store.dispatch(AuthStoreAction.forgotPwd({ forgotPwd: this.forgotAttempt }));
  }

  resetLoginFormsStatus() {
    this.loginForm.reset();
    this.loginMultitenantForm.reset();
    this.loginOneTimePinForm.reset();
    this.forgotPasswordForm.reset();
    this.isForgotForm = false;
    this.store.dispatch(AuthStoreAction.resetLoginFormsStatus());
  }

  isForgotLoading() {
    return this.isForgotSubmitted && !this.isForgotMailSent && !this.forgotError;
  }

  goTenantAdmin() {
    this.loginForm.reset();
    this.isAdminTenantAuth = true;
  }

  closeTenantAdmin() {
    this.loginForm.reset();
    this.isAdminTenantAuth = false;
  }

  oauthLogin(link: string) {
    location.href = `${environment.getEndpoint(link)}`;
  }

  getOpenAuth() {
    return this.publicPageSettings && this.publicPageSettings.openAuthorizations
      ? this.publicPageSettings.openAuthorizations.filter((oa) => !oa.admin)
      : null;
  }

  getAdminOpenAuth() {
    return this.publicPageSettings && this.publicPageSettings.openAuthorizations
      ? this.publicPageSettings.openAuthorizations.filter((oa) => oa.admin)
      : null;
  }

  get formControls() {
    return this.loginForm.controls;
  }

  get forgotFormControls() {
    return this.forgotPasswordForm.controls;
  }

  get otpFormControls() {
    return this.loginOneTimePinForm.controls;
  }

  get environtment() {
    return environment;
  }
}
