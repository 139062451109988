import { createAction, props } from '@ngrx/store';
import { HelpChatbotData } from 'src/app/models/help';

export const openFinesseChatbotInterface = createAction('[FinesseChatbotInterface API] Open Finesse Chatbot Interface');

export const closeFinesseChatbotInterface = createAction('[FinesseChatbotInterface API] Close Finesse Chatbot Interface');

export const changeFinesseSource = createAction('[FinesseChatbotInterface API] Change Finesse Source', props<{ source: string }>());

export const storeFinesseMessages = createAction(
  '[FinesseChatbotInterface API] Store Finesse Messages',
  props<{ messages: HelpChatbotData[] }>()
);
