<div class="change-password">
  <mat-expansion-panel class="detail-expansion-panel change-psw-expansion-panel" [hideToggle]="true" [expanded]="true" [disabled]="true">
    <!-- <mat-expansion-panel-header>
      <mat-panel-title>
        <span>{{ 'changepwd_title' | translate }}</span>
      </mat-panel-title>
      <mat-panel-description>
        <button (click)="expandPanel($event)" mat-icon-button>
          <mat-icon *ngIf="!entityDetailsIsOpen">expand_more</mat-icon>
          <mat-icon *ngIf="entityDetailsIsOpen">expand_less</mat-icon>
        </button>
        <div *ngIf="entityDetailsIsOpen">
          <div class="buttons-container">
            <button
              mat-raised-button
              color="primary"
              type="submit"
              form="change-psw-form"
              (click)="$event.stopPropagation()"
              [disabled]="isSubmitted && isChanged"
            >
              <mat-icon>edit</mat-icon>
              <span>{{ 'changepwd_button' | translate }}</span>
            </button>
          </div>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header> -->

    <div class="detail-expansion-panel-body">
      <div class="form-wrapper">
        <div *ngIf="!(isSubmitted && isChanged); else pwdChanged">
          <form id="change-psw-form" class="change-password-form" [formGroup]="changePwdForm" (ngSubmit)="changePwd()">
            <div class="wizard-input-field">
              <div class="label-container">
                <span class="label truncate-text">{{ 'changepwd_placeholder_oldpwd' | translate }}</span>
              </div>
              <span class="value-container">
                <mat-form-field appearance="outline" class="password-input old-password-input form-field-input reset-outline-hint">
                  <input type="password" formControlName="oldPassword" matInput autocomplete="current-password" />
                </mat-form-field>
              </span>
              <mat-error *ngIf="formControls.oldPassword.errors">
                {{ getErrorMessageOldPassword() | translate }}
              </mat-error>
            </div>
            <div class="wizard-input-field">
              <div class="label-container">
                <span class="label truncate-text">{{ 'changepwd_placeholder_newpwd' | translate }}</span>
              </div>
              <span class="value-container">
                <mat-form-field appearance="outline" class="password-input form-field-input reset-outline-hint">
                  <input type="password" formControlName="newPassword" matInput class="form-control" autocomplete="new-password" />
                </mat-form-field>
              </span>
              <mat-error *ngIf="formControls.newPassword.errors">
                <div *ngFor="let errorMessage of getErrorMessageNewPassword()">
                  {{ errorMessage | translate }}
                </div>
              </mat-error>
            </div>
            <div class="wizard-input-field">
              <div class="label-container">
                <span class="label truncate-text">{{ 'changepwd_placeholder_confirmpwd' | translate }}</span>
              </div>
              <span class="value-container">
                <mat-form-field appearance="outline" class="password-input form-field-input reset-outline-hint">
                  <input type="password" formControlName="confirmNewPassword" matInput autocomplete="new-password" />
                </mat-form-field>
              </span>
              <mat-error *ngIf="formControls.confirmNewPassword.errors">
                <div *ngFor="let errorMessage of getErrorMessageConfirmNewPassword()">
                  {{ errorMessage | translate }}
                </div>
              </mat-error>
              <div style="width: 50%" *ngIf="isSubmitted && error">
                <app-alert status="error" [message]="error.errorKey | translate" [tooltip]="error.errorMessage"></app-alert>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ng-template #pwdChanged>
        <app-alert status="success" [message]="'changepwd_message_success' | translate"></app-alert>
      </ng-template>
    </div>
  </mat-expansion-panel>
</div>
<div
  #flyingButtons
  cdkDragBoundary=".main-content-container"
  (cdkDragEnded)="flyingButtonsDragEnd($event)"
  (cdkDragStarted)="flyingBtnDragging = true"
  [style.pointer-events]="flyingBtnDragging ? 'none' : null"
  class="flying-button-overlay-button"
  cdkDrag
  [ngClass]="[isChatbotEnabled ? 'with-chatbot' : '']"
>
  <button
    [disabled]="!changePwdForm?.valid || (isSubmitted && isChanged)"
    mat-icon-button
    type="submit"
    form="change-psw-form"
    (click)="$event.stopPropagation()"
  >
    <mat-icon
      fontSet="fas"
      fontIcon="fa-save"
      matTooltip="{{ 'label_change_password' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    ></mat-icon>
  </button>
  <button
    *ngIf="isChatbotEnabled"
    class="chatbot-button"
    mat-icon-button
    (click)="openChatbot()"
    matTooltip="{{ 'label_finesse_chatbot' | translate }}"
    [matTooltipClass]="'fo-mat-tooltip'"
  >
    <mat-icon fontSet="fas" fontIcon="fa-comments"></mat-icon>
  </button>
</div>
