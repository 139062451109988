import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HelpChatbotData } from 'src/app/models/help';
import * as fromFinesseChatbotInterface from './reducer';
import { State } from './state';

export const selectFinesseChatbotInterfaceState = createFeatureSelector<State>(
  fromFinesseChatbotInterface.finesseChatbotInterfaceFeatureKey
);

export const selectFinesseChatbotInterfaceOpenChatbot = createSelector(
  selectFinesseChatbotInterfaceState,
  (state: State): boolean => state.openChatbot
);

export const selectFinesseChatbotInterfaceSource = createSelector(selectFinesseChatbotInterfaceState, (state: State): string => {
  return state.source;
});

export const selectFinesseChatbotInterfaceMessages = createSelector(
  selectFinesseChatbotInterfaceState,
  (state: State): HelpChatbotData[] => state.messages
);
