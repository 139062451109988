import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import insertTextAtCursor from 'insert-text-at-cursor';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import Base64UploaderPlugin from 'src/@ckeditor/Base64Upload';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entities } from 'src/app/configs/entities';
import {
  MailPreviewModalDialogComponent,
  MailPreviewModalDialogData,
} from 'src/app/configurations-pages/mail-template/mail-preview-modal-dialog/mail-preview-modal-dialog.component';
import {
  FilesUploadProgressDialogComponent,
  FilesUploadProgressDialogData,
} from 'src/app/core/modal/files-upload-progress-dialog/files-upload-progress-dialog.component';
import { Contact } from 'src/app/models/contact';
import { ExtendedFileModel } from 'src/app/models/core/extended-file';
import { KeyValueAddon2 } from 'src/app/models/core/key-value-data';
import { Entity } from 'src/app/models/entity';
import { FileNode } from 'src/app/models/file-grid-node';
import { MailOutgoingAccount } from 'src/app/models/mail-server';
import {
  MailTemplateFrame,
  MailTemplateFrameBody,
  MailTemplateStage,
  MailTemplateStaticsOptions,
} from 'src/app/models/mailtemplate/mail-template-particular';
import { EmailAddress } from 'src/app/models/mailtemplate/mail-template-preview';
import { FilesService } from 'src/app/services/files.data.services';
import { MailTemplatesDataService } from 'src/app/services/mail-template-data.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';
import { FileTabType } from '../../files/file-tab/file-tab-type';

export class WizardCommunicationStage {
  public stageId: number;
  public isRequired: boolean;
  public type: WizardCommunicationStageType;
  constructor(stageId: number, isRequired: boolean, type: WizardCommunicationStageType) {
    this.stageId = stageId;
    this.isRequired = isRequired;
    this.type = type;
  }
}

export enum WizardCommunicationStageType {
  initial,
  reminder1,
  reminder2,
  info,
  invoice,
}

@Component({
  selector: 'app-wizard-communication-step',
  templateUrl: './wizard-communication-step.component.html',
  styleUrls: ['./wizard-communication-step.component.scss'],
})
export class WizardCommunicationStepComponent extends FormatComponent implements OnInit {
  @ViewChild('ckeditor') ckeditor: CKEditorComponent;
  @Output() mailFrameChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() set mailFrame(value: string) {
    this.stringMailFrame = value;
  }
  @Input() mailTemplateFrame: MailTemplateFrame;
  // @Input() stages: WizardCommunicationStage[];
  @Input() stepDisabled = false;
  @Input() entityKind: string;
  @Input() isEditMode: boolean;
  @Input() isTosEnabled: boolean;
  @Input() isCcBccsEnabled = true;
  @Input() isCopyToEnabled: boolean;
  @Input() isDirtyEnabled: boolean;
  @Input() isCreateWizard: boolean;
  @Input() saveSubj: Observable<void> = new Observable<void>();
  @Input() mailpreviewSubj: Observable<void> = new Observable<void>();

  isSavePressed = false;
  public Editor = ClassicEditor;
  public config;
  //Mail template
  stringMailFrame: string;
  mailtemplateId: number;
  staticOptions: MailTemplateStaticsOptions;
  selectedStage: MailTemplateStage;
  selectedLanguage: Entity;
  selectedTemplate: Entity;
  selectedOutgoingAccount: MailOutgoingAccount;
  selectedFontfamily: Entity;
  originalMailTemplateFrame: MailTemplateFrame;
  selectedMailTemplateFrameBody: MailTemplateFrameBody;
  originalSelectedMailTemplateFrameBody: MailTemplateFrameBody;
  DIRTY_CLASSNAME = 'fo-dirty-behaviour';
  isStringInputMenuOpen = false;

  requiredBodyTags: KeyValueAddon2<string, string, string>[];
  requiredSubjectTags: KeyValueAddon2<string, string, string>[];

  isUploading: boolean;
  progressPercentage: number;

  constructor(
    protected dialog: MatDialog,
    private mailTemplateService: MailTemplatesDataService,
    private cdRef: ChangeDetectorRef,
    private filesService: FilesService
  ) {
    super();

    this.config = {
      heading: {
        options: [
          { model: 'paragraph', title: this.translate.instant(_('ckeditor_paragraph')), class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: this.translate.instant(_('ckeditor_heading1')), class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: this.translate.instant(_('ckeditor_heading2')), class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: this.translate.instant(_('ckeditor_heading3')), class: 'ck-heading_heading3' },
        ],
      },
      language: this.translate.currentLang,
      extraPlugins: [Base64UploaderPlugin],
      link: { addTargetToExternalLinks: true },
    };
  }

  async ngOnInit(): Promise<void> {
    await this.loadMailTemplateOptions();
    if (this.stringMailFrame) {
      this.mailTemplateFrame = JSON.parse(this.stringMailFrame);
    }
    if (this.mailTemplateFrame.templateId != null) {
      this.selectedTemplate =
        this.staticOptions.templates && this.staticOptions.templates.length > 0
          ? this.staticOptions.templates.find((t) => t.entityId === this.mailTemplateFrame.templateId)
          : null;
      this.selectedFontfamily =
        this.staticOptions.fontfamilies && this.staticOptions.fontfamilies.length > 0
          ? this.staticOptions.fontfamilies.find((t) => t.entityId === this.mailTemplateFrame.fontfamilyId)
          : null;
      this.selectedOutgoingAccount =
        this.staticOptions.mailoutgoingaccounts && this.staticOptions.mailoutgoingaccounts.length > 0
          ? this.staticOptions.mailoutgoingaccounts.find((t) => t.entityId === this.mailTemplateFrame.mailoutgoingaccountId)
          : null;
    } else {
      this.selectedTemplate =
        this.staticOptions.templates && this.staticOptions.templates.length > 0 ? this.staticOptions.templates[0] : null;
      this.selectedFontfamily =
        this.staticOptions.fontfamilies && this.staticOptions.fontfamilies.length > 0 ? this.staticOptions.fontfamilies[0] : null;
      this.selectedOutgoingAccount =
        this.staticOptions.mailoutgoingaccounts && this.staticOptions.mailoutgoingaccounts.length > 0
          ? this.staticOptions.mailoutgoingaccounts[0]
          : null;
      this.mailTemplateFrame.templateId = this.selectedTemplate ? this.selectedTemplate.entityId : null;
      this.mailTemplateFrame.fontfamilyId = this.selectedFontfamily ? this.selectedFontfamily.entityId : null;
      this.mailTemplateFrame.mailoutgoingaccountId = this.selectedOutgoingAccount ? this.selectedOutgoingAccount.entityId : null;
      this.stringMailFrame = JSON.stringify(this.mailTemplateFrame);
      this.mailFrameChange.emit(this.stringMailFrame);
    }
    this.selectedMailTemplateFrameBody = this.mailTemplateFrame.bodies.find(
      (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === this.selectedStage.entityId
    );
    if (this.isDirtyEnabled) {
      this.originalMailTemplateFrame = JSON.parse(JSON.stringify(this.mailTemplateFrame));
      this.originalSelectedMailTemplateFrameBody = this.originalMailTemplateFrame.bodies.find(
        (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === this.selectedStage.entityId
      );
    }
    if (this.saveSubj) {
      this.subscribe(this.saveSubj, () => (this.isSavePressed = true));
    }
    if (this.mailpreviewSubj) {
      this.subscribe(this.mailpreviewSubj, () => this.showPreview());
    }
  }

  get filteredStages() {
    return this.staticOptions && this.staticOptions.stages
      ? this.staticOptions.stages.filter((stage) => stage.mailtemplatestageRequired)
      : [];
  }

  // MAIL TEMPLATE
  async loadMailTemplateOptions() {
    this.staticOptions = (await firstValueFrom(this.mailTemplateService.getMailTemplateStaticsOptions(this.entityKind))).data;
    this.selectedStage = this.staticOptions.stages && this.staticOptions.stages.length > 0 ? this.staticOptions.stages[0] : null;
    this.selectedLanguage =
      this.staticOptions.languages && this.staticOptions.languages.length > 0 ? this.staticOptions.languages[0] : null;
    this.requiredSubjectTags = this.staticOptions.subjectTags.filter((t) => t.addon2 === 't');
    this.requiredBodyTags = this.staticOptions.bodyTags.filter((t) => t.addon2 === 't');
  }

  isValid(): boolean {
    if (this.mailTemplateFrame == null) {
      return false;
    }
    const filledBodies = this.mailTemplateFrame.bodies.filter((b) => this.isFilledBody(b));
    if (!this.filteredStages.every((fs) => filledBodies.some((fb) => fb.stageId === fs.entityId))) {
      return false;
    }
    if (
      this.isTosEnabled &&
      !this.mailTemplateFrame.bodies.every((b) => {
        if (b.prohibitadditionaltos) {
          return b.tos && b.tos.filter((t) => !t.emailaddressDeleted).length > 0;
        } else {
          return true;
        }
      })
    ) {
      return false;
    }

    return filledBodies.every((b) => {
      if (b.body == null || b.body == '' || b.subject == null || b.subject == '') {
        return false;
      } else {
        return this.isRequiredSubjectTagsValid(b) && this.isRequiredBodyTagValid(b);
      }
    });
  }

  isFilledBody(b: MailTemplateFrameBody) {
    return (b.subject != null && b.subject != '') || (b.body != null && b.body != '');
  }

  isRequiredSubjectTagsValid(b: MailTemplateFrameBody) {
    if (this.requiredSubjectTags != null && this.requiredSubjectTags.length > 0) {
      return this.requiredSubjectTags.every((t) => b.subject.includes(t.key));
    } else {
      return true;
    }
  }

  isRequiredBodyTagValid(b: MailTemplateFrameBody) {
    if (this.requiredBodyTags != null && this.requiredBodyTags.length > 0) {
      return this.requiredBodyTags.every((t) => b.body.includes(t.key));
    } else {
      return true;
    }
  }

  private xor(a: boolean, b: boolean): boolean {
    const xor = (a || b) && !(a && b);
    return xor;
  }

  isRequiredSubjectTagSetted(key: string): boolean {
    if (this.selectedMailTemplateFrameBody == null || this.selectedMailTemplateFrameBody.subject == null) {
      return false;
    }
    return this.selectedMailTemplateFrameBody.subject.includes(key);
  }

  isRequiredBodyTagSetted(key: string): boolean {
    if (this.selectedMailTemplateFrameBody == null || this.selectedMailTemplateFrameBody.body == null) {
      return false;
    }
    return this.selectedMailTemplateFrameBody.body.includes(key);
  }

  isMissingRequiredTag(): boolean {
    if (this.selectedMailTemplateFrameBody == null) {
      return false;
    }
    let isSubjectValid = true;
    let isBodyValid = true;
    if (this.requiredSubjectTags != null && this.requiredSubjectTags.length > 0) {
      isSubjectValid =
        this.selectedMailTemplateFrameBody.subject == null
          ? false
          : this.requiredSubjectTags.every((t) => this.selectedMailTemplateFrameBody.subject.includes(t.key));
    }
    if (this.requiredBodyTags != null && this.requiredBodyTags.length > 0) {
      isBodyValid =
        this.selectedMailTemplateFrameBody.body == null
          ? false
          : this.requiredBodyTags.every((t) => this.selectedMailTemplateFrameBody.body.includes(t.key));
    }
    return !(isSubjectValid && isBodyValid);
  }

  isMissingRequiredField(): boolean {
    if (this.selectedMailTemplateFrameBody == null) {
      return false;
    }
    return this.xor(
      this.selectedMailTemplateFrameBody.body == null || this.selectedMailTemplateFrameBody.body == '',
      this.selectedMailTemplateFrameBody.subject == null || this.selectedMailTemplateFrameBody.subject == ''
    );
  }

  isSelectedStageValid(): boolean {
    if (this.mailTemplateFrame == null || this.selectedMailTemplateFrameBody == null) {
      return true;
    }

    if (
      (this.selectedMailTemplateFrameBody.body == null || this.selectedMailTemplateFrameBody.body == '') &&
      (this.selectedMailTemplateFrameBody.subject == null || this.selectedMailTemplateFrameBody.subject == '')
    ) {
      return true;
    } else if (this.isMissingRequiredField()) {
      return false;
    } else {
      return !this.isMissingRequiredTag();
    }
  }

  invalidStageNames() {
    if (this.mailTemplateFrame == null) {
      return null;
    }
    const invalidStages: MailTemplateStage[] = [];
    const filledBodies = this.mailTemplateFrame.bodies.filter(
      (b) => (b.subject != null && b.subject != '') || (b.body != null && b.body != '')
    );

    this.filteredStages.forEach((stage) => {
      if (!filledBodies.some((fb) => fb.stageId === stage.entityId)) {
        invalidStages.push(stage);
      } else {
        const valid = filledBodies
          .filter((fb) => fb.stageId === stage.entityId)
          .every((b) => {
            if (b.body == null || b.body == '' || b.subject == null || b.subject == '') {
              return false;
            } else {
              let isSubjectValid = true;
              let isBodyValid = true;
              if (this.requiredSubjectTags != null && this.requiredSubjectTags.length > 0) {
                isSubjectValid = this.requiredSubjectTags.every((t) => b.subject.includes(t.key));
              }
              if (this.requiredBodyTags != null && this.requiredBodyTags.length > 0) {
                isBodyValid = this.requiredBodyTags.every((t) => b.body.includes(t.key));
              }
              return isSubjectValid && isBodyValid;
            }
          });
        if (!valid) {
          invalidStages.push(stage);
        }
      }
    });
    return invalidStages.map((s) => s.mailtemplatestageName).join(', ');
  }

  showPreview() {
    this.dialog.open(MailPreviewModalDialogComponent, {
      autoFocus: false,
      width: '90vw',
      maxWidth: '90vw',
      height: '90vh',
      panelClass: 'mail-preview-modal-dialog',
      disableClose: true,
      data: {
        mailframedefaultId: this.mailTemplateFrame.defaultId,
        mailframeId: this.mailTemplateFrame.id,
        mailtemplateId: this.mailTemplateFrame.templateId,
        stageId: this.selectedStage.entityId,
        languageId: this.selectedLanguage.entityId,
        fontfamilyId: this.selectedFontfamily.entityId,
        subject: this.selectedMailTemplateFrameBody.subject,
        body: this.selectedMailTemplateFrameBody.body,
      } as MailPreviewModalDialogData,
    });
  }

  selectedLanguageOptionChanged() {
    this.selectedMailTemplateFrameBody = this.mailTemplateFrame.bodies.find(
      (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === this.selectedStage.entityId
    );
    this.originalSelectedMailTemplateFrameBody = this.originalMailTemplateFrame.bodies.find(
      (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === this.selectedStage.entityId
    );
  }

  subjectBodyChanged() {
    this.stringMailFrame = JSON.stringify(this.mailTemplateFrame);
    this.mailFrameChange.emit(this.stringMailFrame);
  }

  selectedTemplateOptionChanged() {
    this.mailTemplateFrame.templateId = this.selectedTemplate.entityId;
    this.stringMailFrame = JSON.stringify(this.mailTemplateFrame);
    this.mailFrameChange.emit(this.stringMailFrame);
  }

  selectedFontfamilyOptionChanged() {
    this.mailTemplateFrame.fontfamilyId = this.selectedFontfamily.entityId;
    this.stringMailFrame = JSON.stringify(this.mailTemplateFrame);
    this.mailFrameChange.emit(this.stringMailFrame);
  }

  selectedOutgoingAccountOptionChanged() {
    this.mailTemplateFrame.mailoutgoingaccountId = this.selectedOutgoingAccount.entityId;
    this.stringMailFrame = JSON.stringify(this.mailTemplateFrame);
    this.mailFrameChange.emit(this.stringMailFrame);
  }

  selectedStageOptionChanged() {
    this.selectedMailTemplateFrameBody = this.mailTemplateFrame.bodies.find(
      (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === this.selectedStage.entityId
    );
    this.originalSelectedMailTemplateFrameBody = this.originalMailTemplateFrame.bodies.find(
      (b) => b.languageId === this.selectedLanguage.entityId && b.stageId === this.selectedStage.entityId
    );
  }

  onAddSingleTextMailPlaceholder(button: KeyValue<string, string>) {
    if (this.selectedMailTemplateFrameBody.subject == null) {
      this.selectedMailTemplateFrameBody.subject = '';
    }
    const el = document.getElementById('form-field-multiline-mail-inputarea');
    insertTextAtCursor(el, button.key);
  }

  onAddCkeditorTextMailPlaceholder(button: KeyValue<string, string>) {
    this.ckeditor.editorInstance?.model.change((writer) => {
      const insertPosition = this.ckeditor.editorInstance?.model.document.selection.getLastPosition();
      writer.insertText(button.key, null, insertPosition);
      this.ckeditor.editorInstance?.editing.view.focus();
    });
  }

  onCkeditorMailReady(): void {
    // WORKAROUND - to fix ngModel binding does not set initial value https://github.com/ckeditor/ckeditor5-angular/issues/265
    if (this.ckeditor && this.selectedMailTemplateFrameBody.body) {
      setTimeout(() => {
        // this.ckeditor.editorInstance?.setData(this.selectedMailTemplateFrameBody.body);
      }, 0);
    }
  }

  uploadFile(fileList: FileList): void {
    this.subscribe(
      this.dialog
        .open<FilesUploadProgressDialogComponent, FilesUploadProgressDialogData, ExtendedFileModel[]>(FilesUploadProgressDialogComponent, {
          autoFocus: false,
          width: '800px',
          height: '60vh',
          panelClass: 'files-upload-progress-dialog',
          hasBackdrop: true,
          disableClose: true,
          data: {
            fileList: fileList,
            apipath: ApiPath.Files.FILES_UPLOAD(FileTabType.user.key, -1),
          } as FilesUploadProgressDialogData,
        })
        .afterClosed(),
      (uploadedFiles) => {
        if (uploadedFiles.length > 0) {
          this.selectedMailTemplateFrameBody.files.push(
            ...uploadedFiles
              .map((f) => f.uploadStatus.fileEntity)
              .map((f) => {
                return { fileId: -1, fileFileId: f.entityId, fileName: f.entityName, fileDeleted: false };
              })
          );
        }
      }
    );
  }

  removeFile(file: FileNode) {
    const index = this.selectedMailTemplateFrameBody.files.findIndex((f) => f.fileFileId === file.fileFileId);
    if (index !== -1) {
      if (file.fileId !== -1) {
        file.fileDeleted = true;
      } else {
        this.selectedMailTemplateFrameBody.files.splice(index, 1);
      }
    }
  }

  get availableFiles() {
    return this.selectedMailTemplateFrameBody.files ? this.selectedMailTemplateFrameBody.files.filter((er) => !er.fileDeleted) : [];
  }

  /** TOS ADDRESSES */

  originalContractToAddresses: EmailAddress[];
  actualToAddressesSelection: Contact[];
  currentToAddressesSelectionSubj: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>(null);
  multiselectionToAddressesDialogRef: MatDialogRef<any>;

  openToAddressesDialog(ref: TemplateRef<any>) {
    this.actualToAddressesSelection =
      this.selectedMailTemplateFrameBody.tos != null
        ? this.selectedMailTemplateFrameBody.tos.map((ffer) => {
            return {
              ...new Contact(),
              entityId: ffer.entityId,
              entityName: `${ffer.emailaddressFullname} (${ffer.emailaddressEmail})`,
              // entityName: ffer.entityName,
              entityKind: ffer.entityKind,
              contactFirstname: ffer.emailaddressFirstname,
              contactLastname: ffer.emailaddressLastname,
              contactEmail: ffer.emailaddressEmail,
              deleted: ffer.emailaddressDeleted,
            };
          })
        : [];
    this.currentToAddressesSelectionSubj.next(this.actualToAddressesSelection);
    this.multiselectionToAddressesDialogRef = this.dialog.open(ref, {
      autoFocus: false,
      width: '80vw',
      height: '90vh',
      panelClass: 'chips-multiselection-dialog',
      disableClose: true,
    });
  }

  saveToAddressesSelection() {
    this.cdRef.detectChanges();
    // DO SAVE
    this.selectedMailTemplateFrameBody.tos = this.actualToAddressesSelection.map((as) => {
      return {
        ...new EmailAddress(),
        entityId: as.entityId,
        entityName: as.entityName,
        emailaddressId: as.entityId,
        emailaddressFirstname: as.contactFirstname,
        emailaddressLastname: as.contactLastname,
        emailaddressFullname: `${as.contactLastname} ${as.contactFirstname}`,
        emailaddressEmail: as.contactEmail,
        emailaddressDeleted: as.deleted,
      };
    });
    this.closeToAddressesDialog();
  }

  addToToAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualToAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        this.actualToAddressesSelection[index].deleted = false;
      } else {
        this.actualToAddressesSelection.push(row);
      }
    });
    this.currentToAddressesSelectionSubj.next(this.actualToAddressesSelection);
  }

  removeFromToAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualToAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        // Check if Item is present
        const elem =
          this.selectedMailTemplateFrameBody.tos != null
            ? this.selectedMailTemplateFrameBody.tos.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          this.actualToAddressesSelection[index].deleted = true;
        } else {
          this.actualToAddressesSelection.splice(index, 1);
        }
      }
    });
    this.currentToAddressesSelectionSubj.next(this.actualToAddressesSelection);
  }

  clearToAddressesSelection() {
    if (this.actualToAddressesSelection.length > 0) {
      this.actualToAddressesSelection.forEach((row, index) => {
        // Check if Item is present
        let elem =
          this.selectedMailTemplateFrameBody.tos != null
            ? this.selectedMailTemplateFrameBody.tos.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          row.deleted = true;
        }
      });
    }
    this.actualToAddressesSelection = this.actualToAddressesSelection.filter((row) => row.deleted);
    this.currentToAddressesSelectionSubj.next(this.actualToAddressesSelection);
  }

  closeToAddressesDialog() {
    this.multiselectionToAddressesDialogRef.close();
  }

  isToAddressesSelectionDirty() {
    if (this.selectedMailTemplateFrameBody.tos == null || this.originalSelectedMailTemplateFrameBody.tos == null) {
      return false;
    }
    return JSON.stringify(this.selectedMailTemplateFrameBody.tos) !== JSON.stringify(this.originalSelectedMailTemplateFrameBody.tos);
  }

  removeToAddressesChip(item: EmailAddress) {
    const index = this.originalSelectedMailTemplateFrameBody.tos
      ? this.originalSelectedMailTemplateFrameBody.tos.findIndex((i) => i.entityId === item.entityId)
      : -1;

    if (index < 0) {
      // Removed a chip not yet stored
      const i = this.selectedMailTemplateFrameBody.tos.findIndex((i) => i.entityId === item.entityId);
      this.selectedMailTemplateFrameBody.tos.splice(i, 1);
    } else {
      item.emailaddressDeleted = true;
    }
  }

  get availableToAddresses() {
    return this.selectedMailTemplateFrameBody.tos ? this.selectedMailTemplateFrameBody.tos.filter((er) => !er.emailaddressDeleted) : [];
  }

  /** CCS ADDRESSES */

  originalContractCcAddresses: EmailAddress[];
  actualCcAddressesSelection: Contact[];
  currentCcAddressesSelectionSubj: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>(null);
  multiselectionCcAddressesDialogRef: MatDialogRef<any>;

  openCcAddressesDialog(ref: TemplateRef<any>) {
    this.actualCcAddressesSelection =
      this.selectedMailTemplateFrameBody.ccs != null
        ? this.selectedMailTemplateFrameBody.ccs.map((ffer) => {
            return {
              ...new Contact(),
              entityId: ffer.entityId,
              entityName: `${ffer.emailaddressFullname} (${ffer.emailaddressEmail})`,
              // entityName: ffer.entityName,
              entityKind: ffer.entityKind,
              contactFirstname: ffer.emailaddressFirstname,
              contactLastname: ffer.emailaddressLastname,
              contactEmail: ffer.emailaddressEmail,
              deleted: ffer.emailaddressDeleted,
            };
          })
        : [];
    this.currentCcAddressesSelectionSubj.next(this.actualCcAddressesSelection);
    this.multiselectionCcAddressesDialogRef = this.dialog.open(ref, {
      autoFocus: false,
      width: '80vw',
      height: '90vh',
      panelClass: 'chips-multiselection-dialog',
      disableClose: true,
    });
  }

  saveCcAddressesSelection() {
    this.cdRef.detectChanges();
    // DO SAVE
    this.selectedMailTemplateFrameBody.ccs = this.actualCcAddressesSelection.map((as) => {
      return {
        ...new EmailAddress(),
        entityId: as.entityId,
        entityName: as.entityName,
        emailaddressId: as.entityId,
        emailaddressFirstname: as.contactFirstname,
        emailaddressLastname: as.contactLastname,
        emailaddressFullname: `${as.contactLastname} ${as.contactFirstname}`,
        emailaddressEmail: as.contactEmail,
        emailaddressDeleted: as.deleted,
      };
    });
    this.closeCcAddressesDialog();
  }

  addToCcAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualCcAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        this.actualCcAddressesSelection[index].deleted = false;
      } else {
        this.actualCcAddressesSelection.push(row);
      }
    });
    this.currentCcAddressesSelectionSubj.next(this.actualCcAddressesSelection);
  }

  removeFromCcAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualCcAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        // Check if Item is present
        const elem =
          this.selectedMailTemplateFrameBody.ccs != null
            ? this.selectedMailTemplateFrameBody.ccs.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          this.actualCcAddressesSelection[index].deleted = true;
        } else {
          this.actualCcAddressesSelection.splice(index, 1);
        }
      }
    });
    this.currentCcAddressesSelectionSubj.next(this.actualCcAddressesSelection);
  }

  clearCcAddressesSelection() {
    if (this.actualCcAddressesSelection.length > 0) {
      this.actualCcAddressesSelection.forEach((row, index) => {
        // Check if Item is present
        let elem =
          this.selectedMailTemplateFrameBody.ccs != null
            ? this.selectedMailTemplateFrameBody.ccs.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          row.deleted = true;
        }
      });
    }
    this.actualCcAddressesSelection = this.actualCcAddressesSelection.filter((row) => row.deleted);
    this.currentCcAddressesSelectionSubj.next(this.actualCcAddressesSelection);
  }

  closeCcAddressesDialog() {
    this.multiselectionCcAddressesDialogRef.close();
  }

  isCcAddressesSelectionDirty() {
    if (this.selectedMailTemplateFrameBody.ccs == null || this.originalSelectedMailTemplateFrameBody.ccs == null) {
      return false;
    }
    return JSON.stringify(this.selectedMailTemplateFrameBody.ccs) !== JSON.stringify(this.originalSelectedMailTemplateFrameBody.ccs);
  }

  removeCcAddressesChip(item: EmailAddress) {
    const index = this.originalSelectedMailTemplateFrameBody.ccs
      ? this.originalSelectedMailTemplateFrameBody.ccs.findIndex((i) => i.entityId === item.entityId)
      : -1;

    if (index < 0) {
      // Removed a chip not yet stored
      const i = this.selectedMailTemplateFrameBody.ccs.findIndex((i) => i.entityId === item.entityId);
      this.selectedMailTemplateFrameBody.ccs.splice(i, 1);
    } else {
      item.emailaddressDeleted = true;
    }
  }

  get availableCcAddresses() {
    return this.selectedMailTemplateFrameBody.ccs ? this.selectedMailTemplateFrameBody.ccs.filter((er) => !er.emailaddressDeleted) : [];
  }

  /** BCCS ADDRESSES */

  originalContractBccAddresses: EmailAddress[];
  actualBccAddressesSelection: Contact[];
  currentBccAddressesSelectionSubj: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>(null);
  multiselectionBccAddressesDialogRef: MatDialogRef<any>;

  openBccAddressesDialog(ref: TemplateRef<any>) {
    this.actualBccAddressesSelection =
      this.selectedMailTemplateFrameBody.bccs != null
        ? this.selectedMailTemplateFrameBody.bccs.map((ffer) => {
            return {
              ...new Contact(),
              entityId: ffer.entityId,
              entityName: `${ffer.emailaddressFullname} (${ffer.emailaddressEmail})`,
              // entityName: ffer.entityName,
              entityKind: ffer.entityKind,
              contactFirstname: ffer.emailaddressFirstname,
              contactLastname: ffer.emailaddressLastname,
              contactEmail: ffer.emailaddressEmail,
              deleted: ffer.emailaddressDeleted,
            };
          })
        : [];
    this.currentBccAddressesSelectionSubj.next(this.actualBccAddressesSelection);
    this.multiselectionBccAddressesDialogRef = this.dialog.open(ref, {
      autoFocus: false,
      width: '80vw',
      height: '90vh',
      panelClass: 'chips-multiselection-dialog',
      disableClose: true,
    });
  }

  saveBccAddressesSelection() {
    this.cdRef.detectChanges();
    // DO SAVE
    this.selectedMailTemplateFrameBody.bccs = this.actualBccAddressesSelection.map((as) => {
      return {
        ...new EmailAddress(),
        entityId: as.entityId,
        entityName: as.entityName,
        emailaddressId: as.entityId,
        emailaddressFirstname: as.contactFirstname,
        emailaddressLastname: as.contactLastname,
        emailaddressFullname: `${as.contactLastname} ${as.contactFirstname}`,
        emailaddressEmail: as.contactEmail,
        emailaddressDeleted: as.deleted,
      };
    });
    this.closeBccAddressesDialog();
  }

  addToBccAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualBccAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        this.actualBccAddressesSelection[index].deleted = false;
      } else {
        this.actualBccAddressesSelection.push(row);
      }
    });
    this.currentBccAddressesSelectionSubj.next(this.actualBccAddressesSelection);
  }

  removeFromBccAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualBccAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        // Check if Item is present
        const elem =
          this.selectedMailTemplateFrameBody.bccs != null
            ? this.selectedMailTemplateFrameBody.bccs.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          this.actualBccAddressesSelection[index].deleted = true;
        } else {
          this.actualBccAddressesSelection.splice(index, 1);
        }
      }
    });
    this.currentBccAddressesSelectionSubj.next(this.actualBccAddressesSelection);
  }

  clearBccAddressesSelection() {
    if (this.actualBccAddressesSelection.length > 0) {
      this.actualBccAddressesSelection.forEach((row, index) => {
        // Check if Item is present
        let elem =
          this.selectedMailTemplateFrameBody.bccs != null
            ? this.selectedMailTemplateFrameBody.bccs.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          row.deleted = true;
        }
      });
    }
    this.actualBccAddressesSelection = this.actualBccAddressesSelection.filter((row) => row.deleted);
    this.currentBccAddressesSelectionSubj.next(this.actualBccAddressesSelection);
  }

  closeBccAddressesDialog() {
    this.multiselectionBccAddressesDialogRef.close();
  }

  isBccAddressesSelectionDirty() {
    if (this.selectedMailTemplateFrameBody.bccs == null || this.originalSelectedMailTemplateFrameBody.bccs == null) {
      return false;
    }
    return JSON.stringify(this.selectedMailTemplateFrameBody.bccs) !== JSON.stringify(this.originalSelectedMailTemplateFrameBody.bccs);
  }

  removeBccAddressesChip(item: EmailAddress) {
    const index = this.originalSelectedMailTemplateFrameBody.bccs
      ? this.originalSelectedMailTemplateFrameBody.bccs.findIndex((i) => i.entityId === item.entityId)
      : -1;

    if (index < 0) {
      // Removed a chip not yet stored
      const i = this.selectedMailTemplateFrameBody.bccs.findIndex((i) => i.entityId === item.entityId);
      this.selectedMailTemplateFrameBody.bccs.splice(i, 1);
    } else {
      item.emailaddressDeleted = true;
    }
  }

  get availableBccAddresses() {
    return this.selectedMailTemplateFrameBody.bccs ? this.selectedMailTemplateFrameBody.bccs.filter((er) => !er.emailaddressDeleted) : [];
  }

  get Entities() {
    return Entities;
  }
}
