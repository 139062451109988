import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, forkJoin, Observable, of, tap } from 'rxjs';
import { BaseResponse } from 'src/app/models/base-response';
import { ExtendedFileModel } from 'src/app/models/core/extended-file';
import { InterfaceWizardResponse } from 'src/app/models/wizard-import-response';
import { FilesService } from 'src/app/services/files.data.services';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export interface FilesUploadProgressDialogData {
  fileList: FileList;
  files: File[];
  fileIds: number[];
  apipath: string;
  uploadSingleFile: boolean;
  showFolder: boolean;
}
@Component({
  selector: 'app-files-upload-progress-dialog',
  templateUrl: './files-upload-progress-dialog.component.html',
  styleUrls: ['./files-upload-progress-dialog.component.scss'],
})
export class FilesUploadProgressDialogComponent extends FormatComponent implements OnInit {
  isUploading = false;
  toUploadFilesList: ExtendedFileModel[] = [];
  progressPercentage = 0;
  filesToUpload: number;
  columns: string[];

  constructor(
    protected fileUploadService: FilesService,
    public dialogRef: MatDialogRef<FilesUploadProgressDialogComponent, ExtendedFileModel[]>,
    @Inject(MAT_DIALOG_DATA) public data: FilesUploadProgressDialogData
  ) {
    super();
  }

  ngOnInit(): void {
    this.columns = this.data.showFolder ? ['folder', 'filename', 'percentage', 'progress'] : ['filename', 'percentage', 'progress'];
    this.isUploading = true;
    this.constructToUploadFilesList(this.data.fileList ? Array.from(this.data.fileList) : this.data.files);
    if (this.data.uploadSingleFile) {
      this.executeSingleFileUpload();
    } else {
      const requestsList = this.constructRequestsChain();
      this.executeMultipleFileUpload(requestsList);
    }
  }

  close() {
    if (this.data.uploadSingleFile) {
      this.dialogRef.close(this.toUploadFilesList);
    } else {
      this.dialogRef.close(this.toUploadFilesList);
    }
  }

  private constructToUploadFilesList(files: File[]): void {
    files.forEach((file: File) => {
      const fullpath = file.webkitRelativePath ? file.webkitRelativePath.split('/') : [file.name];
      const filename = fullpath.length > 1 ? fullpath[fullpath.length - 1] : fullpath[0];
      const folder = fullpath.length > 1 ? fullpath.splice(0, fullpath.length - 1).join('/') : null;
      // const fullpath
      const newFile: ExtendedFileModel = {
        file,
        filename,
        folder,
        uploadStatus: {
          isSuccess: false,
          isError: false,
          errorMessage: '',
          progressCount: 0,
          fileEntity: null,
          uploadResponse: null,
        },
      };
      this.toUploadFilesList.push(newFile);
    });
    this.filesToUpload = this.toUploadFilesList.length;
  }

  private constructRequestsChain() {
    return this.toUploadFilesList.map((item, index) => {
      return this.fileUploadService.uploadMultipleFilesToServer(this.data.apipath, [item.file], this.data.fileIds).pipe(
        tap((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.toUploadFilesList[index].uploadStatus.progressCount = Math.round(100 * (event.loaded / event.total));
          }
          if (event.type === HttpEventType.Response) {
            const response = event.body as BaseResponse<InterfaceWizardResponse>;
            this.toUploadFilesList[index].uploadStatus.isSuccess = response.data.state;
            this.toUploadFilesList[index].uploadStatus.fileEntity = response.data.entities[0];
            this.toUploadFilesList[index].uploadStatus.uploadResponse = response;
            this.checkUploadStatus();
          }
        }),
        catchError((error) => {
          return of({ isError: true, index, error });
        })
      );
    });
  }

  private executeSingleFileUpload() {
    this.subscribe(
      this.fileUploadService.uploadFileToServer(this.data.apipath, this.toUploadFilesList[0].file),
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.toUploadFilesList[0].uploadStatus.progressCount = Math.round(100 * (event.loaded / event.total));
        }
        if (event.type === HttpEventType.Response) {
          const response = event.body as BaseResponse<InterfaceWizardResponse>;
          this.toUploadFilesList[0].uploadStatus.isSuccess = response.data.state;
          this.toUploadFilesList[0].uploadStatus.isError = !response.data.state;
          this.toUploadFilesList[0].uploadStatus.errorMessage = response.data.error;
          this.toUploadFilesList[0].uploadStatus.fileEntity = response.data.entities[0];
          this.toUploadFilesList[0].uploadStatus.uploadResponse = response;
          this.checkUploadStatus();
        }
      },
      (error: any) => {
        this.toUploadFilesList[0].uploadStatus.isError = true;
        this.toUploadFilesList[0].uploadStatus.errorMessage = error.error.errorMessage;
        this.toUploadFilesList[0].uploadStatus.uploadResponse = {
          ...new BaseResponse<InterfaceWizardResponse>(),
          data: null,
          error: error.error.errorMessage,
        };
        this.checkUploadStatus();
      }
    );
  }

  private executeMultipleFileUpload(
    requestsChain: Observable<
      | HttpEvent<Object>
      | {
          isError: boolean;
          index: number;
          error: HttpErrorResponse;
        }
    >[]
  ): void {
    forkJoin(requestsChain).subscribe((response) => {
      response.forEach((item: { isError: any; index: number; error: HttpErrorResponse }) => {
        if (item.isError) {
          this.toUploadFilesList[item.index].uploadStatus.isError = true;
          this.toUploadFilesList[item.index].uploadStatus.errorMessage = item.error.error.errorMessage;
          this.checkUploadStatus();
        }
      });
    });
  }

  private checkUploadStatus() {
    const finishedUpload = this.toUploadFilesList.filter((f) => f.uploadStatus.isSuccess || f.uploadStatus.isError).length;
    this.progressPercentage = Math.round(100 * (finishedUpload / this.filesToUpload));
    this.isUploading = this.progressPercentage !== 100;
  }
}
