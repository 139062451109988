<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span>{{ selectedEntity?.entityKind | translate }}: {{ selectedEntity?.entityName }}</span>
  <div class="buttons-container">
    <button mat-icon-button matDialogClose>
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content class="generic-audit-log-modal-dialog-content">
  <div class="audit-log-details-table">
    <mat-expansion-panel
      [hideToggle]="true"
      [expanded]="isPanelExpanded"
      class="detail-expansion-panel"
      (opened)="isPanelExpanded = true"
      (closed)="isPanelExpanded = false"
      [disabled]="!showPanelTitleToggle"
    >
      <mat-expansion-panel-header *ngIf="showPanelTitleToggle">
        <mat-panel-title>
          <span>{{ 'audit_log_title' | translate }}</span>
        </mat-panel-title>
        <mat-panel-description>
          <button (click)="isPanelExpanded = !isPanelExpanded; $event.stopPropagation()" mat-icon-button>
            <mat-icon *ngIf="!isPanelExpanded">expand_more</mat-icon>
            <mat-icon *ngIf="isPanelExpanded">expand_less</mat-icon>
          </button>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="detail-expansion-panel-body entity-table">
        <div class="table-wrapper">
          <div class="table-container">
            <table
              class="table table-stripes table-truncate-text"
              mat-table
              [dataSource]="dataSourceTable"
              matSort
              #sortTable="matSort"
              [matSortActive]="gbGetInitialOrderBy()"
              [matSortDirection]="gbGetInitialSort()"
              matSortDisableClear
            >
              <ng-container matColumnDef="logEntityKind">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'log_entity' | translate }}</th>
                <td mat-cell *matCellDef="let row" [matTooltip]="row.logEntityKind" showIfTruncated>
                  {{ row.logEntityKind }}
                </td>
              </ng-container>

              <ng-container matColumnDef="logField">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'log_field' | translate }}</th>
                <td mat-cell *matCellDef="let row" [matTooltip]="row.logField" showIfTruncated>
                  {{ row.logField }}
                </td>
              </ng-container>

              <ng-container matColumnDef="logKind">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'log_kind' | translate }}</th>
                <td mat-cell *matCellDef="let row" [matTooltip]="row.logKind" showIfTruncated>
                  {{ row.logKind }}
                </td>
              </ng-container>

              <ng-container matColumnDef="logFromvalue">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'log_fromvalue' | translate }}</th>
                <td mat-cell *matCellDef="let row" [matTooltip]="row.logFromvalue" showIfTruncated>
                  {{ row.logFromvalue }}
                </td>
              </ng-container>

              <ng-container matColumnDef="logTovalue">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'log_tovalue' | translate }}</th>
                <td mat-cell *matCellDef="let row" [matTooltip]="row.logTovalue" showIfTruncated>
                  {{ row.logTovalue }}
                </td>
              </ng-container>

              <ng-container matColumnDef="logModified">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'log_modified' | translate }}</th>
                <td mat-cell *matCellDef="let row" [matTooltip]="getDatetime(row.logModified)" showIfTruncated>
                  {{ getDatetime(row.logModified) }}
                </td>
              </ng-container>

              <ng-container matColumnDef="logTransaction">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'log_transaction' | translate }}</th>
                <td mat-cell *matCellDef="let row" [matTooltip]="row.logTransaction" showIfTruncated>
                  {{ row.logTransaction }}
                </td>
              </ng-container>

              <ng-container matColumnDef="logModifiedby">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'log_modifiedby' | translate }}</th>
                <td mat-cell *matCellDef="let row" [matTooltip]="row.logModifiedby" showIfTruncated>
                  {{ row.logModifiedby }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
            </table>
            <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
              <mat-toolbar-row *ngIf="isLoading" class="spinner">
                <mat-spinner diameter="50"></mat-spinner>
              </mat-toolbar-row>
              <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
                <span>{{ 'auditlog_table_error_nodata' | translate }}</span>
              </mat-toolbar-row>
            </mat-toolbar>
            <mat-toolbar class="table-footer-toolbar">
              <mat-toolbar-row>
                <mat-paginator
                  [disabled]="isLoading"
                  #paginatorTable
                  [pageSizeOptions]="pageSizeList"
                  [length]="pageTotalElements"
                  class="reset-paginator"
                ></mat-paginator>
              </mat-toolbar-row>
            </mat-toolbar>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
  <div
    id="generic-audit-log-overlay-button"
    #flyingButtons
    cdkDragBoundary=".cdk-overlay-pane"
    (cdkDragEnded)="flyingButtonsDragEnd($event)"
    (cdkDragStarted)="flyingBtnDragging = true"
    [style.pointer-events]="flyingBtnDragging ? 'none' : null"
    class="flying-button-overlay-button"
    cdkDrag
    [ngClass]="[isChatbotEnabled ? 'with-chatbot' : '']"
  >
    <button
      mat-icon-button
      (click)="loadSpreadsheetFile()"
      matTooltip="{{ 'label_spreadsheet' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-file-excel"></mat-icon>
    </button>
    <button
      *ngIf="isChatbotEnabled"
      class="chatbot-button"
      mat-icon-button
      (click)="openChatbot()"
      matTooltip="{{ 'label_finesse_chatbot' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-comments"></mat-icon>
    </button>
  </div>
</div>
