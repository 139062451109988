<div class="generic-grid-view-with-details">
  <mat-expansion-panel
    [hideToggle]="true"
    [expanded]="isPanelExpanded"
    class="detail-expansion-panel"
    (opened)="isPanelExpanded = true"
    (closed)="isPanelExpanded = false; resetSelectedRow()"
    [disabled]="!showPanelTitleToggle"
  >
    <mat-expansion-panel-header *ngIf="showPanelTitleToggle">
      <mat-panel-title>
        <span>{{ titleKey | translate }}</span>
      </mat-panel-title>
      <mat-panel-description>
        <button (click)="isPanelExpanded = !isPanelExpanded; $event.stopPropagation()" mat-icon-button [disabled]="isEditing">
          <mat-icon *ngIf="!isPanelExpanded">expand_more</mat-icon>
          <mat-icon *ngIf="isPanelExpanded">expand_less</mat-icon>
        </button>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="detail-expansion-panel-body">
      <!-- ----- generic grid view - generic grid view - generic grid view ---- -->
      <div class="generic-grid-view">
        <app-generic-grid-view
          #genericGridView
          gridNoDataMessage="{{ gridNoDataMessage }}"
          [gridTitle]="titleKey"
          [gridApiPath]="gridApiPath"
          [gridColumnsConfig]="gridColumnsConfig"
          [gridRefreshEvent]="gridRefreshEvent"
          [gridEnableSearch]="gridEnableSearch"
          [gridEnablePagination]="gridEnablePagination"
          [gridEnableHeader]="gridEnableHeader"
          [gridAllowRemove]="allowRemove"
          [gridPageSize]="gridPageSize"
          [autoSelectFirstRow]="autoSelectFirstRow"
          [selectedRowToRestore]="selectedRowToRestore"
          (rowClick)="!overrideCreateCancel ? gridRowClick($event) : null"
          (resetSelectedRowEvEm)="resetSelectedRow()"
          (createFlagEvEm)="rightButtonEnabledOverride ? (rightButtonEnabled = $event) : (rightButtonEnabled = false)"
          (isLoadingEvEm)="isLoading = $event"
          (noDataEvEm)="noEntityData = $event"
        ></app-generic-grid-view>
      </div>
      <!-- ----- generic grid view - generic grid view - generic grid view ---- -->

      <!-- --- app-entity-field-list-content - app-entity-field-list-content -- -->
      <div class="details-and-related-container" *ngIf="selectedRow" [ngClass]="isRelatedFirst ? 'reverse' : ''">
        <mat-tab-group
          class="generic-grid-view-details-tab"
          *ngIf="!atomicCommonIsLoading && atomicDetailsSteps && atomicDetailsSteps.length > 1"
        >
          <!-- BASE DETAILS -->
          <mat-tab *ngFor="let step of atomicDetailsSteps" [disabled]="isEditing">
            <ng-template mat-tab-label>
              {{ step.formstepName }}
            </ng-template>
            <ng-template matTabContent>
              <generic-entity-form
                #entityFormContent
                [entitySubj]="selectedEntitySubject.asObservable()"
                [getAPIPath]="getPutMainAPIFormFieldsPath(step.formstepId)"
                [putAPIPath]="getPutMainAPIFormFieldsPath(step.formstepId)"
                (fetchCallbackEvEm)="fetchDetailsCallbackEvEm($event)"
                (createUpdateCallbackEvEm)="createUpdateCallbackEvEm($event)"
                (eventDataChanged)="eventDataChanged.emit($event)"
                [isPanelExpanded]="true"
                [showPanelTitleToggle]="false"
                [isEditing]="isEditing"
                (isEditingChange)="onEditChange($event)"
              ></generic-entity-form>
            </ng-template> </mat-tab
        ></mat-tab-group>
        <ng-container *ngIf="!atomicCommonIsLoading && atomicDetailsSteps && atomicDetailsSteps.length === 1">
          <generic-entity-form
            #entityFormContent
            [entitySubj]="selectedEntitySubject.asObservable()"
            [getAPIPath]="getPutMainAPIFormFieldsPath(atomicDetailsSteps[0].formstepId)"
            [putAPIPath]="getPutMainAPIFormFieldsPath(atomicDetailsSteps[0].formstepId)"
            (fetchCallbackEvEm)="fetchDetailsCallbackEvEm($event)"
            (createUpdateCallbackEvEm)="createUpdateCallbackEvEm($event)"
            (eventDataChanged)="eventDataChanged.emit($event)"
            [isPanelExpanded]="true"
            [showPanelTitleToggle]="false"
            [isEditing]="isEditing"
            (isEditingChange)="onEditChange($event)"
          ></generic-entity-form>
        </ng-container>
        <!-- RELATED DETAILS -->
        <mat-tab-group
          class="generic-grid-view-details-tab"
          *ngIf="!atomicRelatedCommonIsLoading && atomicRelatedDetailsSteps && atomicRelatedDetailsSteps.length > 1"
        >
          <mat-tab *ngFor="let step of atomicRelatedDetailsSteps" [disabled]="isEditing">
            <ng-template mat-tab-label>
              {{ step.formstepName }}
            </ng-template>
            <ng-template matTabContent>
              <generic-entity-form
                #entityRelatedFormContent
                [entitySubj]="selectedRelatedEntitySubject.asObservable()"
                [getAPIPath]="getPutRelatedAPIFormFieldsPath(step.formstepId)"
                [putAPIPath]="getPutRelatedAPIFormFieldsPath(step.formstepId)"
                (fetchCallbackEvEm)="fetchDetailsCallbackEvEm($event)"
                (createUpdateCallbackEvEm)="createUpdateRelatedCallbackEvEm($event)"
                [isPanelExpanded]="true"
                [showPanelTitleToggle]="false"
                [isEditing]="isEditing"
                (isEditingChange)="onEditChange($event)"
              ></generic-entity-form>
            </ng-template> </mat-tab
        ></mat-tab-group>
        <ng-container *ngIf="!atomicRelatedCommonIsLoading && atomicRelatedDetailsSteps && atomicRelatedDetailsSteps.length === 1">
          <generic-entity-form
            #entityRelatedFormContent
            [entitySubj]="selectedRelatedEntitySubject.asObservable()"
            [getAPIPath]="getPutRelatedAPIFormFieldsPath(atomicRelatedDetailsSteps[0].formstepId)"
            [putAPIPath]="getPutRelatedAPIFormFieldsPath(atomicRelatedDetailsSteps[0].formstepId)"
            (fetchCallbackEvEm)="fetchDetailsCallbackEvEm($event)"
            (createUpdateCallbackEvEm)="createUpdateRelatedCallbackEvEm($event)"
            [isPanelExpanded]="true"
            [showPanelTitleToggle]="false"
            [isEditing]="isEditing"
            (isEditingChange)="onEditChange($event)"
          ></generic-entity-form>
        </ng-container>
      </div>
      <div class="spinner-container" *ngIf="atomicCommonIsLoading || atomicRelatedCommonIsLoading" style="margin-top: 1rem">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
      <!-- --- app-entity-field-list-content - app-entity-field-list-content -- -->
    </div>
  </mat-expansion-panel>
</div>
<mat-toolbar class="table-footer-toolbar" *ngIf="isLoading || noEntityData || detailsRowNoData">
  <mat-toolbar-row *ngIf="isLoading" class="spinner">
    <mat-spinner diameter="50"></mat-spinner>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
    <span *ngIf="gridNoDataMessage">{{ gridNoDataMessage }}</span>
    <span *ngIf="!gridNoDataMessage">{{ 'generic_grid_view_error_no_data' | translate }}</span>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="selectedRow && detailsRowNoData" class="no-data-message">
    <div>
      {{ noDetailsCustomMessage ? selectedRow.entityName : ('generic_grid_view_error_details_no_data' | translate) }}
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<!-- <div *ngIf="isLoading || noEntityData || detailsRowNoData" class="spinner-container">
  <div *ngIf="isLoading">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <div *ngIf="noEntityData" class="no-data-message">
    <span *ngIf="gridNoDataMessage">{{ gridNoDataMessage }}</span>
    <span *ngIf="!gridNoDataMessage">{{ 'generic_grid_view_error_no_data' | translate }}</span>
  </div>
  <div *ngIf="selectedRow && detailsRowNoData">
    {{ noDetailsCustomMessage ? selectedRow.entityName : ('generic_grid_view_error_details_no_data' | translate) }}
  </div>
</div> -->
<div
  id="generic-grid-details-overlay-button"
  #flyingButtons
  cdkDragBoundary=".main-content-container"
  (cdkDragEnded)="flyingButtonsDragEnd($event)"
  (cdkDragStarted)="flyingBtnDragging = true"
  [style.pointer-events]="flyingBtnDragging ? 'none' : null"
  class="flying-button-overlay-button double-buttons"
  cdkDrag
  [ngClass]="[isChatbotEnabled ? 'with-chatbot' : '']"
>
  <ng-container *ngIf="!isEditing">
    <button
      mat-icon-button
      (click)="leftButtonClick($event)"
      [disabled]="!leftButtonEnabled"
      matTooltip="{{ leftButtonKey | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <!--
        *ngIf="selectedRow && !detailsRowNoData && !(grid && grid.isDeleteEnabled)" -->
      <mat-icon fontSet="fas" fontIcon="fa-edit"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="rightButtonClick($event)"
      [disabled]="!rightButtonEnabled"
      *ngIf="!(grid && grid.isDeleteEnabled)"
      matTooltip="{{ rightButtonKey | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="grid.removeMultipleRecords()"
      *ngIf="grid && grid.isDeleteEnabled"
      matTooltip="{{ 'label_remove' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-trash-alt"></mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="isEditing">
    <button
      mat-icon-button
      (click)="onSave($event)"
      [disabled]="saveIsDisabled"
      matTooltip="{{ 'label_save' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-save"></mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="onCancel($event)"
      [matMenuTriggerFor]="contextEditMenu"
      #confirmCancelMenu="matMenuTrigger"
      matTooltip="{{ 'label_cancel' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-times-circle"></mat-icon>
    </button>
  </ng-container>
  <button
    *ngIf="isChatbotEnabled"
    class="chatbot-button"
    mat-icon-button
    (click)="openChatbot()"
    matTooltip="{{ 'label_finesse_chatbot' | translate }}"
    [matTooltipClass]="'fo-mat-tooltip'"
  >
    <mat-icon fontSet="fas" fontIcon="fa-comments"></mat-icon>
  </button>
</div>
<!--
<mat-menu class="flying-button-context-menu" #contextViewMenu="matMenu" xPosition="before" yPosition="above">
  <ng-template matMenuContent let-item="item">
    <button [disabled]="!rightButtonEnabled" mat-menu-item (click)="rightButtonClick($event)">
      <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
      <span> {{ rightButtonKey | translate }}</span>
    </button>

    <button [disabled]="!(grid && grid.isDeleteEnabled)" mat-menu-item (click)="grid.removeMultipleRecords()">
      <mat-icon fontSet="fas" fontIcon="fa-trash-alt"></mat-icon>
      <span> {{ 'label_remove' | translate }}</span>
    </button>
  </ng-template>
</mat-menu> -->

<mat-menu
  class="flying-button-context-menu"
  #contextEditMenu="matMenu"
  xPosition="before"
  yPosition="above"
  (closed)="enableConfirmCancel = false"
>
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="onConfirmCancel()">
      <mat-icon fontSet="fas" fontIcon="fa-times-circle"></mat-icon>
      <span> {{ 'label_confirm_cancel' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
