<div class="generic-notes-container">
  <div class="search-filter-container">
    <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
      <button *ngIf="filter" mat-icon-button matSuffix class="small-icon" (click)="clearSearchFilter(); $event.stopPropagation()">
        <mat-icon>clear</mat-icon>
      </button>
      <mat-autocomplete
        (opened)="matAutocompletPanelOpened()"
        (closed)="matAutocompletPanelClosed()"
        #autoLastStringOption="matAutocomplete"
        (optionSelected)="applyFilterTable($event.option.value)"
      >
        <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
          {{ 'label_recent_searches' | translate }}
        </mat-option>
        <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
          {{ opt }}
        </mat-option>
      </mat-autocomplete>
      <input
        #searchInputField
        #searchInputAutocompleteTrigger="matAutocompleteTrigger"
        (input)="searchInputAutocompleteTrigger.closePanel()"
        matInput
        [(ngModel)]="filter"
        [matAutocomplete]="autoLastStringOption"
        (debounceFilterInput)="applyFilterTable($event)"
        [debounceTime]="600"
        maxlength="1500"
        placeholder="{{ 'generic_note_filter_placeholder' | translate }}"
      />
    </mat-form-field>
    <generic-grid-filter-panel
      [clearFilter]="clearFilterSubject.asObservable()"
      [filtersMapSubj]="filtersMapSubject.asObservable()"
      [filterName]="NoteParams.FILTER_TOPIC_IDS"
      [filterApiPath]="ApiPath.Note.Filters.TOPICS(selectedEntity.entityKind, selectedEntity.entityId)"
      filterTitle="{{ 'filter_topic' | translate }}"
      (filterIdsEvEm)="setFiltersIds($event, NoteParams.FILTER_TOPIC_IDS)"
    ></generic-grid-filter-panel>
    <generic-grid-filter-panel
      [clearFilter]="clearFilterSubject.asObservable()"
      [filtersMapSubj]="filtersMapSubject.asObservable()"
      [filterName]="NoteParams.FILTER_USER_IDS"
      [filterApiPath]="ApiPath.Note.Filters.USERS(selectedEntity.entityKind, selectedEntity.entityId)"
      filterTitle="{{ 'filter_user' | translate }}"
      (filterIdsEvEm)="setFiltersIds($event, NoteParams.FILTER_USER_IDS)"
    ></generic-grid-filter-panel>
    <button mat-button color="warn" (click)="clearFilters()" *ngIf="isFiltersSetted()">
      {{ 'label_reset_filters' | translate }}
    </button>
  </div>

  <ng-container *ngIf="notes">
    <mat-expansion-panel
      *ngFor="let note of notes"
      class="detail-expansion-panel"
      [hideToggle]="true"
      [expanded]="!note.noteDeleted"
      [disabled]="true"
    >
      <mat-expansion-panel-header>
        <mat-panel-title [ngClass]="note.noteDeleted ? 'deleted' : ''">
          <div class="title-container">
            <span
              class="title"
              showIfTruncated
              matTooltip="{{ getDatetime(note.noteTimestamp) }} - {{ note.noteTitle }} - {{ note.noteUsers }}"
              >{{ getDatetime(note.noteTimestamp) }} - {{ note.noteTitle }} - {{ note.noteUsers }}</span
            >
          </div>
          <div class="topics-buttons-container">
            <span class="topics" showIfTruncated [matTooltip]="note.noteTopics">{{ note.noteTopics }}</span>
            <button
              *ngIf="note.noteDeleted"
              (click)="$event.stopPropagation()"
              mat-icon-button
              matTooltip="{{ 'label_restore' | translate }}"
              [matTooltipClass]="'fo-mat-tooltip'"
            >
              <mat-icon fontSet="fas" fontIcon="fa-redo-alt"></mat-icon>
            </button>
            <button
              *ngIf="!note.noteDeleted"
              (click)="$event.stopPropagation()"
              mat-icon-button
              matTooltip="{{ 'label_edit' | translate }}"
              [matTooltipClass]="'fo-mat-tooltip'"
            >
              <mat-icon fontSet="fas" fontIcon="fa-pencil-alt"></mat-icon>
            </button>
            <button
              *ngIf="!note.noteDeleted"
              (click)="$event.stopPropagation()"
              mat-icon-button
              matTooltip="{{ 'label_delete' | translate }}"
              [matTooltipClass]="'fo-mat-tooltip'"
            >
              <mat-icon fontSet="fas" fontIcon="fa-trash-alt"></mat-icon>
            </button>
            <button
              *ngIf="note.noteAttachmentCount > 0"
              (click)="$event.stopPropagation()"
              mat-icon-button
              matTooltip="{{ 'label_attachments' | translate }}"
              [matTooltipClass]="'fo-mat-tooltip'"
            >
              <mat-icon fontSet="fas" fontIcon="fa-paperclip"></mat-icon>
            </button>
            <button
              *ngIf="note.noteHistoryCount > 0"
              (click)="$event.stopPropagation()"
              mat-icon-button
              matTooltip="{{ 'label_history' | translate }}"
              [matTooltipClass]="'fo-mat-tooltip'"
            >
              <mat-icon fontSet="far" fontIcon="fa-calendar-alt"></mat-icon>
            </button>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="detail-expansion-panel-body">
        <span class="note-content">{{ note.noteContent }}</span>
      </div>
    </mat-expansion-panel>
  </ng-container>

  <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading">
    <mat-toolbar-row *ngIf="isLoading" class="spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
      <span>{{ 'generic_note_error_nodata' | translate }}</span>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<div
  id="generic-notes-overlay-button"
  #flyingButtons
  *ngIf="isCreateEnabled"
  cdkDragBoundary=".main-content-container"
  (cdkDragEnded)="flyingButtonsDragEnd($event)"
  (cdkDragStarted)="flyingBtnDragging = true"
  [style.pointer-events]="flyingBtnDragging ? 'none' : null"
  class="flying-button-overlay-button"
  cdkDrag
  [ngClass]="[isChatbotEnabled ? 'with-chatbot' : '']"
>
  <ng-container *ngIf="isCreateEnabled">
    <button mat-icon-button (click)="onAdd()" matTooltip="{{ 'generic_note_add' | translate }}" [matTooltipClass]="'fo-mat-tooltip'">
      <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
    </button>
  </ng-container>
  <button
    *ngIf="isChatbotEnabled"
    class="chatbot-button"
    mat-icon-button
    (click)="openChatbot()"
    matTooltip="{{ 'label_finesse_chatbot' | translate }}"
    [matTooltipClass]="'fo-mat-tooltip'"
  >
    <mat-icon fontSet="fas" fontIcon="fa-comments"></mat-icon>
  </button>
</div>
