<div
  class="dialog-draggable-header"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
  [cdkDragConstrainPosition]="computeDragRenderPos.bind(this)"
>
  <mat-icon>drag_indicator</mat-icon>
</div>
<div class="dialog-header">
  <span>{{ selectedEntity?.entityKind | translate }}: {{ selectedEntity?.entityName }}</span>
  <div class="buttons-container">
    <button mat-icon-button matDialogClose>
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-content class="app-generic-entity-file-upload-dialog-content">
  <div id="generic-entity-files-table" class="entity-table">
    <div class="table-wrapper">
      <div class="table-search-paginate hidden-filters">
        <div class="table-search-paginate-left-wrapper">
          <mat-form-field appearance="outline" class="reset-outline-hint filter-field search">
            <button *ngIf="filter" mat-icon-button matSuffix class="small-icon" (click)="clearSearchFilter(); $event.stopPropagation()">
              <mat-icon>clear</mat-icon>
            </button>
            <mat-autocomplete
              (opened)="matAutocompletPanelOpened()"
              (closed)="matAutocompletPanelClosed()"
              #autoLastStringOption="matAutocomplete"
              (optionSelected)="applyFilterTable($event.option.value)"
            >
              <mat-option class="form-field-option label-option" [disabled]="true" *ngIf="lastUsedFilters?.length > 0">
                {{ 'label_recent_searches' | translate }}
              </mat-option>
              <mat-option class="form-field-option" *ngFor="let opt of lastUsedFilters" [value]="opt" [class.mat-selected]="filter === opt">
                {{ opt }}
              </mat-option>
            </mat-autocomplete>
            <input
              #searchInputField
              #searchInputAutocompleteTrigger="matAutocompleteTrigger"
              (input)="searchInputAutocompleteTrigger.closePanel()"
              matInput
              [(ngModel)]="filter"
              [matAutocomplete]="autoLastStringOption"
              (debounceFilterInput)="applyFilterTable($event)"
              [debounceTime]="600"
              maxlength="1500"
              placeholder="{{ 'organisation_table_filter_placeholder' | translate }}"
            /> </mat-form-field
          ><ng-container *ngIf="showFilters">
            <button mat-button color="warn" (click)="clearFilters()" *ngIf="isFiltersSetted()">
              {{ 'label_reset_filters' | translate }}
            </button>
          </ng-container>
        </div>
        <div class="table-search-paginate-right">
          <mat-button-toggle-group [(ngModel)]="showThumbnail">
            <mat-button-toggle [value]="false" class="view-toggle">
              <mat-icon>view_list</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle [value]="true" class="view-toggle">
              <mat-icon>view_module</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="table-filters hidden"></div>

      <div class="table-container">
        <table
          *ngIf="!showThumbnail"
          class="table table-stripes table-truncate-text"
          mat-table
          [dataSource]="dataSourceTable"
          matSort
          #sortTable="matSort"
          [matSortActive]="gbGetInitialOrderBy()"
          [matSortDirection]="gbGetInitialSort()"
          matSortDisableClear
        >
          <ng-container matColumnDef="filenodeName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'entity_file_name' | translate }}
            </th>
            <td
              mat-cell
              showIfTruncated
              [matTooltip]="row.filenodeName"
              *matCellDef="let row"
              (click)="$event.stopPropagation(); downloadFile(row)"
            >
              <a href="javascript:void(0)">{{ row.filenodeName }}</a>
            </td>
          </ng-container>

          <ng-container matColumnDef="filenodeType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'entity_file_type' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.filenodeType" showIfTruncated>{{ row.filenodeType }}</td>
          </ng-container>

          <ng-container matColumnDef="filenodeSize">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'entity_file_size' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" matTooltip="{{ row.filenodeSize | filesize }}" showIfTruncated>
              {{ row.filenodeSize | filesize }}
            </td>
          </ng-container>

          <ng-container matColumnDef="filenodeUploaded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'entity_file_upload_date' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" [matTooltip]="getDatetime(row.filenodeUploaded)" showIfTruncated>
              {{ getDatetime(row.filenodeUploaded) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="filenodeUploadedby">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ 'entity_file_uploaded_by' | translate }}
            </th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.filenodeUploadedby" showIfTruncated>{{ row.filenodeUploadedby }}</td>
          </ng-container>

          <ng-container matColumnDef="actionButtons">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="icondelete-header"></th>
            <td mat-cell *matCellDef="let row" class="icondelete-cell">
              <mat-icon
                *ngIf="selectedEntity.entityDelete"
                (click)="deleteFile(row)"
                style="color: red"
                fontSet="fas"
                fontIcon="fa-times"
                matTooltip="{{ 'label_action_remove' | translate }}"
                [matTooltipClass]="'fo-mat-tooltip'"
              ></mat-icon>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
        </table>
        <div class="thumbnails-container" *ngIf="showThumbnail && dataSourceTable.data.length > 0">
          <mat-card *ngFor="let file of dataSourceTable.data" class="thumbnail-card">
            <div class="main-container">
              <div class="title-container">
                <span class="title">{{ file.filenodeName }}</span>
              </div>
              <div class="image-container">
                <ng-container *ngIf="file.filenodeThumbnail != null">
                  <img
                    class="image"
                    [src]="transform(file.filenodeThumbnail)"
                    alt="File Preview Thumbnail"
                    (click)="$event.stopPropagation(); previewFile(file)"
                  />
                </ng-container>
                <ng-container *ngIf="file.filenodeThumbnail == null">
                  <span>{{ 'label_no_file_preview_available' | translate }}</span>
                </ng-container>
              </div>
              <div class="button-container">
                <button mat-stroked-button color="primary" (click)="$event.stopPropagation(); downloadFile(file)">
                  <span>{{ 'label_download' | translate }}</span>
                </button>
              </div>
            </div>
          </mat-card>
        </div>
        <mat-toolbar class="table-footer-toolbar" *ngIf="noEntityData || isLoading || isUploading">
          <mat-progress-bar *ngIf="isUploading" mode="determinate" [value]="progressPercentage"></mat-progress-bar>
          <mat-toolbar-row *ngIf="isLoading" class="spinner">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-toolbar-row>
          <mat-toolbar-row *ngIf="noEntityData" class="no-data-message">
            <span>{{ 'entity_files_table_error_nodata' | translate }}</span>
          </mat-toolbar-row>
        </mat-toolbar>
        <mat-toolbar class="table-footer-toolbar">
          <mat-toolbar-row>
            <mat-paginator
              [disabled]="isLoading"
              #paginatorTable
              [pageSizeOptions]="pageSizeList"
              [length]="pageTotalElements"
              class="reset-paginator"
            ></mat-paginator>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </div>
  </div>
  <div
    id="generic-file-upload-overlay-button"
    #flyingButtons
    cdkDragBoundary=".cdk-overlay-pane"
    (cdkDragEnded)="flyingButtonsDragEnd($event)"
    (cdkDragStarted)="flyingBtnDragging = true"
    [style.pointer-events]="flyingBtnDragging ? 'none' : null"
    class="flying-button-overlay-button"
    cdkDrag
    [ngClass]="[isChatbotEnabled ? 'with-chatbot' : '']"
    *ngIf="selectedEntity.entityDelete"
  >
    <input hidden type="file" #fileInput (click)="$event.stopPropagation()" (change)="uploadFiles($event.target.files)" multiple />
    <button
      mat-icon-button
      (click)="fileInput.click(); $event.stopPropagation()"
      matTooltip="{{ 'label_upload' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
    </button>
    <button
      *ngIf="isChatbotEnabled"
      class="chatbot-button"
      mat-icon-button
      (click)="openChatbot()"
      matTooltip="{{ 'label_finesse_chatbot' | translate }}"
      [matTooltipClass]="'fo-mat-tooltip'"
    >
      <mat-icon fontSet="fas" fontIcon="fa-comments"></mat-icon>
    </button>
  </div>
</div>
