<div id="generic-relation-container">
  <mat-expansion-panel
    class="detail-expansion-panel"
    (opened)="isPanelExpanded = true"
    (closed)="isPanelExpanded = false"
    [hideToggle]="true"
    [expanded]="true"
    [disabled]="true"
  >
    <div id="generic-relation" class="detail-expansion-panel-body">
      <div id="generic-relation-table">
        <div class="table-wrapper">
          <div
            class="table-search-paginate"
            [ngStyle]="{ display: !isViewMode() || pagination || searchFilter ? '' : 'none' }"
            [ngClass]="!gridFiltersConfig && !enableFilter ? 'hidden-filters' : ''"
          >
            <div class="table-search-paginate-left-wrapper">
              <!-- Search Filter Field-->
              <mat-form-field
                appearance="outline"
                class="reset-outline-hint filter-field search"
                [ngStyle]="{ display: searchFilter || !isViewMode() ? '' : 'none' }"
              >
                <input
                  #searchInputField
                  matInput
                  [(ngModel)]="filter"
                  (debounceFilterInput)="applyFilterTable($event)"
                  [debounceTime]="600"
                  maxlength="1500"
                  [placeholder]="
                    isAddMode() ? (addTableFilterPlaceholder | translate) : ('generic_relation_table_filter_placeholder' | translate)
                  "
                />
              </mat-form-field>
              <ng-container *ngIf="showFilters">
                <button mat-button color="warn" (click)="clearFilters()" *ngIf="isFiltersSetted()">
                  {{ 'label_reset_filters' | translate }}
                </button>
                <button mat-button color="primary" (click)="enableFilter = !enableFilter" *ngIf="gridFiltersConfig">
                  {{ enableFilter ? ('label_hide_filters' | translate) : ('label_more_filters' | translate) }}
                </button>
              </ng-container>
            </div>
            <!-- Pagination Field -->
            <div class="table-search-paginate-right"></div>
            <!-- Select All Button -->
            <!-- <ng-container *ngIf="isAddMode() && addModeSelectAllEnabled && entityRelations.length === 0 && !isSelectAllSelected()">
              <button mat-raised-button color="primary" (click)="selectAllRelations(); $event.stopPropagation()">
                <mat-icon>plus</mat-icon>
                <span>{{ addModeSelectAllLabel | translate }}</span>
              </button>
            </ng-container> -->
          </div>
          <div class="table-filters" *ngIf="gridFiltersConfig && !isAddMode()" [ngClass]="enableFilter ? 'visible' : 'hidden'">
            <generic-grid-filter-panel
              *ngFor="let filter of gridFiltersConfig"
              [clearFilter]="clearFilterSubject.asObservable()"
              [filtersMapSubj]="filtersMapSubject.asObservable()"
              [filterName]="filter.filterParams"
              [filterApiPath]="filter.filterApiPath"
              filterTitle="{{ filter.filterTitle | translate }}"
              (filterIdsEvEm)="setFiltersIds($event, filter.filterParams)"
            ></generic-grid-filter-panel>
          </div>
          <mat-expansion-panel
            class="detail-expansion-panel"
            *ngIf="(isAddMode() || isRemoveMode()) && itemsBoardTable?.data && itemsBoardTable.data.length > 0"
            [expanded]="true"
            [@.disabled]="true"
          >
            <mat-expansion-panel-header>
              <mat-panel-title *ngIf="isRemoveMode()">
                {{ 'relation_remove' | translate }} {{ ' (' }} {{ itemsBoardTable.data.length }} {{ ')' }}
              </mat-panel-title>
              <mat-panel-title *ngIf="isAddMode()">
                {{ 'relation_add' | translate }} {{ ' (' }} {{ itemsBoardTable.data.length }} {{ ')' }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="table-container">
              <table
                class="detail-expansion-panel-body table table-stripes table-truncate-text"
                mat-table
                [dataSource]="itemsBoardTable"
                [ngClass]="!gridEnableHeader ? 'no-header-table' : ''"
              >
                <!-- name column -->
                <ng-container matColumnDef="entityName">
                  <th mat-header-cell *matHeaderCellDef>{{ gridEnableHeader ? ('entity_name' | translate) : null }}</th>
                  <td mat-cell *matCellDef="let row" [matTooltip]="row.entityName" showIfTruncated>
                    {{ row.entityName }}
                  </td>
                </ng-container>
                <!-- description column -->
                <ng-container matColumnDef="entityDescription">
                  <th mat-header-cell *matHeaderCellDef>{{ gridEnableHeader ? ('entity_description' | translate) : null }}</th>
                  <td mat-cell *matCellDef="let row" [matTooltip]="row.entityDescription" showIfTruncated>
                    {{ row.entityDescription }}
                  </td>
                </ng-container>
                <!-- checkbox column -->
                <ng-container matColumnDef="checkbox">
                  <th mat-header-cell *matHeaderCellDef></th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox *ngIf="isAddMode()" [disabled]="true" [checked]="true"></mat-checkbox>
                  </td>
                </ng-container>
                <!-- cancel column -->
                <ng-container matColumnDef="cancel">
                  <th mat-header-cell *matHeaderCellDef class="icondelete-header"></th>
                  <td mat-cell class="icondelete-cell" *matCellDef="let row">
                    <mat-icon (click)="removeRelationFromItemBoard(row)" style="color: red" fontSet="fas" fontIcon="fa-times"></mat-icon>
                  </td>
                </ng-container>

                <!-- Configurable columns -->
                <ng-container *ngIf="gridColumnsConfig">
                  <ng-container *ngFor="let c of gridColumnsConfig">
                    <ng-container matColumnDef="{{ c.entityField }}">
                      <ng-container *ngIf="gridEnableHeader"
                        ><th mat-header-cell *matHeaderCellDef [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }">
                          {{ c.columnTitleKey | translate }}
                        </th></ng-container
                      >
                      <ng-container *ngIf="!gridEnableHeader">
                        <th mat-header-cell *matHeaderCellDef></th>
                      </ng-container>
                      <td
                        mat-cell
                        *matCellDef="let row"
                        [matTooltip]="getTooltipString(c, row)"
                        showIfTruncated
                        [ngSwitch]="c.columnType"
                        [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }"
                      >
                        <span *ngSwitchCase="'date'">{{ getDate(row[c.entityField]) }}</span>
                        <span *ngSwitchCase="'timestamp'">{{ getTimestamp(row[c.entityField]) }}</span>
                        <span *ngSwitchCase="'price'">{{
                          row[c.entityField] != null ? getMoney(row[c.entityField]) : row[c.columnPriceSwitchField]
                        }}</span>
                        <span *ngSwitchCase="'factor'">{{ getFactor(row[c.entityField]) }}</span>
                        <span *ngSwitchCase="'percentage'">{{ getPercentageNormalized(row[c.entityField]) }}</span>
                        <span *ngSwitchCase="'boolean'">
                          <ng-container *ngIf="row[c.entityField]">
                            <mat-icon style="height: auto; vertical-align: middle; color: green; font-size: 20px">check</mat-icon>
                          </ng-container>
                        </span>
                        <span *ngSwitchDefault>{{ row[c.entityField] }}</span>
                      </td>
                    </ng-container>
                  </ng-container>
                </ng-container>

                <ng-container matColumnDef="auditlog">
                  <th mat-header-cell class="icon-header" *matHeaderCellDef></th>
                  <td mat-cell class="icon-cell" *matCellDef="let row">
                    <mat-icon
                      *ngIf="row.entityId != null"
                      (click)="$event.stopPropagation(); openAuditLog(row)"
                      fontSet="fas"
                      fontIcon="fa-file-alt"
                      matTooltip="{{ 'label_show_audit_log' | translate }}"
                      [matTooltipClass]="'fo-mat-tooltip'"
                    ></mat-icon>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>
              </table>
            </div>
          </mat-expansion-panel>

          <div class="table-title" *ngIf="!isViewMode() && !isReadOnlyMode()">
            <span> {{ relationLabel | translate }}</span>
          </div>
          <div class="table-container">
            <table
              class="table table-stripes table-truncate-text"
              mat-table
              [dataSource]="dataSourceTable"
              matSort
              #sortTable="matSort"
              [matSortActive]="gbGetInitialOrderBy()"
              [matSortDirection]="gbGetInitialSort()"
              matSortDisableClear
              [ngClass]="!gridEnableHeader ? 'no-header-table' : ''"
            >
              <!-- name column -->
              <ng-container matColumnDef="entityName">
                <th mat-header-cell *matHeaderCellDef>{{ gridEnableHeader ? ('entity_name' | translate) : null }}</th>
                <td mat-cell *matCellDef="let row" [matTooltip]="row.entityName" showIfTruncated>
                  {{ row.entityName }}
                </td>
              </ng-container>
              <!-- description column -->
              <ng-container matColumnDef="entityDescription">
                <th mat-header-cell *matHeaderCellDef>{{ gridEnableHeader ? ('entity_description' | translate) : null }}</th>
                <td mat-cell *matCellDef="let row" [matTooltip]="row.entityDescription" showIfTruncated>
                  {{ row.entityDescription }}
                </td>
              </ng-container>
              <!-- checkbox column -->
              <ng-container matColumnDef="checkbox">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    *ngIf="isAddMode()"
                    [disabled]="isAlreadyAssigned(row) || isSelectAllSelected()"
                    (change)="addRelationToItemBoard(row)"
                  ></mat-checkbox>
                </td>
              </ng-container>
              <!-- cancel column -->
              <ng-container matColumnDef="cancel">
                <th mat-header-cell *matHeaderCellDef class="icondelete-cell"></th>
                <td mat-cell class="icondelete-cell" *matCellDef="let row">
                  <mat-icon
                    (click)="removeEntryMode(row)"
                    style="color: red"
                    fontSet="fas"
                    fontIcon="fa-times"
                    [ngStyle]="{ visibility: isRemoveMode() || isEditMode() ? '' : 'hidden' }"
                  ></mat-icon>
                </td>
              </ng-container>
              <!-- Configurable columns -->
              <ng-container *ngIf="gridColumnsConfig">
                <ng-container *ngFor="let c of gridColumnsConfig">
                  <ng-container matColumnDef="{{ c.entityField }}">
                    <ng-container *ngIf="gridEnableHeader"
                      ><th mat-header-cell *matHeaderCellDef [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }">
                        {{ c.columnTitleKey | translate }}
                      </th></ng-container
                    >
                    <ng-container *ngIf="!gridEnableHeader">
                      <th mat-header-cell *matHeaderCellDef></th>
                    </ng-container>
                    <td
                      mat-cell
                      *matCellDef="let row"
                      [matTooltip]="getTooltipString(c, row)"
                      showIfTruncated
                      [ngSwitch]="c.columnType"
                      [ngStyle]="{ width: c.columnWidth != null ? c.columnWidth : '' }"
                    >
                      <span *ngSwitchCase="'date'">{{ getDate(row[c.entityField]) }}</span>
                      <span *ngSwitchCase="'timestamp'">{{ getTimestamp(row[c.entityField]) }}</span>
                      <span *ngSwitchCase="'price'">{{
                        row[c.entityField] != null ? getMoney(row[c.entityField]) : row[c.columnPriceSwitchField]
                      }}</span>
                      <span *ngSwitchCase="'factor'">{{ getFactor(row[c.entityField]) }}</span>
                      <span *ngSwitchCase="'percentage'">{{ getPercentageNormalized(row[c.entityField]) }}</span>
                      <span *ngSwitchCase="'boolean'">
                        <ng-container *ngIf="row[c.entityField]">
                          <mat-icon style="height: auto; vertical-align: middle; color: green; font-size: 20px">check</mat-icon>
                        </ng-container>
                      </span>
                      <span *ngSwitchDefault>{{ row[c.entityField] }}</span>
                    </td>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container matColumnDef="auditlog">
                <th mat-header-cell class="icon-header" *matHeaderCellDef></th>
                <td mat-cell class="icon-cell" *matCellDef="let row">
                  <mat-icon
                    *ngIf="row.entityId != null"
                    (click)="$event.stopPropagation(); openAuditLog(row)"
                    fontSet="fas"
                    fontIcon="fa-file-alt"
                    matTooltip="{{ 'label_show_audit_log' | translate }}"
                    [matTooltipClass]="'fo-mat-tooltip'"
                  ></mat-icon>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumnsTable; sticky: true"></tr>
              <tr mat-row (click)="onRowClicked(row)" *matRowDef="let row; columns: displayedColumnsTable"></tr>
            </table>
            <mat-toolbar class="table-footer-toolbar" *ngIf="isDatasourceEmpty() || isLoading">
              <mat-toolbar-row *ngIf="isLoading" class="spinner">
                <mat-spinner diameter="50"></mat-spinner>
              </mat-toolbar-row>
              <mat-toolbar-row *ngIf="isDatasourceEmpty()" class="no-data-message">
                <span>{{ 'relations_table_error_nodata' | translate }}</span>
              </mat-toolbar-row>
            </mat-toolbar>
            <mat-toolbar class="table-footer-toolbar" *ngIf="pagination">
              <mat-toolbar-row>
                <mat-paginator
                  [disabled]="isLoading"
                  #paginatorTable
                  [pageSizeOptions]="pageSizeList"
                  [length]="pageTotalElements"
                  class="reset-paginator"
                ></mat-paginator>
              </mat-toolbar-row>
            </mat-toolbar>
          </div>
        </div>
      </div>
    </div>
    <div class="details-container" *ngIf="selectedEntity && selectedEntity.entityId && mustShowRoleDetails()">
      <mat-tab-group class="generic-grid-view-details-tab" *ngIf="atomicDetailsSteps?.length > 1">
        <mat-tab *ngFor="let step of atomicDetailsSteps" [disabled]="isEditing">
          <ng-template mat-tab-label>
            {{ step.formstepName }}
          </ng-template>
          <ng-template matTabContent>
            <generic-entity-form
              [entitySubj]="selectedEntitySubject.asObservable()"
              [getAPIPath]="getMainDetailsPath(step.formstepId)"
              [putAPIPath]="getMainDetailsPath(step.formstepId)"
              (createUpdateCallbackEvEm)="createUpdateDetailsCallbackEvEm($event)"
              [isPanelExpanded]="true"
              [showPanelTitleToggle]="false"
              [isEditing]="isEditing"
              (isEditingChange)="onEditChange($event)"
            ></generic-entity-form>
          </ng-template> </mat-tab
      ></mat-tab-group>
      <ng-container *ngIf="atomicDetailsSteps?.length === 1">
        <generic-entity-form
          [entitySubj]="selectedEntitySubject.asObservable()"
          [getAPIPath]="getMainDetailsPath(atomicDetailsSteps[0].formstepId)"
          [putAPIPath]="getMainDetailsPath(atomicDetailsSteps[0].formstepId)"
          (createUpdateCallbackEvEm)="createUpdateDetailsCallbackEvEm($event)"
          [isPanelExpanded]="true"
          [showPanelTitleToggle]="false"
          [isEditing]="isEditing"
          (isEditingChange)="onEditChange($event)"
        ></generic-entity-form>
      </ng-container>
    </div>
  </mat-expansion-panel>
</div>
<div
  id="generic-grid-details-overlay-button"
  #flyingButtons
  cdkDragBoundary=".main-content-container"
  (cdkDragEnded)="flyingButtonsDragEnd($event)"
  (cdkDragStarted)="flyingBtnDragging = true"
  [style.pointer-events]="flyingBtnDragging ? 'none' : null"
  class="flying-button-overlay-button"
  [ngClass]="[showDetails || !isViewMode() ? 'double-buttons' : '', isChatbotEnabled ? 'with-chatbot' : '']"
  cdkDrag
  *ngIf="!isReadOnlyMode()"
>
  <!-- RELATIONS -->
  <ng-container *ngIf="!showDetails">
    <ng-container *ngIf="isViewMode() && !isDatasourceEmpty()">
      <button mat-icon-button (click)="editEntryMode()" matTooltip="{{ 'label_edit' | translate }}" [matTooltipClass]="'fo-mat-tooltip'">
        <mat-icon fontSet="fas" fontIcon="fa-wrench"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="isEditMode() || (isRemoveMode() && isItemsboardEmpty()) || (isViewMode() && isDatasourceEmpty())">
      <button mat-icon-button (click)="addEntryMode()" matTooltip="{{ 'label_add' | translate }}" [matTooltipClass]="'fo-mat-tooltip'">
        <mat-icon fontSet="fas" fontIcon="fa-plus"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="isEditMode() && !isDatasourceEmpty()">
      <button mat-icon-button (click)="viewEntryMode()" matTooltip="{{ 'label_back' | translate }}" [matTooltipClass]="'fo-mat-tooltip'">
        <mat-icon fontSet="fas" fontIcon="fa-long-arrow-alt-left"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="isAddMode() || (isRemoveMode() && !isItemsboardEmpty())">
      <button
        mat-icon-button
        (click)="onSave()"
        [disabled]="isItemsboardEmpty()"
        matTooltip="{{ 'label_save' | translate }}"
        [matTooltipClass]="'fo-mat-tooltip'"
      >
        <mat-icon fontSet="fas" fontIcon="fa-save"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="isAddMode() || isRemoveMode()">
      <button
        mat-icon-button
        (click)="onCancel($event)"
        [matMenuTriggerFor]="contextEditMenu"
        #confirmCancelMenu="matMenuTrigger"
        matTooltip="{{ 'label_cancel' | translate }}"
        [matTooltipClass]="'fo-mat-tooltip'"
      >
        <mat-icon fontSet="fas" fontIcon="fa-times-circle"></mat-icon>
      </button>
    </ng-container>
  </ng-container>
  <!-- DETAILS -->
  <ng-container *ngIf="showDetails">
    <ng-container *ngIf="!isDetailsEditing">
      <button mat-icon-button (click)="onEditChange(true)">
        <mat-icon fontSet="fas" fontIcon="fa-edit"></mat-icon>
      </button>
      <button mat-icon-button (click)="clearRelationEntityDetails()">
        <mat-icon fontSet="fas" fontIcon="fa-times"></mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="isDetailsEditing">
      <button mat-icon-button (click)="onDetailsSave($event)" [disabled]="saveIsDisabled">
        <mat-icon fontSet="fas" fontIcon="fa-save"></mat-icon>
      </button>
      <button mat-icon-button (click)="onDetailsCancel($event)" [matMenuTriggerFor]="contextEditMenu" #confirmCancelMenu="matMenuTrigger">
        <mat-icon fontSet="fas" fontIcon="fa-times-circle"></mat-icon>
      </button>
    </ng-container>
  </ng-container>
  <button
    *ngIf="isChatbotEnabled"
    class="chatbot-button"
    mat-icon-button
    (click)="openChatbot()"
    matTooltip="{{ 'label_finesse_chatbot' | translate }}"
    [matTooltipClass]="'fo-mat-tooltip'"
  >
    <mat-icon fontSet="fas" fontIcon="fa-comments"></mat-icon>
  </button>
</div>
<mat-menu
  class="flying-button-context-menu"
  #contextEditMenu="matMenu"
  xPosition="before"
  yPosition="above"
  (closed)="enableConfirmCancel = false"
>
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="showDetails ? onDetailsConfirmCancel() : onConfirmCancel()">
      <mat-icon fontSet="fas" fontIcon="fa-times-circle"></mat-icon>
      <span> {{ 'label_confirm_cancel' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
