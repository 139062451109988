import { ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import * as saveAs from 'file-saver';
import insertTextAtCursor from 'insert-text-at-cursor';
import { BehaviorSubject } from 'rxjs';
import Base64UploaderPlugin from 'src/@ckeditor/Base64Upload';
import { ApiPath } from 'src/app/configs/api-paths';
import { Entities } from 'src/app/configs/entities';
import {
  FilesUploadProgressDialogComponent,
  FilesUploadProgressDialogData,
} from 'src/app/core/modal/files-upload-progress-dialog/files-upload-progress-dialog.component';
import { Contact } from 'src/app/models/contact';
import { ExtendedFileModel } from 'src/app/models/core/extended-file';
import { KeyValueAddon2 } from 'src/app/models/core/key-value-data';
import { Email, EmailAddress, EmailFile, EmailRequest } from 'src/app/models/mailtemplate/mail-template-preview';
import { FilesService } from 'src/app/services/files.data.services';
import { MailTemplatesDataService } from 'src/app/services/mail-template-data.service';
import { MessageNotifierService } from 'src/app/services/utils/message-notifier.service';
import { FormatComponent } from '../../base-components/format-component';
import { FileTabType } from '../../files/file-tab/file-tab-type';
import { MessageBox, MessageBoxButton, MessageBoxStyle, MessageBoxType } from '../../message-box';

export interface GenericSendEmailDialogData {
  entityKind: string;
  entityId: number;
  entityName: string;
  files: EmailFile[];
  filterEntityKind?: string;
  filterEntityId?: number;
  viewMail: Email;
  emailhistoryId: number;
}

@Component({
  selector: 'app-generic-send-email-dialog',
  templateUrl: './generic-send-email-dialog.component.html',
  styleUrls: ['./generic-send-email-dialog.component.scss'],
})
export class GenericSendEmailDialogComponent extends FormatComponent implements OnInit {
  @ViewChild('ckeditor') ckeditor: CKEditorComponent;
  mailTemplates: Email[];
  languages: Map<number, string>;
  stages: Map<number, string>;
  selectedLanguage: number;
  selectedStage: number;
  selectedTemplate: Email;
  isLoading = false;
  isUploading: boolean;
  progressPercentage: number;

  public Editor = ClassicEditor;
  public config;
  isStringInputMenuOpen = false;

  isViewMail = false;

  constructor(
    protected dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    protected mailDataService: MailTemplatesDataService,
    protected messageNotifierService: MessageNotifierService,
    private filesService: FilesService,
    private sanitized: DomSanitizer,
    public dialogRef: MatDialogRef<GenericSendEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericSendEmailDialogData
  ) {
    super();

    this.config = {
      heading: {
        options: [
          { model: 'paragraph', title: this.translate.instant(_('ckeditor_paragraph')), class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: this.translate.instant(_('ckeditor_heading1')), class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: this.translate.instant(_('ckeditor_heading2')), class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: this.translate.instant(_('ckeditor_heading3')), class: 'ck-heading_heading3' },
        ],
      },
      language: this.translate.currentLang,
      extraPlugins: [Base64UploaderPlugin],
      link: { addTargetToExternalLinks: true },
    };
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.isViewMail = this.data.viewMail != null;
    if (this.isViewMail) {
      this.selectedTemplate = this.data.viewMail;
      this.isLoading = false;
    } else {
      this.subscribe(
        this.mailDataService.getEmailByEntityKindEntityId(
          this.data.entityKind,
          this.data.entityId,
          this.data.filterEntityKind,
          this.data.filterEntityId
        ),
        ({ data }) => {
          this.mailTemplates = data;
          this.initLanguages();
          this.initGroups();
          if (this.data.files) {
            this.mailTemplates.forEach((mt) => mt.emailAttachments.push(...this.data.files));
          }
          this.isLoading = false;
        },
        (error) => (this.isLoading = false)
      );
    }
  }

  private initLanguages() {
    this.languages = new Map<number, string>();
    this.stages = new Map<number, string>();
    this.mailTemplates.forEach((mt) => {
      if (!this.languages.has(mt.emailLanguageId)) {
        this.languages.set(mt.emailLanguageId, mt.emailLanguage);
      }
      if (!this.stages.has(mt.emailStageId)) {
        this.stages.set(mt.emailStageId, mt.emailStage);
      }
    });
    this.selectedLanguage = Array.from(this.languages.keys())[0];
    this.selectedStage = Array.from(this.stages.keys())[0];
    this.selectedTemplate = this.mailTemplates.find(
      (mt) => mt.emailLanguageId === this.selectedLanguage && mt.emailStageId === this.selectedStage
    );
  }

  private initGroups() {
    this.stages = new Map<number, string>();
    this.mailTemplates.forEach((mt) => {
      if (!this.stages.has(mt.emailStageId)) {
        this.stages.set(mt.emailStageId, mt.emailStage);
      }
    });
  }
  selectedLanguageOptionChanged() {
    this.selectedTemplate = this.mailTemplates.find(
      (mt) => mt.emailLanguageId === this.selectedLanguage && mt.emailStageId === this.selectedStage
    );
  }
  selectedStageOptionChanged() {
    this.selectedTemplate = this.mailTemplates.find(
      (mt) => mt.emailLanguageId === this.selectedLanguage && mt.emailStageId === this.selectedStage
    );
  }

  sendEmail() {
    const request: EmailRequest = {
      emailMailoutgoingaccountId: this.selectedTemplate.emailMailoutgoingaccountId,
      emailBccs: this.selectedTemplate.emailBccs,
      emailBody: this.selectedTemplate.emailBody,
      emailCcs: this.selectedTemplate.emailCcs,
      emailColorBackground: this.selectedTemplate.emailColorBackground,
      emailColorButton: this.selectedTemplate.emailColorButton,
      emailColorFrame: this.selectedTemplate.emailColorFrame,
      emailFontfamily: this.selectedTemplate.emailFontfamily,
      emailFooter: this.selectedTemplate.emailFooter,
      emailFooterImage: this.selectedTemplate.emailFooterImage,
      emailHeaderImage: this.selectedTemplate.emailHeaderImage,
      emailSubject: this.selectedTemplate.emailSubject,
      emailTos: this.selectedTemplate.emailTos,
      emailWidthContent: this.selectedTemplate.emailWidthContent,
      emailWidthFrame: this.selectedTemplate.emailWidthFrame,
      emailBodyTags: this.selectedTemplate.emailBodyTags,
      emailSubjectTags: this.selectedTemplate.emailSubjectTags,
      emailDateFormat: this.selectedTemplate.emailDateFormat,
      emailDateTimeFormat: this.selectedTemplate.emailDateTimeFormat,
      emailMoneyFormat: this.selectedTemplate.emailMoneyFormat,
      emailNumberFormat: this.selectedTemplate.emailNumberFormat,
      emailTimeFormat: this.selectedTemplate.emailTimeFormat,
      // emailEntityId: this.data.entityId,
      // emailEntityKind: this.data.entityKind,
      emailFileIds: this.selectedTemplate.emailAttachments.map((a) => a.fileId),
      emailShowFooterText: this.selectedTemplate.emailShowFooterText,
      emailShowQuote: this.selectedTemplate.emailShowQuote,
    };
    this.subscribe(this.mailDataService.sendEmailByEntityKindEntityId(this.data.entityKind, this.data.entityId, request), (response) => {
      if (response.data) {
        if (response.data.state) {
          // Success
          this.messageNotifierService.showSuccessMessage(_('toastr_email_successfully_sent_check_history'));
          this.dialogRef.close();
        } else if (response.data.error) {
          // Fail
          this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
        }
      } else {
        this.messageNotifierService.showWarningMessage('toastr_no_data');
      }
    });
  }

  resendEmail() {
    this.subscribe(
      MessageBox.show(
        this.dialog,
        this.translate.instant(_('label_do_you_really_want_resend_email')),
        this.translate.instant(_('label_resend_email')),
        null,
        MessageBoxType.Comfirm,
        MessageBoxButton.OkCancel,
        false,
        MessageBoxStyle.Full,
        '600px'
      ),
      (result) => {
        if (result.result === 'ok') {
          this.subscribe(
            this.mailDataService.resendEmailByEntityKindEntityId(this.data.entityKind, this.data.entityId, this.data.emailhistoryId),
            (response) => {
              if (response.data) {
                if (response.data.state) {
                  // Success
                  this.messageNotifierService.showSuccessMessage(_('toastr_email_successfully_sent_check_history'));
                  this.dialogRef.close();
                } else if (response.data.error) {
                  // Fail
                  this.messageNotifierService.showErrorMessage(response.data.error, response.data.systemerrorId);
                }
              } else {
                this.messageNotifierService.showWarningMessage('toastr_no_data');
              }
            }
          );
        }
      }
    );
  }

  isSendMailValid() {
    if (this.selectedTemplate == null) {
      return false;
    } else if (this.selectedTemplate.emailTos.length === 0) {
      return false;
    } else {
      if (
        this.selectedTemplate.emailBody != null &&
        this.selectedTemplate.emailBody !== '' &&
        this.selectedTemplate.emailSubject != null &&
        this.selectedTemplate.emailSubject !== '' &&
        this.isRequiredSubjectTagsValid() &&
        this.isRequiredBodyTagValid()
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  isRequiredSubjectTagsValid() {
    if (this.selectedTemplate.emailSubjectTags != null && this.selectedTemplate.emailSubjectTags.length > 0) {
      return this.selectedTemplate.emailSubjectTags
        .filter((t) => t.addon2 === 't')
        .every((t) => this.selectedTemplate.emailSubject.includes(t.addon));
    } else {
      return true;
    }
  }

  isRequiredBodyTagValid() {
    if (this.selectedTemplate.emailBodyTags != null && this.selectedTemplate.emailBodyTags.length > 0) {
      return this.selectedTemplate.emailBodyTags
        .filter((t) => t.addon2 === 't')
        .every((t) => this.selectedTemplate.emailBody.includes(t.addon));
    } else {
      return true;
    }
  }

  isRequiredSubjectTagSetted(key: string): boolean {
    if (this.selectedTemplate == null || this.selectedTemplate.emailSubject == null) {
      return false;
    }
    return this.selectedTemplate.emailSubject.includes(key);
  }

  isRequiredBodyTagSetted(key: string): boolean {
    if (this.selectedTemplate == null || this.selectedTemplate.emailBody == null) {
      return false;
    }
    return this.selectedTemplate.emailBody.includes(key);
  }

  uploadFile(fileList: FileList): void {
    this.subscribe(
      this.dialog
        .open<FilesUploadProgressDialogComponent, FilesUploadProgressDialogData, ExtendedFileModel[]>(FilesUploadProgressDialogComponent, {
          autoFocus: false,
          width: '800px',
          height: '60vh',
          panelClass: 'files-upload-progress-dialog',
          hasBackdrop: true,
          disableClose: true,
          data: {
            fileList: fileList,
            apipath: ApiPath.Files.FILES_UPLOAD(FileTabType.user.key, -1),
          } as FilesUploadProgressDialogData,
        })
        .afterClosed(),
      (uploadedFiles) => {
        if (uploadedFiles.length > 0) {
          this.selectedTemplate.emailAttachments.push(
            ...uploadedFiles
              .map((fe) => fe.uploadStatus.fileEntity)
              .map((f) => {
                return { ...new EmailFile(), fileId: -1, fileFileId: f.entityId, fileName: f.entityName, fileDeleted: false };
              })
          );
        }
      }
    );
  }

  removeFile(file: EmailFile) {
    const index = this.selectedTemplate.emailAttachments.findIndex((f) => f.fileId === file.fileId);
    if (index !== -1) {
      this.selectedTemplate.emailAttachments.splice(index, 1);
    }
  }

  get availableFiles() {
    return this.selectedTemplate.emailAttachments;
  }

  onCkeditorMailReady(): void {
    // WORKAROUND - to fix ngModel binding does not set initial value https://github.com/ckeditor/ckeditor5-angular/issues/265
    if (this.ckeditor && this.selectedTemplate.emailBody) {
      setTimeout(() => {}, 0);
    }
  }

  onAddSingleTextMailPlaceholder(button: KeyValueAddon2<string, string, string>) {
    if (this.selectedTemplate.emailSubject == null) {
      this.selectedTemplate.emailSubject = '';
    }
    const el = document.getElementById('form-field-multiline-mail-inputarea');
    insertTextAtCursor(el, button.addon);
  }

  onAddCkeditorTextMailPlaceholder(button: KeyValueAddon2<string, string, string>) {
    const viewFragment = this.ckeditor.editorInstance?.data.processor.toView(`<p>${button.addon}</p>`);
    const modelFragment = this.ckeditor.editorInstance?.data.toModel(viewFragment);
    this.ckeditor.editorInstance?.model.insertContent(modelFragment);
    this.ckeditor.editorInstance?.editing.view.focus();
  }

  sanitizeHtml(value: string) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  downloadFile(file: EmailFile) {
    this.subscribe(this.filesService.downloadFile(Entities.FILE, file.fileId), (blob) => {
      console.log(blob.type);
      if (blob.type === 'text/plain' || blob.type.match(/image\/\w+/)) {
        const fileURL = URL.createObjectURL(blob);

        window.open(fileURL, '_blank');
      } else {
        saveAs(blob, file.fileName); // FileSaver;
      }
    });
  }

  /** TOS ADDRESSES */

  originalContractToAddresses: EmailAddress[];
  actualToAddressesSelection: Contact[];
  currentToAddressesSelectionSubj: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>(null);
  multiselectionToAddressesDialogRef: MatDialogRef<any>;

  openToAddressesDialog(ref: TemplateRef<any>) {
    this.actualToAddressesSelection =
      this.selectedTemplate.emailTos != null
        ? this.selectedTemplate.emailTos.map((ffer) => {
            return {
              ...new Contact(),
              entityId: ffer.entityId,
              entityName: `${ffer.emailaddressFullname} (${ffer.emailaddressEmail})`,
              // entityName: ffer.entityName,
              entityKind: ffer.entityKind,
              contactFirstname: ffer.emailaddressFirstname,
              contactLastname: ffer.emailaddressLastname,
              contactEmail: ffer.emailaddressEmail,
              deleted: ffer.emailaddressDeleted,
            };
          })
        : [];
    this.currentToAddressesSelectionSubj.next(this.actualToAddressesSelection);
    this.multiselectionToAddressesDialogRef = this.dialog.open(ref, {
      autoFocus: false,
      width: '80vw',
      height: '90vh',
      panelClass: 'chips-multiselection-dialog',
      disableClose: true,
    });
  }

  saveToAddressesSelection() {
    this.cdRef.detectChanges();
    // DO SAVE
    this.selectedTemplate.emailTos = this.actualToAddressesSelection.map((as) => {
      return {
        ...new EmailAddress(),
        entityId: as.entityId,
        entityName: as.entityName,
        emailaddressId: as.entityId,
        emailaddressFirstname: as.contactFirstname,
        emailaddressLastname: as.contactLastname,
        emailaddressFullname: `${as.contactLastname} ${as.contactFirstname}`,
        emailaddressEmail: as.contactEmail,
        emailaddressDeleted: as.deleted,
      };
    });
    this.closeToAddressesDialog();
  }

  addToToAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualToAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        this.actualToAddressesSelection[index].deleted = false;
      } else {
        this.actualToAddressesSelection.push(row);
      }
    });
    this.currentToAddressesSelectionSubj.next(this.actualToAddressesSelection);
  }

  removeFromToAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualToAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        // Check if Item is present
        const elem =
          this.selectedTemplate.emailTos != null
            ? this.selectedTemplate.emailTos.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          this.actualToAddressesSelection[index].deleted = true;
        } else {
          this.actualToAddressesSelection.splice(index, 1);
        }
      }
    });
    this.currentToAddressesSelectionSubj.next(this.actualToAddressesSelection);
  }

  clearToAddressesSelection() {
    if (this.actualToAddressesSelection.length > 0) {
      this.actualToAddressesSelection.forEach((row, index) => {
        // Check if Item is present
        let elem =
          this.selectedTemplate.emailTos != null
            ? this.selectedTemplate.emailTos.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          row.deleted = true;
        }
      });
    }
    this.actualToAddressesSelection = this.actualToAddressesSelection.filter((row) => row.deleted);
    this.currentToAddressesSelectionSubj.next(this.actualToAddressesSelection);
  }

  closeToAddressesDialog() {
    this.multiselectionToAddressesDialogRef.close();
  }

  isToAddressesSelectionDirty() {
    if (this.selectedTemplate.emailTos == null || this.originalContractToAddresses == null) {
      return false;
    }
    return JSON.stringify(this.selectedTemplate.emailTos) !== JSON.stringify(this.originalContractToAddresses);
  }

  removeToAddressesChip(item: EmailAddress) {
    const index = this.originalContractToAddresses ? this.originalContractToAddresses.findIndex((i) => i.entityId === item.entityId) : -1;

    if (index < 0) {
      // Removed a chip not yet stored
      const i = this.selectedTemplate.emailTos.findIndex((i) => i.entityId === item.entityId);
      this.selectedTemplate.emailTos.splice(i, 1);
    } else {
      item.emailaddressDeleted = true;
    }
  }

  get availableToAddresses() {
    return this.selectedTemplate.emailTos ? this.selectedTemplate.emailTos.filter((er) => !er.emailaddressDeleted) : [];
  }

  /** CCS ADDRESSES */

  originalContractCcAddresses: EmailAddress[];
  actualCcAddressesSelection: Contact[];
  currentCcAddressesSelectionSubj: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>(null);
  multiselectionCcAddressesDialogRef: MatDialogRef<any>;

  openCcAddressesDialog(ref: TemplateRef<any>) {
    this.actualCcAddressesSelection =
      this.selectedTemplate.emailCcs != null
        ? this.selectedTemplate.emailCcs.map((ffer) => {
            return {
              ...new Contact(),
              entityId: ffer.entityId,
              entityName: `${ffer.emailaddressFullname} (${ffer.emailaddressEmail})`,
              // entityName: ffer.entityName,
              entityKind: ffer.entityKind,
              contactFirstname: ffer.emailaddressFirstname,
              contactLastname: ffer.emailaddressLastname,
              contactEmail: ffer.emailaddressEmail,
              deleted: ffer.emailaddressDeleted,
            };
          })
        : [];
    this.currentCcAddressesSelectionSubj.next(this.actualCcAddressesSelection);
    this.multiselectionCcAddressesDialogRef = this.dialog.open(ref, {
      autoFocus: false,
      width: '80vw',
      height: '90vh',
      panelClass: 'chips-multiselection-dialog',
      disableClose: true,
    });
  }

  saveCcAddressesSelection() {
    this.cdRef.detectChanges();
    // DO SAVE
    this.selectedTemplate.emailCcs = this.actualCcAddressesSelection.map((as) => {
      return {
        ...new EmailAddress(),
        entityId: as.entityId,
        entityName: as.entityName,
        emailaddressId: as.entityId,
        emailaddressFirstname: as.contactFirstname,
        emailaddressLastname: as.contactLastname,
        emailaddressFullname: `${as.contactLastname} ${as.contactFirstname}`,
        emailaddressEmail: as.contactEmail,
        emailaddressDeleted: as.deleted,
      };
    });
    this.closeCcAddressesDialog();
  }

  addToCcAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualCcAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        this.actualCcAddressesSelection[index].deleted = false;
      } else {
        this.actualCcAddressesSelection.push(row);
      }
    });
    this.currentCcAddressesSelectionSubj.next(this.actualCcAddressesSelection);
  }

  removeFromCcAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualCcAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        // Check if Item is present
        const elem =
          this.selectedTemplate.emailCcs != null
            ? this.selectedTemplate.emailCcs.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          this.actualCcAddressesSelection[index].deleted = true;
        } else {
          this.actualCcAddressesSelection.splice(index, 1);
        }
      }
    });
    this.currentCcAddressesSelectionSubj.next(this.actualCcAddressesSelection);
  }

  clearCcAddressesSelection() {
    if (this.actualCcAddressesSelection.length > 0) {
      this.actualCcAddressesSelection.forEach((row, index) => {
        // Check if Item is present
        let elem =
          this.selectedTemplate.emailCcs != null
            ? this.selectedTemplate.emailCcs.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          row.deleted = true;
        }
      });
    }
    this.actualCcAddressesSelection = this.actualCcAddressesSelection.filter((row) => row.deleted);
    this.currentCcAddressesSelectionSubj.next(this.actualCcAddressesSelection);
  }

  closeCcAddressesDialog() {
    this.multiselectionCcAddressesDialogRef.close();
  }

  isCcAddressesSelectionDirty() {
    if (this.selectedTemplate.emailCcs == null || this.originalContractCcAddresses == null) {
      return false;
    }
    return JSON.stringify(this.selectedTemplate.emailCcs) !== JSON.stringify(this.originalContractCcAddresses);
  }

  removeCcAddressesChip(item: EmailAddress) {
    const index = this.originalContractCcAddresses ? this.originalContractCcAddresses.findIndex((i) => i.entityId === item.entityId) : -1;

    if (index < 0) {
      // Removed a chip not yet stored
      const i = this.selectedTemplate.emailCcs.findIndex((i) => i.entityId === item.entityId);
      this.selectedTemplate.emailCcs.splice(i, 1);
    } else {
      item.emailaddressDeleted = true;
    }
  }

  get availableCcAddresses() {
    return this.selectedTemplate.emailCcs ? this.selectedTemplate.emailCcs.filter((er) => !er.emailaddressDeleted) : [];
  }

  /** BCCS ADDRESSES */

  originalContractBccAddresses: EmailAddress[];
  actualBccAddressesSelection: Contact[];
  currentBccAddressesSelectionSubj: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>(null);
  multiselectionBccAddressesDialogRef: MatDialogRef<any>;

  openBccAddressesDialog(ref: TemplateRef<any>) {
    this.actualBccAddressesSelection =
      this.selectedTemplate.emailBccs != null
        ? this.selectedTemplate.emailBccs.map((ffer) => {
            return {
              ...new Contact(),
              entityId: ffer.entityId,
              entityName: `${ffer.emailaddressFullname} (${ffer.emailaddressEmail})`,
              // entityName: ffer.entityName,
              entityKind: ffer.entityKind,
              contactFirstname: ffer.emailaddressFirstname,
              contactLastname: ffer.emailaddressLastname,
              contactEmail: ffer.emailaddressEmail,
              deleted: ffer.emailaddressDeleted,
            };
          })
        : [];
    this.currentBccAddressesSelectionSubj.next(this.actualBccAddressesSelection);
    this.multiselectionBccAddressesDialogRef = this.dialog.open(ref, {
      autoFocus: false,
      width: '80vw',
      height: '90vh',
      panelClass: 'chips-multiselection-dialog',
      disableClose: true,
    });
  }

  saveBccAddressesSelection() {
    this.cdRef.detectChanges();
    // DO SAVE
    this.selectedTemplate.emailBccs = this.actualBccAddressesSelection.map((as) => {
      return {
        ...new EmailAddress(),
        entityId: as.entityId,
        entityName: as.entityName,
        emailaddressId: as.entityId,
        emailaddressFirstname: as.contactFirstname,
        emailaddressLastname: as.contactLastname,
        emailaddressFullname: `${as.contactLastname} ${as.contactFirstname}`,
        emailaddressEmail: as.contactEmail,
        emailaddressDeleted: as.deleted,
      };
    });
    this.closeBccAddressesDialog();
  }

  addToBccAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualBccAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        this.actualBccAddressesSelection[index].deleted = false;
      } else {
        this.actualBccAddressesSelection.push(row);
      }
    });
    this.currentBccAddressesSelectionSubj.next(this.actualBccAddressesSelection);
  }

  removeFromBccAddressesSelection(rows: Contact[]) {
    rows.forEach((row) => {
      const index: number = this.actualBccAddressesSelection.findIndex((entity) => row && entity.entityId === row.entityId);
      if (index !== -1) {
        // Check if Item is present
        const elem =
          this.selectedTemplate.emailBccs != null
            ? this.selectedTemplate.emailBccs.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          this.actualBccAddressesSelection[index].deleted = true;
        } else {
          this.actualBccAddressesSelection.splice(index, 1);
        }
      }
    });
    this.currentBccAddressesSelectionSubj.next(this.actualBccAddressesSelection);
  }

  clearBccAddressesSelection() {
    if (this.actualBccAddressesSelection.length > 0) {
      this.actualBccAddressesSelection.forEach((row, index) => {
        // Check if Item is present
        let elem =
          this.selectedTemplate.emailBccs != null
            ? this.selectedTemplate.emailBccs.find((entity) => row && entity.entityId === row.entityId)
            : null;
        if (elem) {
          row.deleted = true;
        }
      });
    }
    this.actualBccAddressesSelection = this.actualBccAddressesSelection.filter((row) => row.deleted);
    this.currentBccAddressesSelectionSubj.next(this.actualBccAddressesSelection);
  }

  closeBccAddressesDialog() {
    this.multiselectionBccAddressesDialogRef.close();
  }

  isBccAddressesSelectionDirty() {
    if (this.selectedTemplate.emailBccs == null || this.originalContractBccAddresses == null) {
      return false;
    }
    return JSON.stringify(this.selectedTemplate.emailBccs) !== JSON.stringify(this.originalContractBccAddresses);
  }

  removeBccAddressesChip(item: EmailAddress) {
    const index = this.originalContractBccAddresses ? this.originalContractBccAddresses.findIndex((i) => i.entityId === item.entityId) : -1;

    if (index < 0) {
      // Removed a chip not yet stored
      const i = this.selectedTemplate.emailBccs.findIndex((i) => i.entityId === item.entityId);
      this.selectedTemplate.emailBccs.splice(i, 1);
    } else {
      item.emailaddressDeleted = true;
    }
  }

  get availableBccAddresses() {
    return this.selectedTemplate.emailBccs ? this.selectedTemplate.emailBccs.filter((er) => !er.emailaddressDeleted) : [];
  }

  get Languages() {
    return Array.from(this.languages.keys()).filter((k) => k != null);
  }

  get Stages() {
    return Array.from(this.stages.keys()).filter((k) => k != null);
  }

  get Entities() {
    return Entities;
  }
}
