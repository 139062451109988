import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IdValue } from 'src/app/models/core/key-value-data';
import { HelpChatbotData } from 'src/app/models/help';
import {
  FinesseChatbotInterfaceStoreAction,
  FinesseChatbotInterfaceStoreSelectors,
} from 'src/app/root-store/finesse-chatbot-interface.store';
import { HelpDataService } from 'src/app/services/help-data.service';
import { FormatComponent } from 'src/app/shared/base-components/format-component';

export interface FinesseChatbotData {
  source: string;
}

@Component({
  selector: 'app-finesse-chatbot',
  templateUrl: './finesse-chatbot.component.html',
  styleUrls: ['./finesse-chatbot.component.scss'],
})
export class FinesseChatbotComponent extends FormatComponent implements OnInit {
  @ViewChild('messagesContainer') private messagesContainer: ElementRef;

  messages: HelpChatbotData[];
  inputMessage: string;
  isLoading = false;
  source: string;

  constructor(
    public dialogRef: MatDialogRef<FinesseChatbotComponent>,
    public cdRef: ChangeDetectorRef,
    public helpDataService: HelpDataService,
    @Inject(MAT_DIALOG_DATA) public data: FinesseChatbotData
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribe(this.store.select(FinesseChatbotInterfaceStoreSelectors.selectFinesseChatbotInterfaceSource), (source) => {
      this.source = source;
      // if (this.source == null) {
      //   this.source = source;
      //   // this.messages = [];
      //   // this.loadMessages();
      // } else if (this.source !== source) {
      //   this.source = source;
      //   this.messages = [];
      //   // this.loadMessages();
      //   this.store.dispatch(FinesseChatbotInterfaceStoreAction.storeFinesseMessages({ messages: this.messages }));
      // }
    });
    this.subscribe(this.store.select(FinesseChatbotInterfaceStoreSelectors.selectFinesseChatbotInterfaceMessages), (messages) => {
      this.messages = messages;
      this.scrollToBottom();
      if (!this.messages || this.messages.length === 0) {
        this.messages = [];
        this.loadMessages();
      }
    });
  }

  loadMessages(question?: string) {
    this.isLoading = true;
    this.scrollToBottom();
    this.subscribe(
      this.helpDataService.getHelpChatbotData(this.source, question),
      (response) => {
        if (response.data) {
          this.isLoading = false;
          this.messages.push({ ...response.data });
        } else {
          this.isLoading = false;
        }
        this.inputMessage = null;
        this.scrollToBottom();
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }

  sendMessage() {
    if (this.inputMessage && this.inputMessage != '') {
      const newMessage: HelpChatbotData = {
        ...new HelpChatbotData(),
        response: this.inputMessage,
        isUser: true,
        state: true,
        responder: this.AuthUser.fullname,
      };
      this.messages.push(newMessage);
      this.loadMessages(this.inputMessage);
    }
  }

  askCommonQuestion(question: IdValue<number, string>) {
    const newMessage: HelpChatbotData = {
      ...new HelpChatbotData(),
      response: question.value,
      isUser: true,
      state: true,
      responder: this.AuthUser.fullname,
    };
    this.messages.push(newMessage);
    this.isLoading = true;
    this.scrollToBottom();
    this.subscribe(
      this.helpDataService.getHelpCommonQuestionResponse(this.source, question.id),
      (response) => {
        if (response.data) {
          this.isLoading = false;
          this.messages.push({ ...response.data });
        } else {
          this.isLoading = false;
        }
        this.inputMessage = null;
        this.scrollToBottom();
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }

  commit(message: HelpChatbotData) {
    this.isLoading = true;
    this.scrollToBottom();
    this.subscribe(
      this.helpDataService.commitQuestionResponse(this.source, message.responseId),
      (response) => {
        if (response.data) {
          this.isLoading = false;
          this.messages.push({ ...response.data });
        } else {
          this.isLoading = false;
        }
        message.feedbackSent = true;
        message.feedbackValue = true;
        this.inputMessage = null;
        this.scrollToBottom();
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }
  deny(message: HelpChatbotData) {
    this.isLoading = true;
    this.scrollToBottom();
    this.subscribe(
      this.helpDataService.denyQuestionResponse(this.source, message.responseId),
      (response) => {
        if (response.data) {
          this.isLoading = false;
          this.messages.push({ ...response.data });
        } else {
          this.isLoading = false;
        }
        message.feedbackSent = true;
        message.feedbackValue = false;
        this.inputMessage = null;
        this.scrollToBottom();
      },
      (error) => {
        /* HTTP Errors are managed on ServerErrorInterceptor */
      }
    );
  }

  scrollToBottom(): void {
    this.cdRef.detectChanges();
    try {
      if (this.messagesContainer) {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      }
    } catch (err) {
      //
    }
  }

  close() {
    this.store.dispatch(FinesseChatbotInterfaceStoreAction.storeFinesseMessages({ messages: this.messages }));
    this.store.dispatch(FinesseChatbotInterfaceStoreAction.closeFinesseChatbotInterface());
  }

  get AuthUser() {
    return this.authUser;
  }
}
