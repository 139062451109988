import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiPath } from '../configs/api-paths';
import { ActionResponse } from '../models/action-response';
import { AssetSubscription } from '../models/asset-subscription';
import { BaseResponse } from '../models/base-response';
import { PaginatedRequest } from '../models/paginated-request';
import { CatalogPriceIncreaseRequest } from '../models/price';
import { getServiceRequestParams, Service } from '../models/service';
import { LogService } from './log-service';

@Injectable({
  providedIn: 'root',
})
export class ServiceDataService {
  getServices(request: PaginatedRequest): Observable<BaseResponse<Service[]>> {
    const m = this.getServices.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICES);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    const retVal = this.http.get<BaseResponse<Service[]>>(path, {
      params: getServiceRequestParams(request),
    });

    return retVal;
  }

  getServicesSpreadsheet(request: PaginatedRequest) {
    const m = this.getServicesSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICES_SPREADSHEET);
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: getServiceRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  getServiceAllocations(request: PaginatedRequest, serviceId: number): Observable<BaseResponse<AssetSubscription[]>> {
    const m = this.getServiceAllocations.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_SUBSCRIPTIONS(serviceId));
    LogService.info(this, m, LogService.GET + path, null);
    let params = PaginatedRequest.getBaseRequestParams(request);
    const retVal = this.http.get<BaseResponse<AssetSubscription[]>>(path, {
      params,
    });

    return retVal;
  }

  getServiceAllocationsSpreadsheet(request: PaginatedRequest, serviceId: number) {
    const m = this.getServiceAllocationsSpreadsheet.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_SUBSCRIPTIONS_SPREADSHEET(serviceId));
    LogService.info(this, m, LogService.GET + path, null);

    LogService.info(this, m, LogService.REQUEST, request);
    return this.http
      .get(path, {
        params: getServiceRequestParams(request),
        observe: 'response',
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': '*',
        },
      })
      .pipe(
        map((res) => {
          const filename = res.headers.get('Content-Disposition');
          const data = {
            file: new Blob([res.body], { type: res.headers.get('Content-Type') }),
            filename: res.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, ''),
          };
          return data;
        })
      );
  }

  postServicePriceIncrease(serviceId: number, request: CatalogPriceIncreaseRequest): Observable<BaseResponse<ActionResponse>> {
    const m = this.postServicePriceIncrease.name;

    const path = environment.getEndpoint(ApiPath.Services.SERVICE_PRICE_INCREASE(serviceId));
    LogService.info(this, m, LogService.POST + path, null);
    LogService.info(this, m, LogService.REQUEST, request);

    const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);

    return retVal;
  }

  // getServiceUsages(
  //   request: PaginatedRequest,
  //   filterBillingitemId: number,
  //   starts: Date,
  //   ends: Date
  // ): Observable<BaseResponse<ServiceUsage[]>> {
  //   const m = this.getServiceUsages.name;
  //
  //   const path = environment.getEndpoint(ApiPath.Services.SERVICE_USAGES);
  //   LogService.info(this, m, LogService.GET + path, null);
  //
  //   LogService.info(this, m, 'filterBillingitemId: ', filterBillingitemId);
  //   LogService.info(this, m, 'starts: ', starts);
  //   LogService.info(this, m, 'ends: ', ends);
  //   LogService.info(this, m, LogService.REQUEST, request);
  //   const retVal = this.http.get<BaseResponse<ServiceUsage[]>>(path, {
  //     params: getServiceUsageRequestParams(request, filterBillingitemId, starts, ends),
  //   });
  //
  //   return retVal;
  // }

  // createServiceUsage(request: BillingItemUsageRequest): Observable<BaseResponse<ActionResponse>> {
  //   const m = this.createServiceUsage.name;
  //
  //   const path = environment.getEndpoint(ApiPath.Services.SERVICE_USAGES);
  //   LogService.info(this, m, LogService.POST + path, null);
  //   LogService.info(this, m, LogService.REQUEST, request);
  //   const retVal = this.http.post<BaseResponse<ActionResponse>>(path, request);
  //
  //   return retVal;
  // }

  // updateServiceUsage(usageId: number, request: BillingItemUsageRequest): Observable<BaseResponse<ActionResponse>> {
  //   const m = this.updateServiceUsage.name;
  //
  //   const path = environment.getEndpoint(ApiPath.Services.SERVICE_USAGES_ID(usageId));
  //   LogService.info(this, m, LogService.PUT + path, null);
  //   LogService.info(this, m, LogService.REQUEST, request);
  //   const retVal = this.http.put<BaseResponse<ActionResponse>>(path, request);
  //
  //   return retVal;
  // }

  // deleteServiceUsage(usageId: number): Observable<BaseResponse<ActionResponse>> {
  //   const m = this.deleteServiceUsage.name;
  //
  //   const path = environment.getEndpoint(ApiPath.Services.SERVICE_USAGES_ID(usageId));
  //   LogService.info(this, m, LogService.DELETE + path, null);
  //   const retVal = this.http.delete<BaseResponse<ActionResponse>>(path);
  //
  //   return retVal;
  // }

  constructor(private http: HttpClient) {}
}
