import { IdValue } from './core/key-value-data';

export class HelpData {
  helpId: number;
  helpTopic: string;
  helpText: string;
}

export class HelpChatbotData {
  commonQuestions: IdValue<number, string>[];
  error: string;
  errorKind: string;
  help: string;
  info: string;
  message: string;
  offset: number;
  responder: string;
  response: string;
  responseId: number;
  state: boolean;
  isUser: boolean;
  feedbackSent: boolean;
  feedbackValue: boolean;
}
